import { handleAction } from 'redux-actions';

import { cacheTalentProfileChanges } from '../actions/talentProfileActions';

const talentProfileCacheReducer = handleAction(
  cacheTalentProfileChanges,
  {
    next(state, action) {
      return { ...state, ...action.payload };
    },
    throw(state) {
      return state;
    },
  },
  {}
);

export default talentProfileCacheReducer;
