import stringHash from 'string-hash';
import { Record } from 'immutable';

export class Place extends Record({
  address: '',
  city: null,
  label: null,
  neighborhood: null,
  state: null,
  zipCode: null,
}) {
  constructor(obj) {
    const placeObj = obj || {};
    let { address = '' } = placeObj;
    const {
      neighborhood = null,
      city = null,
      label = null,
      state = null,
      zipCode = null,
    } = placeObj;

    if (!address && city && state) {
      address = [neighborhood, city, state, zipCode, 'United States']
        .filter((f) => f)
        .join(', ');
    }

    super({
      address,
      neighborhood,
      city,
      label: label || `${city}, ${state}`,
      state,
      zipCode,
    });
  }

  equals(other) {
    return (
      this.neighborhood === other.neighborhood &&
      this.city === other.city &&
      this.label === other.label &&
      this.state === other.state &&
      this.zipCode === other.zipCode
    );
  }

  hashCode() {
    return stringHash(`${this.city} ${this.state} ${this.zipCode}`);
  }

  isEmpty() {
    return !this.city || !this.state;
  }

  isValid() {
    const hasNoValue =
      !this.address && !this.city && !this.state && !this.zipCode;
    const hasFullValues = this.address && this.city && this.state;

    return !!(hasFullValues || hasNoValue);
  }
}
