import { styled } from 'shared/components/ihcl/styled';

export const Header = styled('h2', {});
export const BodyWrapper = styled('div', ({ $theme }) => ({
  paddingBottom: $theme.sizing.unit128,
}));

export const ButtonWrapper = styled('div', {
  marginTop: '12px',
});

export const ErrorMessage = styled('span', ({ $theme }) => ({
  color: $theme.colors.negative,
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '120%',
  letterSpacing: '0.5px',
  marginLeft: '4px',
}));

export const FooterWrapper = styled('div', ({ $theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  position: 'fixed',
  width: '100vw',
  backgroundColor: '#f6fbfc',
  bottom: 0,
  left: 0,
  paddingTop: $theme.sizing.unit16,
  paddingRight: $theme.sizing.unit16,
  paddingBottom: $theme.sizing.unit48,
  paddingLeft: $theme.sizing.unit16,
}));

export const Prompt = styled('div', {
  marginTop: '70px',
  marginBottom: '42px',
});
