import { styled } from 'shared/components/ihcl/styled';
import Cookie from 'js-cookie';
import throttle from 'just-throttle';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  serverErrorsReceived,
  updateTalentProfileComplete,
} from 'shared/actions/talentProfileActions';
import sharedConstants from 'shared/constants/constants.json';
import { Button } from 'shared/components/ihcl/button';
import { Divider } from 'shared/components/ihcl/divider';
import { StatefulInput } from 'shared/components/ihcl/input';
import { ToasterContainer, toaster } from 'shared/components/ihcl/toast';
import getFetchSignupSettings from 'shared/helpers/getFetchSignupSettings';
import {
  trackClick,
  trackNonHiringSignUpCompletion,
} from 'shared/helpers/tracking';

import { convertStateToAttributes } from '../../../helpers/talentProfileConversions';
import validateTalentProfile, {
  validateTalentProfileField,
} from '../../../helpers/validateTalentProfile';
import getGoogleClickID from '../../../helpers/getGoogleClickID';
import { useOnboardingStepContext } from '../providers/OnboardingStepProvider';
import { useOnboardingVariantContext } from '../providers/OnboardingVariantProvider';

import GoogleSignIn from './GoogleSignIn';
import {
  ErrorContainer,
  ErrorText,
  FooterWrapper,
  getTitles,
  SignInButton,
  SubmitFooterWrapper,
  Subtitle,
} from './OnboardingFormEmail';

const DividerWrapper = styled('div', {
  marginTop: '24px',
  marginBottom: '24px',
});

// eslint-disable-next-line react/require-default-props
const OnboardingFormEmail = ({ dispatch }) => {
  const {
    addTalentProfileKeys,
    completeStep,
    maybeGoToPrevious,
    localStepTalentProfile,
  } = useOnboardingStepContext();
  const { currentStep, intent, onboardVariant } = useOnboardingVariantContext();

  const location = useLocation();
  const urlQuery = qs.parse(location.search, { ignoreQueryPrefix: true });

  const formRef = useRef(null);
  const {
    clearErrors,
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'onTouched',
    defaultValues: localStepTalentProfile,
  });
  const formErrors = {
    ...errors,
    ...errors.user,
  };
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [googleJwt, setGoogleJwt] = useState(null);

  useEffect(() => {
    addTalentProfileKeys(['email']);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signUp = async (formData) => {
    const validationErrors = validateTalentProfile(
      formData,
      Object.keys(formData)
    );
    if (validationErrors.size > 0 && !googleJwt) {
      return;
    }

    const signUpUrl = '/users/sign_up/talent.json';
    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    let profileAttributes = {
      onboard_variant: onboardVariant,
      signup_intent: intent,
      timezone_from_onboarding: browserTimezone,
    };

    if (urlQuery.source) {
      profileAttributes.source = urlQuery.source;
    }
    sharedConstants.TALENT_PROFILE_TRACKING_PARAM_NAMES.forEach(
      (utmParamName) => {
        if (urlQuery[utmParamName]) {
          profileAttributes[utmParamName] = urlQuery[utmParamName];
        }
      }
    );
    if (urlQuery.ref) {
      profileAttributes.referrer_code = urlQuery.ref;
    }
    const gclid = Cookie.get('gclid') || getGoogleClickID(urlQuery);
    if (gclid) {
      profileAttributes.google_click_id = gclid;
    }

    const localOnboardingUpdates = convertStateToAttributes(
      localStepTalentProfile,
      Object.keys(localStepTalentProfile),
      formData
    );

    profileAttributes = {
      ...profileAttributes,
      ...localOnboardingUpdates,
      current_onboard_step: currentStep,
    };
    const body = {
      user: {
        ...formData,
        talent_profile_attributes: profileAttributes,
        remember_me: true,
      },
    };

    if (!formData || !formData.password) {
      body.gen_password = true;
    }
    if (googleJwt) {
      body.google = { credential: googleJwt };
    }
    setIsFormSubmitting(true);
    fetch(signUpUrl, getFetchSignupSettings(body))
      .then((data) => data.json())
      .then(async (json) => {
        setIsFormSubmitting(false);
        if (json && json.errors) {
          Object.keys(json.errors).forEach((key) => {
            if (key === 'user.email') {
              setError('email', {
                type: 'validationError',
                message: json.errors[key].join('; '),
              });
            } else if (key === 'google') {
              setError(key, {
                type: 'validationError',
                message: json.errors[key].join('; '),
              });
            }
          });
          dispatch(serverErrorsReceived(json));
        } else {
          trackClick('talent.sign_up', {
            Intent: intent,
          });
          trackNonHiringSignUpCompletion(profileAttributes);
          if (json.user_id && json.user_id.toString()) {
            mixpanel.alias(json.user_id.toString());
          } else {
            console.error(`cannot alias user_id: ${json.user_id}`);
          }
          mixpanel.register({ 'User Type': 'talent' });
          dispatch(updateTalentProfileComplete(json));
          dispatch(serverErrorsReceived({}));
          completeStep({});
        }
      })
      .catch((error) => {
        console.error(error);
        setIsFormSubmitting(false);
        const errorMsg =
          'Oops, there was a problem connecting ' +
          'to the server. Please check your internet connection ' +
          'and try again, or refresh the page.';
        toaster.negative(errorMsg);
      });
  };

  useEffect(() => {
    if (googleJwt) {
      clearErrors('email');
      signUp({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleJwt]);

  const blurField = (evt, fieldName, fieldProps) => {
    fieldProps.onBlur(evt);
  };
  const changeField = (evt, fieldName, fieldProps) => {
    if (formErrors[fieldName]) {
      clearErrors(fieldName);
    }
    fieldProps.onChange(evt);
  };
  const emailProps = register('email', {
    validate: (value) => {
      if (!googleJwt) {
        return validateTalentProfileField('email', value);
      }
      return null;
    },
  });
  const submitHandler = handleSubmit(signUp);
  const throttledSubmitHandler = useCallback(
    throttle((evt) => submitHandler(evt), 1000, { leading: true }),
    []
  );
  const invalidEmailErrorMessage = formErrors.email?.message;
  const emailInputErrorMessage = !googleJwt && invalidEmailErrorMessage;
  const googleErrorMessage =
    googleJwt && (formErrors.google?.message || invalidEmailErrorMessage);
  const signInPrompt = invalidEmailErrorMessage ===
    'has already been taken' && <SignInButton />;
  const emailInput = (
    <StatefulInput
      caption={emailInputErrorMessage}
      collapseCaptionPadding
      initialState={{ value: localStepTalentProfile.email }}
      label="Email Address"
      type="email"
      inputMode="email"
      {...emailProps}
      onBlur={(evt) => blurField(evt, 'email', emailProps)}
      onChange={(evt) => {
        setGoogleJwt(null);
        clearErrors('google');
        changeField(evt, 'email', emailProps);
      }}
      error={!!formErrors.email}
    />
  );
  const googleEmailButton = (
    <>
      <GoogleSignIn setGoogleJwt={setGoogleJwt} isSignIn={false} />
      {googleErrorMessage && (
        <>
          <ErrorText>{googleErrorMessage}</ErrorText>
          {signInPrompt}
        </>
      )}
    </>
  );

  const [title, subtitle] = getTitles(intent);
  return (
    <>
      <div>
        <ErrorContainer errors={errors} />
        <h1 className="title">{title}</h1>
        <Subtitle>{subtitle}</Subtitle>
        <form
          action="#"
          id="OnboardingFormEmail"
          onSubmit={(evt) => {
            evt.preventDefault();
            throttledSubmitHandler(evt);
          }}
          ref={formRef}
        >
          <ToasterContainer />
          {googleEmailButton}
          <DividerWrapper>
            <Divider>Or</Divider>
          </DividerWrapper>
          {emailInput}
          {emailInputErrorMessage && signInPrompt}
        </form>
      </div>
      <FooterWrapper>
        By signing up you agree to Incredible Health&apos;s&nbsp;
        <a
          href="https://www.incrediblehealth.com/terms.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms of Service
        </a>
        &nbsp; and&nbsp;
        <a
          href="https://www.incrediblehealth.com/privacy.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
      </FooterWrapper>
      <SubmitFooterWrapper>
        {currentStep > 1 ? (
          <Button type="button" kind="minimal" onClick={maybeGoToPrevious}>
            Back
          </Button>
        ) : (
          <span />
        )}
        <Button
          className="submit"
          form="OnboardingFormEmail"
          disabled={
            isFormSubmitting || (!googleJwt && Boolean(formErrors.email))
          }
          style={{
            marginTop: '15px',
            padding: '10px 24px',
          }}
        >
          Continue
        </Button>
      </SubmitFooterWrapper>
    </>
  );
};

OnboardingFormEmail.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

OnboardingFormEmail.defaultProps = {};

export default connect()(OnboardingFormEmail);
