import PropTypes from 'prop-types';
import React from 'react';
import { Map } from 'immutable';
import qs from 'qs';
import { styled } from 'shared/components/ihcl/styled';

import sharedConstants from 'shared/constants/constants.json';
import { Button } from 'shared/components/ihcl/button';
import { Checkbox } from 'shared/components/ihcl/checkbox';
import { Input } from 'shared/components/ihcl/input';
import { authenticityToken } from 'shared/helpers/rails';
import { validateRequired } from 'shared/helpers/validations';
import { trackClick } from 'shared/helpers/tracking';

import getGoogleClickID from '../../helpers/getGoogleClickID';

export const AltPrompt = styled('div', {
  marginTop: '36px',
  marginBottom: '36px',
});

export const InputWrapper = styled('div', {
  marginBottom: '20px',
  textAlign: 'left',
});

export const SignInOptionsWrapper = styled('div', ({ $theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '20px',
  ...$theme.typography.DisplaySmall,
}));

const FooterWrapper = styled('div', {
  fontSize: '14px',
});

const Subtitle = styled('p', {
  marginTop: '20px',
  marginBottom: '30px',
  fontSize: '16px',
});

export function parseInputObj(name, value, nameValuePairs = []) {
  let parsedNameValuePairs = [...nameValuePairs];
  if (value && typeof value === 'object') {
    if (Array.isArray(value)) {
      value.forEach((subValue) => {
        parsedNameValuePairs = parsedNameValuePairs.concat(
          parseInputObj(`${name}[]`, subValue, nameValuePairs)
        );
      });
    } else {
      Object.keys(value).forEach((valueKey) => {
        parsedNameValuePairs = parsedNameValuePairs.concat(
          parseInputObj(`${name}[${valueKey}]`, value[valueKey], nameValuePairs)
        );
      });
    }
  } else {
    parsedNameValuePairs.push([name, value]);
  }
  return parsedNameValuePairs;
}

function hiddenInputIfValue(name, value) {
  if (value && typeof value === 'object') {
    const nameValuePairs = parseInputObj(name, value);
    return nameValuePairs.map(([inputName, inputValue]) =>
      inputValue ? (
        <input
          name={inputName}
          value={inputValue}
          type="hidden"
          key={`${inputName}:${inputValue}`}
        />
      ) : null
    );
  }
  return value ? (
    <input name={name} value={value} type="hidden" key={name} />
  ) : null;
}

class SignXForm extends React.Component {
  constructor(props) {
    super(props);

    const { email } = this.props.previousData || {};
    const { email: emailError } = this.props.initialErrors || {};

    this.state = {
      email: email || '',
      password: '',
      rememberMe: true,
      errors: Map(emailError ? { email: emailError[0] } : {}),
      isSubmitting: false,
    };

    this.handleTextFieldChange = this.handleTextFieldChange.bind(this);
    this.handleSubmittableKeyPress = this.handleSubmittableKeyPress.bind(this);
    this.validateAndSubmit = this.validateAndSubmit.bind(this);

    this.signXForm = React.createRef();
  }

  componentDidMount() {
    mixpanel.track('Onboard Lite Step 2');
  }

  handleTextFieldChange(e) {
    const { target } = e;
    const fieldName = target.dataset.fieldname;
    this.setState((prevState) => ({
      [fieldName]: target.value,
      errors: prevState.errors.delete(fieldName),
    }));
  }

  handleSubmittableKeyPress(e) {
    if (e.charCode === 13) {
      this.validateAndSubmit();
    }
  }

  validateAndSubmit() {
    const { intent, isSignUp } = this.props;
    let errors = validateRequired(this.state, 'email', 'password');

    if (isSignUp) {
      if (!errors.has('email') && this.state.email.indexOf('@') === -1) {
        errors = errors.set(
          'email',
          'Seems like there might be a problem here.'
        );
      }

      if (!errors.has('password') && this.state.password.trim().length < 6) {
        errors = errors.set('password', 'Must be at least 6 characters.');
      }
    }

    if (errors.size === 0) {
      trackClick(`talent.${isSignUp ? 'sign_up' : 'sign_in'}`, {
        Intent: intent,
      });
      this.signXForm.current.submit();
      this.setState({ isSubmitting: true });
    } else {
      this.setState({ errors });
    }
  }

  render() {
    const { isSignUp, profileAttributes } = this.props;
    const { errors, isSubmitting } = this.state;

    let actionSignUp = 'Create a free Incredible Health account or sign in';
    const actionSignIn = 'Login';

    let title;
    let valueProposition;
    switch (this.props.intent) {
      case 'advice':
        title = 'Real Advice for Nurses';
        valueProposition =
          "get advice from Incredible Health's nurse " +
          'community, or share your own expertise.';
        actionSignUp = 'Create a free account or sign in';
        break;

      case 'education':
        title = 'Continuing Education';
        valueProposition =
          'get access to 100% free continuing education & much more.';
        break;

      case 'mental_health':
        title = 'Nurse Mental Health';
        valueProposition =
          'get access to all of our 100% free tools &' +
          ' services exclusively for nurses.';
        actionSignUp = 'Create an account or sign in';
        break;

      case 'network':
        title = 'Nurse Community';
        valueProposition = 'get access to our community of nurses & much more.';
        break;

      case 'resume':
        title = 'Resume Builder';
        valueProposition = 'get access to our resume builder & much more.';
        break;

      case 'salary': {
        title = 'Nurse salary estimator';
        valueProposition = 'get custom salary estimates & much more.';
        break;
      }
      default:
        console.error(`Unknown intent: ${this.props.intent}`);
    }

    const action = isSignUp ? actionSignUp : actionSignIn;
    const subtitle = `${action} to ${valueProposition}`;

    let altPrompt;
    let altPromptAction;
    let altPromptURL;
    if (isSignUp) {
      altPrompt = 'Already have an account?';
      altPromptAction = 'Sign In';
      altPromptURL = `/users/sign_in/${this.props.intent}`;
    } else {
      altPrompt = "Don't have an Incredible Health account?";
      altPromptAction = 'Sign Up Here';
      altPromptURL = `/users/sign_up/${this.props.intent}`;
    }

    const baseActionURL = `/users/sign_${isSignUp ? 'up' : 'in'}/${
      this.props.intent
    }`;
    const actionURL = baseActionURL;

    let extraHiddenInputs = null;
    if (isSignUp) {
      const query = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
      });
      const gclid = getGoogleClickID(query);

      extraHiddenInputs = (
        <>
          <input
            name="user[talent_profile_attributes][signup_intent]"
            type="hidden"
            value={this.props.intent}
          />
          {hiddenInputIfValue(
            'user[talent_profile_attributes][source]',
            query.source
          )}
          {hiddenInputIfValue(
            'user[talent_profile_attributes][google_click_id]',
            gclid
          )}
          {sharedConstants.TALENT_PROFILE_TRACKING_PARAM_NAMES.map(
            (utmParamName) =>
              hiddenInputIfValue(
                `user[talent_profile_attributes][${utmParamName}]`,
                query[utmParamName]
              )
          )}
          {profileAttributes &&
            Object.keys(profileAttributes).length > 0 &&
            Object.keys(profileAttributes).map((key) =>
              hiddenInputIfValue(
                `user[talent_profile_attributes][${key}]`,
                profileAttributes[key]
              )
            )}
        </>
      );
    }

    const termsOfService = (
      <FooterWrapper>
        By signing up you agree to Incredible Health’s{' '}
        {/* eslint-disable react/jsx-no-target-blank */}
        <a href="https://www.incrediblehealth.com/terms.html" target="_blank">
          Terms of Service
        </a>{' '}
        and{' '}
        <a href="https://www.incrediblehealth.com/privacy.html" target="_blank">
          Privacy Policy
        </a>
        {/* eslint-enable react/jsx-no-target-blank */}
      </FooterWrapper>
    );
    const continueButton = (
      <Button
        className="submit"
        onClick={this.validateAndSubmit}
        disabled={isSubmitting || !this.state.password || !this.state.email}
        style={{
          marginTop: '15px',
          padding: '10px 24px',
        }}
      >
        Continue
      </Button>
    );
    return (
      <>
        <div>
          <h1
            className={(() => {
              if (!isSignUp) return 'title';
              return null;
            })()}
          >
            {title}
          </h1>
          <Subtitle>{subtitle}</Subtitle>
          <form
            action={actionURL}
            id="signXForm"
            method="POST"
            ref={this.signXForm}
          >
            <InputWrapper>
              <Input
                id="email"
                label="Email Address"
                name="user[email]"
                data-fieldname="email"
                value={this.state.email}
                onChange={this.handleTextFieldChange}
                error={Boolean(errors.get('email', false))}
                caption={errors.get('email', '')}
              />
            </InputWrapper>

            <InputWrapper>
              <Input
                id="password"
                label="Password"
                name="user[password]"
                value={this.state.password}
                data-fieldname="password"
                onChange={this.handleTextFieldChange}
                onKeyPress={this.handleSubmittableKeyPress}
                type="password"
                error={Boolean(errors.get('password', false))}
                caption={errors.get('password', '')}
              />
            </InputWrapper>

            {!this.props.isSignUp && (
              <SignInOptionsWrapper>
                <Checkbox
                  name="user[remember_me]"
                  value={this.state.rememberMe}
                  checked={this.state.rememberMe}
                  labelSize="small"
                  labelWeight="normal"
                  onChange={() => {
                    this.setState((prevState) => ({
                      ...prevState,
                      rememberMe: !prevState.rememberMe,
                    }));
                  }}
                >
                  Keep me signed in
                </Checkbox>

                <div>
                  <a href="/users/password/new">Forgot Password?</a>
                </div>
              </SignInOptionsWrapper>
            )}

            <input
              name="authenticity_token"
              type="hidden"
              value={authenticityToken()}
            />
            {extraHiddenInputs}

            {continueButton}
          </form>
        </div>
        <AltPrompt>
          {altPrompt}
          <br />
          <a href={altPromptURL}>{altPromptAction}</a>
        </AltPrompt>
        {termsOfService}
      </>
    );
  }
}

SignXForm.propTypes = {
  initialErrors: PropTypes.object.isRequired,
  intent: PropTypes.string.isRequired,
  isSignUp: PropTypes.bool,
  previousData: PropTypes.object.isRequired,
  profileAttributes: PropTypes.object,
};

SignXForm.defaultProps = {
  isSignUp: false,
  profileAttributes: {},
};

export default SignXForm;
