import lodash from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Grid, Button, Col, Row } from 'react-bootstrap';
import { Map } from 'immutable';

import { authenticityToken } from 'shared/helpers/rails';
import { validateRequired } from 'shared/helpers/validations';

import EmployerProfileDeprecatedForm from './EmployerProfileDeprecatedForm';
import EmployerProfilePreRegistationForm from './EmployerProfilePreRegistationForm';

import { getEmployerProfile } from '../reducers';

class EmployerForm extends React.Component {
  constructor(props) {
    super(props);

    const ie = {};
    const initialErrors = this.props.initialErrors || {};

    if (initialErrors.email) {
      ie.email = initialErrors.email[0];
    }

    this.state = {
      fullName: '',
      email: '',
      password: '',
      passwordStrength: 0,
      phone: '',
      jobTitle: '',
      companyName: '',
      errors: Map(ie),
    };

    const previousData = this.props.previousData || {};
    this.state.email = this.props.urlEmail || '';

    if (previousData.type === 'employer') {
      this.state.fullName = previousData.full_name || '';
      this.state.email = previousData.email || this.state.email;
      this.state.phone = previousData.phone_and_extension || '';
    }

    if (props.employerProfile) {
      this.state.companyName = props.employerProfile.company_name;
      this.state.email = props.employerProfile.email;
    }

    this.onTextFieldChange = this.onTextFieldChange.bind(this);
    this.onPasswordScoreChange = this.onPasswordScoreChange.bind(this);
    this.validateAndSubmit = this.validateAndSubmit.bind(this);

    this.signUpForm = React.createRef();
  }

  onTextFieldChange(e) {
    const target = e.currentTarget;
    const fieldName = lodash.camelCase(target.id);
    this.setState((prevState) => ({
      [fieldName]: target.value,
      errors: prevState.errors.delete(fieldName),
    }));
  }

  onPasswordScoreChange(score) {
    this.setState((prevState) => ({
      errors: prevState.errors.delete('password'),
      passwordStrength: score,
    }));
  }

  validateAndSubmit() {
    let errors = validateRequired(
      this.state,
      'fullName',
      'email',
      'password',
      'phone',
      'jobTitle',
      'companyName'
    );

    if (!errors.has('email') && this.state.email.indexOf('@') === -1) {
      errors = errors.set('email', 'Seems like there might be a problem here.');
    }

    if (!errors.has('password') && this.state.passwordStrength < 3) {
      errors = errors.set('password', 'Password is too weak');
    }

    if (errors.size === 0) {
      this.signUpForm.current.submit();
    } else {
      this.setState({ errors });
    }
  }

  render() {
    const { errors } = this.state;
    const { employerProfile } = this.props;

    return (
      <Grid fluid className="nopadding">
        <Row className="top-buffer">
          <Col xs={12}>
            <h2 className="align-center">
              Great talent quickly delivered to you &nbsp;
              <span className="face-with-sunglasses-emoji" />
            </h2>
          </Col>
        </Row>
        <form
          action="/users/sign_up/employer"
          method="POST"
          ref={this.signUpForm}
        >
          {employerProfile && (
            <EmployerProfilePreRegistationForm
              companyName={this.state.companyName}
              email={this.state.email}
              employerProfile={employerProfile}
              errors={errors}
              fullName={this.state.fullName}
              jobTitle={this.state.jobTitle}
              onTextFieldChange={this.onTextFieldChange}
              onPasswordScoreChange={this.onPasswordScoreChange}
              password={this.state.password}
              phone={this.state.phone}
            />
          )}
          {!employerProfile && (
            <EmployerProfileDeprecatedForm
              companyName={this.state.companyName}
              email={this.state.email}
              errors={errors}
              fullName={this.state.fullName}
              jobTitle={this.state.jobTitle}
              onPasswordScoreChange={this.onPasswordScoreChange}
              onTextFieldChange={this.onTextFieldChange}
              password={this.state.password}
              phone={this.state.phone}
            />
          )}

          <Row className="top-separator center-row">
            <input
              name="authenticity_token"
              type="hidden"
              value={authenticityToken()}
            />
            <input
              name="user[employer_profile_attributes][registered_as_employer]"
              type="hidden"
              value="1"
            />
            <Button
              bsStyle="primary"
              className="get-started orange-button"
              onClick={this.validateAndSubmit}
            >
              GET STARTED
            </Button>
          </Row>

          <Row className="top-buffer padded-row center-row tos-row terms">
            <span>
              By signing up you agree to Incredible Health&apos;s{' '}
              <a
                href="https://www.incrediblehealth.com/terms.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Service
              </a>{' '}
              and{' '}
              <a
                href="https://www.incrediblehealth.com/privacy.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              .
            </span>
          </Row>
        </form>
      </Grid>
    );
  }
}

EmployerForm.propTypes = {
  employerProfile: PropTypes.object,
  initialErrors: PropTypes.object.isRequired,
  previousData: PropTypes.object.isRequired,
  urlEmail: PropTypes.string,
};

EmployerForm.defaultProps = {
  employerProfile: null,
  urlEmail: null,
};

const mapStateToProps = (state) => ({
  employerProfile: getEmployerProfile(state),
});

export default connect(mapStateToProps)(EmployerForm);
