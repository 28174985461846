import React, { useEffect, useState } from 'react';
import { Map } from 'immutable';
import { Helmet } from 'react-helmet';
import { Transition } from 'react-transition-group';

import validateTalentProfile from 'registration/helpers/validateTalentProfile';

import { Button } from 'shared/components/ihcl/button';
import { Input } from 'shared/components/ihcl/input';
import { styled, useStyletron, Theme } from 'shared/components/ihcl/styled';
import { Tag } from 'shared/components/ihcl/tag';
import { trackClick } from 'shared/helpers/tracking';

import {
  BodyWrapper,
  ButtonWrapper,
  FooterWrapper,
  Header,
  Prompt,
} from './styledComponents';

import { useOnboardingStepContext } from '../providers/OnboardingStepProvider';
import { useOnboardingVariantContext } from '../providers/OnboardingVariantProvider';

const SPECIALTY_YEARS_MIN = 1;
const SPECIALTY_YEARS_MAX = 99;

const DEFAULT_HEADER = 'Your specialties and experience';
const DEFAULT_PROMPT = 'It’s ok to estimate your years of experience.';

const specialtyRowHeight = '50px';
const yearInputMargin = '16px';
const yearInputWidth = '80px';

const isValidSpecialtyYears = (years) =>
  years === undefined ||
  years === '' ||
  (SPECIALTY_YEARS_MIN <= years && years <= SPECIALTY_YEARS_MAX);

const validate = (localStepTalentProfile, talentProfileKeys) =>
  validateTalentProfile(
    localStepTalentProfile,
    talentProfileKeys,
    talentProfileKeys
  );

const SpecialtyDetails = styled(
  'div',
  ({ $isVisible }: { $isVisible: boolean; $theme: Theme }) => ({
    display: 'inline-block',
    height: specialtyRowHeight,
    width: yearInputWidth,
    right: 0,
    top: '7px',
    position: 'absolute',
    transition: 'transform 200ms',
    transform: $isVisible
      ? `translateX(calc(100% - ${yearInputWidth}))`
      : 'translateX(0)',
  })
);

const SpecialtyRow = styled('div', {
  height: specialtyRowHeight,
  width: '100%',
  position: 'relative',
  overflow: 'hidden',
});

const SpecialtyButtonWrapper = styled(
  'div',
  ({ $isFullWidth }: { $isFullWidth: boolean; $theme: Theme }) => ({
    display: 'flex',
    alignItems: 'center',
    height: specialtyRowHeight,
    width: $isFullWidth
      ? '100%'
      : `calc(100% - ${yearInputWidth} - ${yearInputMargin})`,
    position: 'absolute',
    transition: 'transform 200ms',
  })
);

type OnboardingSpecialtiesProps = {
  bodyText: string;
  nurseSpecialtyOptions: Map<number, string>;
  pageTalentProfileKeys: string[];
  title: string;
};

const OnboardingSpecialties = ({
  bodyText = DEFAULT_PROMPT,
  nurseSpecialtyOptions,
  pageTalentProfileKeys,
  title = DEFAULT_HEADER,
}: OnboardingSpecialtiesProps) => {
  const {
    addTalentProfileKeys,
    maybeGoToNext,
    maybeGoToPrevious,
    isUpdatingProfile,
    localStepTalentProfile,
    setLocalStepTalentProfile,
  } = useOnboardingStepContext();
  const { currentStep, intent } = useOnboardingVariantContext();
  const [errors, setErrors] = useState(Map());
  const [allowNoSpecialties, setAllowNoSpecialties] = useState(false);
  const [specialtyYearsValid, setSpecialtyYearsValid] = useState({});
  const [, theme] = useStyletron();

  useEffect(() => {
    const keys = pageTalentProfileKeys;
    addTalentProfileKeys(keys);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (Object.keys(localStepTalentProfile).length === 0) {
    return null;
  }

  const { nurseSpecialties } = localStepTalentProfile;

  const submit = () => {
    const newErrors = validate(localStepTalentProfile, pageTalentProfileKeys);
    if (newErrors.size > 0) {
      setErrors(newErrors);
      return;
    }

    trackClick('talent.onboarding.submit', {
      Intent: intent,
    });
    maybeGoToNext();
  };

  const nurseSpecialtiesArr = nurseSpecialties.keySeq().toArray();
  const nurseSpecialtiesCount = nurseSpecialties.size;

  const displayBackButton = currentStep > 1;
  return (
    <>
      <Helmet>
        <body className="onboarding-page" />
      </Helmet>
      <BodyWrapper>
        <Prompt>
          <Header>{title}</Header>
          <p>{bodyText}</p>
        </Prompt>
        {Object.values(nurseSpecialtyOptions.toJS()).map(
          (nso: { value: number; label: string }) => (
            <SpecialtyRow key={nso.value}>
              <Transition
                in={!(nurseSpecialties.get(nso.value) >= 0)}
                timeout={200}
              >
                {(state) => (
                  <SpecialtyButtonWrapper
                    $isFullWidth={['entering', 'entered'].includes(state)}
                  >
                    <Tag
                      closeable={false}
                      role="option"
                      aria-selected={nurseSpecialties.get(nso.value) >= 0}
                      variant="solid"
                      size="medium"
                      kind={
                        nurseSpecialties.get(nso.value) >= 0
                          ? 'custom'
                          : 'primary'
                      }
                      color={theme.colors.primary}
                      $textColor={
                        nurseSpecialties.get(nso.value) >= 0
                          ? 'white'
                          : theme.colors.primary
                      }
                      $containerWidth
                      onClick={() => {
                        let newNurseSpecialties = Map(nurseSpecialties);
                        if (nurseSpecialties.get(nso.value) >= 0) {
                          newNurseSpecialties = newNurseSpecialties.delete(
                            nso.value
                          );
                        } else {
                          newNurseSpecialties = newNurseSpecialties.set(
                            nso.value,
                            ''
                          );
                        }
                        setLocalStepTalentProfile(
                          (prevLocalStepTalentProfile) => ({
                            ...prevLocalStepTalentProfile,
                            nurseSpecialties: newNurseSpecialties,
                          })
                        );
                      }}
                    >
                      {nso.label}
                    </Tag>
                  </SpecialtyButtonWrapper>
                )}
              </Transition>
              <Transition
                in={nurseSpecialties.get(nso.value) !== undefined}
                timeout={200}
              >
                {(state) => {
                  const isVisible = ['entering', 'entered'].includes(state);
                  return (
                    <SpecialtyDetails $isVisible={isVisible}>
                      {isVisible ? (
                        <Input
                          autoFocus
                          size="tiny"
                          label="Years"
                          tinyLabel="yrs"
                          type="number"
                          inputMode="decimal"
                          min={SPECIALTY_YEARS_MIN}
                          max={SPECIALTY_YEARS_MAX}
                          maxLength={SPECIALTY_YEARS_MAX.toString().length}
                          step="1"
                          id={nso.label}
                          value={
                            nurseSpecialties.get(nso.value) >= 0
                              ? nurseSpecialties.get(nso.value)
                              : ''
                          }
                          error={
                            nso.value in specialtyYearsValid &&
                            !specialtyYearsValid[nso.value]
                          }
                          onChange={(evt) => {
                            let newNurseSpecialties = Map(nurseSpecialties);
                            newNurseSpecialties = newNurseSpecialties.set(
                              nso.value,
                              evt.currentTarget.value
                            );
                            setSpecialtyYearsValid({
                              ...specialtyYearsValid,
                              [nso.value]: isValidSpecialtyYears(
                                evt.currentTarget.value
                              ),
                            });
                            setLocalStepTalentProfile(
                              (prevLocalStepTalentProfile) => ({
                                ...prevLocalStepTalentProfile,
                                nurseSpecialties: newNurseSpecialties,
                              })
                            );
                          }}
                          style={{
                            maxWidth: yearInputWidth,
                            minWidth: yearInputWidth,
                          }}
                        />
                      ) : null}
                    </SpecialtyDetails>
                  );
                }}
              </Transition>
            </SpecialtyRow>
          )
        )}
        {(nurseSpecialtiesCount === 0 ||
          (nurseSpecialtiesCount === 1 &&
            nurseSpecialtiesArr &&
            nurseSpecialtiesArr[0][0] === undefined)) && (
          <SpecialtyRow style={{ marginTop: '16px' }}>
            <SpecialtyButtonWrapper $isFullWidth>
              <Tag
                closeable={false}
                variant="solid"
                size="medium"
                kind="custom"
                color={
                  allowNoSpecialties ? theme.colors.primary : 'transparent'
                }
                $textColor={allowNoSpecialties ? 'white' : theme.colors.primary}
                $containerWidth
                onClick={() => {
                  setAllowNoSpecialties(!allowNoSpecialties);
                }}
              >
                No specialties yet
              </Tag>
            </SpecialtyButtonWrapper>
          </SpecialtyRow>
        )}
      </BodyWrapper>

      <FooterWrapper>
        {displayBackButton && (
          <ButtonWrapper>
            <Button
              type="button"
              kind="minimal"
              onClick={maybeGoToPrevious}
              style={{
                padding: '10px 24px',
              }}
            >
              Back
            </Button>
          </ButtonWrapper>
        )}

        {!displayBackButton && <span />}

        <ButtonWrapper>
          <Button
            className="submit"
            onClick={submit}
            disabled={isUpdatingProfile || (errors && errors.size > 0)}
            isLoading={isUpdatingProfile}
            style={{
              padding: '10px 24px',
            }}
          >
            Continue
          </Button>
        </ButtonWrapper>
      </FooterWrapper>
    </>
  );
};

export default OnboardingSpecialties;
