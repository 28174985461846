import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { styled } from 'shared/components/ihcl/styled';

import { Center } from 'shared/components/ihcl/positioning';
import { Button } from 'shared/components/ihcl/button';
import { ProgressSteps } from 'shared/components/ihcl/progress_steps';
import { doPost } from 'shared/helpers/http';
import { pathToVariant } from 'shared/helpers/activateABTest';
import { ToasterContainer, toaster } from 'shared/components/ihcl/toast';

import {
  OnboardingText,
  OnboardingTitle,
  OnboardingWrapper,
  ProgressStepsWrapper,
  sendUpdate,
} from 'registration/containers/Onboarding';
import { OnboardingContext } from '../../containers/Onboarding';

const ExtraLargeTitle = styled('div', {
  fontSize: '48px',
  marginBottom: '8px',
});

// eslint-disable-next-line react/require-default-props
const ReactivationCompleted = ({ currentStep, nextAction, totalSteps }) => {
  const {
    history,
    onboardingBasePath,
    onboardingData,
    onboardingUpdates,
    setOnboardingData,
  } = useContext(OnboardingContext);
  const { first_name: firstName } = onboardingData;

  useEffect(() => {
    doPost('/talent_reactivation/complete').catch((error) => {
      console.error(error);
      const errorMsg =
        'Oops, there was a problem connecting ' +
        'to the server. Please check your internet connection ' +
        'and refresh the page.';
      toaster.negative(errorMsg);
    });
  }, []);

  const variant = pathToVariant(onboardingBasePath);

  return (
    <Center>
      <OnboardingWrapper>
        {totalSteps > 0 && (
          <ProgressStepsWrapper>
            <ProgressSteps currentStep={currentStep} totalSteps={totalSteps} />
          </ProgressStepsWrapper>
        )}
        <ToasterContainer />
        <OnboardingTitle
          $reducedFontSize={variant === 'yuki'}
          style={{ marginTop: '40px' }}
        >
          <ExtraLargeTitle>🎉</ExtraLargeTitle>
          Thanks {firstName}, your profile is activated!
        </OnboardingTitle>
        <OnboardingText>
          You can also update the rest of your profile. Accurate profile =
          better opportunities!
        </OnboardingText>
        <div>
          <Button
            color="alternate"
            onClick={() => {
              mixpanel.track('Reactivation form completed');
              sendUpdate({
                currentStep,
                history,
                nextAction,
                onboardingBasePath,
                onboardingData,
                onboardingUpdates,
                setOnboardingData,
              });
            }}
          >
            Got it
          </Button>
        </div>
      </OnboardingWrapper>
    </Center>
  );
};
ReactivationCompleted.propTypes = {
  currentStep: PropTypes.number.isRequired,
  nextAction: PropTypes.string.isRequired,
  totalSteps: PropTypes.number.isRequired,
};

export default ReactivationCompleted;
