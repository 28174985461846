import simplePluralize from 'shared/helpers/simplePluralize';

export const getJobCountText = (jobCount: number): string =>
  simplePluralize(jobCount, 'job', 'jobs');

export const getDesiredLocationValuesByState = ({
  filteredLocationValues,
  desiredLocationStateNameMap,
}: {
  filteredLocationValues: any;
  desiredLocationStateNameMap: any;
}) => {
  const desiredLocationValues = [];
  const desiredLocationValuesByState = {};

  filteredLocationValues.forEach((dl: any) => {
    const locationObj = {
      id: dl.value.address,
      label: `${dl.value.city}, ${dl.value.state}`,
      city: dl.value.city,
      state: dl.value.state,
    };
    desiredLocationValues.push(locationObj);
    const stateName = desiredLocationStateNameMap[dl.value.state];
    if (!desiredLocationValuesByState[stateName]) {
      desiredLocationValuesByState[stateName] = [];
    }
    desiredLocationValuesByState[stateName].push(locationObj);
  });

  desiredLocationValues.sort((a, b) => a.label.localeCompare(b.label));

  return { desiredLocationValues, desiredLocationValuesByState };
};
