import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Grid, Col, Row, Checkbox } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import getHiredImage from 'images/get-hired.svg';
import { updateTalentProfile } from 'shared/actions/talentProfileActions';
import { Button } from 'shared/components/ihcl/button';
import { trackClick } from 'shared/helpers/tracking';

import { useOnboardingStepContext } from '../providers/OnboardingStepProvider';
import { useOnboardingVariantContext } from '../providers/OnboardingVariantProvider';

const GetHired = ({ dispatch }) => {
  const {
    addTalentProfileKeys,
    completeStep,
    isUpdatingProfile,
    localStepTalentProfile,
  } = useOnboardingStepContext();
  const { currentStep, intent } = useOnboardingVariantContext();
  const history = useHistory();

  const [optedIn, setOptedIn] = useState(true);

  useEffect(() => {
    addTalentProfileKeys(['currentOnboardStep', 'id']);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClick = () => {
    trackClick(
      optedIn
        ? 'talent.hiring_activation_click'
        : 'talent.hiring_activation_skip',
      { intent }
    );
    // Update the current onboard step back to 2 if we are now going to
    // go through the hiring flow (product wants to skip step 1)
    const profileAttributesWithStep = {
      current_onboard_step: optedIn ? 2 : currentStep,
    };
    dispatch(
      updateTalentProfile(
        localStepTalentProfile.id,
        profileAttributesWithStep,
        () => {
          if (optedIn) {
            history.push('/talent_onboard/activate');
          } else {
            completeStep({});
          }
        }
      )
    );
  };

  return (
    <Grid fluid className="nopadding">
      <Row className="get-hired">
        <Col xs={10} xsOffset={1} md={8} mdOffset={2}>
          <Row className="image-container">
            <Col className="align-center">
              <img alt="" src={getHiredImage} />
            </Col>
          </Row>

          <Row className="top-buffer">
            <Col className="align-center">
              <header>
                Interested in permanent nursing job opportunities?
              </header>
            </Col>
          </Row>

          <Row className="top-buffer">
            <Col className="align-center">
              <p>
                We make it easy to get matched to positions in our network of
                1500+ hospitals, with salaries up to $200k.
              </p>

              <p>It only takes a couple minutes to set up your profile.</p>
            </Col>
          </Row>

          <div className="hiring-cta">
            <Row>
              <Col className="align-center">
                <Checkbox
                  className="interested"
                  checked={optedIn}
                  onChange={() => setOptedIn(!optedIn)}
                >
                  Yes, I&apos;m open to permanent job opportunities!
                </Checkbox>
              </Col>
            </Row>
            <Row className="top-buffer">
              <Col className="align-center">
                <Button
                  className="submit"
                  disabled={isUpdatingProfile}
                  style={{
                    padding: '10px 24px',
                  }}
                  onClick={onClick}
                >
                  Continue
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Grid>
  );
};

GetHired.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect()(GetHired);
