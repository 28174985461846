import React, { useEffect, useState } from 'react';
import { useStyletron } from 'shared/components/ihcl/styled';

export type Breakpoint = 'small' | 'medium' | 'large';

const Context = React.createContext<Breakpoint>(null);

// eslint-disable-next-line react/require-default-props
export default ({ children }: React.ProviderProps<Breakpoint>) => {
  const [, theme] = useStyletron();
  const determineBreakpoint = () => {
    if (window.innerWidth > theme.breakpoints.large) {
      return 'large';
    }
    if (window.innerWidth > theme.breakpoints.medium) {
      return 'medium';
    }

    return 'small';
  };
  const [breakpoint, setBreakpoint] = useState<Breakpoint>(
    determineBreakpoint()
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () => setBreakpoint(determineBreakpoint());
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
    return null;
  });

  return <Context.Provider value={breakpoint}>{children}</Context.Provider>;
};

export const useBreakpointContext = () => React.useContext(Context);
