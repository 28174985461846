import { trackEvent, MixpanelEvent } from 'shared/helpers/tracking';

import { dispatch } from './messaging';
import { isNative } from './utils';

export const Permission = Object.freeze({
  notifications: 'notifications',
});

export const checkPermission = (permission, prompt) => {
  if (!isNative()) {
    return;
  }

  if (!prompt.title || !prompt.body) {
    // eslint-disable-next-line no-console
    console.warn(`Prompt title or body missing (permission=${permission})`);
    return;
  }

  dispatch('checkPermission', { permission, prompt }, '1.0.14');

  trackEvent(MixpanelEvent.permissionRequestDispatch, {
    Permission: permission,
  });
};
