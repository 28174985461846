/* eslint-disable react/prop-types */

import React from 'react';

type EmployerModalProviderContext = {
  closeAction: () => void;
};

const Context = React.createContext<EmployerModalProviderContext>(null);

export const EmployerModalProvider = ({ closeAction, children }) => (
  <Context.Provider value={{ closeAction }}>{children}</Context.Provider>
);

export const useEmployerModal = () => React.useContext(Context);
