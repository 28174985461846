export default function applyItemsToRailsNestedAttributes(
  items,
  objectCollection,
  attrName,
  objectCreate
) {
  const changedCollection = items.map((itemId) => {
    const existingObj = objectCollection.find(
      (obj) => obj[attrName] === itemId
    );
    if (existingObj) {
      if (existingObj._destroy) {
        existingObj._destroy = false;
      }
      return existingObj;
    }

    return objectCreate(itemId);
  });

  objectCollection.forEach((existingObj) => {
    if (!items.find((itemId) => itemId === existingObj[attrName])) {
      changedCollection.push({ ...existingObj, _destroy: true });
    }
  });

  return changedCollection;
}
