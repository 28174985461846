import { Map, OrderedMap } from 'immutable';
import { sortBy } from 'lodash';

import { getExperimentAssignmentFromFeatureFlags } from 'shared/helpers/experiment';

export const getNurseSpecialtyOptions = (state) => {
  const specialties =
    state.nurseSpecialtyDescriptions || state.nurse_specialty_descriptions;

  return OrderedMap(
    specialties.map((nsd) => [
      nsd.id,
      { value: nsd.id, label: nsd.description },
    ])
  );
};

export const getNurseSpecialtySkills = (state) => {
  const specialtySkills =
    state.nurseSpecialtySkills || state.nurse_specialty_skills;

  return Map(specialtySkills).mapEntries(([specialtyId, skills]) => [
    parseInt(specialtyId, 10),
    sortBy(skills, ['position']),
  ]);
};

export const isFeatureEnabled = (state, featureName) =>
  !!state.featureFlags && state.featureFlags.includes(featureName);

// more info on usage and best practices here:
// https://www.notion.so/incrediblehealth/Experiments-721b6079cbf64089bf7d808407afd910?pvs=4
export const getExperimentAssignmentWithoutTracking = (
  state,
  experimentName,
  actorId = null
) =>
  state.featureFlags
    ? getExperimentAssignmentFromFeatureFlags(
        state.featureFlags,
        experimentName,
        actorId
      )
    : null;
