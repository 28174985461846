import React from 'react';
import PropTypes from 'prop-types';

const OnboardingStepContext = React.createContext(null);

export const OnboardingStepProvider = ({ config, children }) => (
  <OnboardingStepContext.Provider value={config}>
    {children}
  </OnboardingStepContext.Provider>
);

OnboardingStepProvider.propTypes = {
  children: PropTypes.node.isRequired,
  config: PropTypes.object.isRequired,
};

export const useOnboardingStepContext = () =>
  React.useContext(OnboardingStepContext);
