export default (nurseSpecialties) => {
  if (!nurseSpecialties) {
    return [];
  }
  if (nurseSpecialties.keys) {
    return [...nurseSpecialties.keys()];
  }
  if (nurseSpecialties.toObject) {
    return Object.keys(nurseSpecialties.toObject());
  }
  if (Object.keys(nurseSpecialties).length) {
    return Object.keys(nurseSpecialties);
  }
  return [];
};
