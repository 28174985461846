import PropTypes from 'prop-types';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { styled } from 'shared/components/ihcl/styled';

import { Button } from 'shared/components/ihcl/button';
import { ButtonSelect } from 'shared/components/ihcl/button_select';
import { Center } from 'shared/components/ihcl/positioning';
import { Spinner } from 'shared/components/ihcl/spinner';
import { doPost } from 'shared/helpers/http';
import simplePluralize from 'shared/helpers/simplePluralize';

import { AddLocation } from 'registration/components/new_pages/OnboardingLocationPersonalized/AddLocation';
import { LocationTags } from 'registration/components/new_pages/OnboardingLocationPersonalized/LocationTags';
import { OnboardingTitle } from 'registration/components/new_pages/OnboardingLocationPersonalized/OnboardingTitle';
import {
  getDesiredLocationValuesByState,
  getJobCountText,
} from 'registration/components/new_pages/OnboardingLocationPersonalized/helpers';
import { desiredLocationOptionsFrozen } from 'registration/constants/desiredLocationOptions';
import {
  byOpenings,
  bySalary,
} from 'registration/constants/personalizedLocationDefaults';
import {
  Onboarding,
  OnboardingContext,
} from 'registration/containers/Onboarding';
import {
  DELIMITER,
  UnbounceParam,
  getShouldPreselectLocations,
  getUnbounceParamFromLocalforage,
} from 'registration/helpers/experiment/xena';
import getNurseSpecialtyIds from 'registration/helpers/getNurseSpecialtyIds';
import getSalaryText from 'registration/helpers/getSalaryText';
import normalizeLocation from 'registration/helpers/normalizeLocation';
import normalizeMarketName from 'registration/helpers/normalizeMarketName';
import { locationFromGooglePlace } from 'registration/helpers/talentProfileConversions';
import { pathToVariant } from 'shared/helpers/activateABTest';
import { useGoogleAutocompleteService } from 'shared/helpers/googlePlaces';
import { OnboardingData } from 'registration/helpers/types';

const filteredLocationValues = [
  ...desiredLocationOptionsFrozen.filter((dl) =>
    Object.prototype.hasOwnProperty.call(dl, 'value')
  ),
];

const desiredLocationStateNameMap = desiredLocationOptionsFrozen
  .filter((dl) => !Object.prototype.hasOwnProperty.call(dl, 'value'))
  .reduce(
    (stateMap, state) => ({
      ...stateMap,
      [state.section.toUpperCase()]: state.label,
    }),
    {}
  );

const { desiredLocationValuesByState } = getDesiredLocationValuesByState({
  filteredLocationValues,
  desiredLocationStateNameMap,
});

const SpinnerContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  marginTop: '50px',
  marginBottom: '50px',
});

const SelectedLocations = styled('div', {
  marginTop: '1em',
});

const getNewLocations = async (onboardingInformation) =>
  doPost('/talent_onboard/custom_job_openings', onboardingInformation)
    .then((resp) => {
      if (resp && resp.length) {
        return resp.map(normalizeLocation);
      }
      throw new Error('No personalized job matches.');
    })
    .catch((err) => {
      console.error(err);
      if (
        onboardingInformation &&
        onboardingInformation.locationMotivation &&
        onboardingInformation.locationMotivation.includes('compensation') &&
        !onboardingInformation.locationMotivation.includes(
          'placement_potential'
        )
      ) {
        return bySalary;
      }
      return byOpenings;
    });

// eslint-disable-next-line react/require-default-props
export const OnboardingLocationPersonalized = ({
  nextAction = null,
  previousAction = null,
  stepNumber,
}) => {
  const {
    onboardingBasePath,
    onboardingData,
    onboardingUpdates,
    setOnboardingData,
    setOnboardingUpdates,
  } = useContext<{
    onboardingBasePath: string;
    onboardingData: OnboardingData;
    onboardingUpdates: any;
    setOnboardingData: Dispatch<SetStateAction<any>>;
    setOnboardingUpdates: Dispatch<SetStateAction<any>>;
  }>(OnboardingContext);
  const { desiredLocations: selectedLocations, nurseSpecialties } =
    onboardingData;
  const {
    education_level: educationLevel,
    location_motivation: locationMotivation,
    nurse_specialties_attributes: nurseSpecialtiesAttributes,
  } = onboardingUpdates;
  const emptySelectedLocations =
    !selectedLocations || (selectedLocations && selectedLocations.length === 0);
  const [newLocations, setNewLocations] = useState([]);
  const [showTenLocations, setShowTenLocations] = useState(false);
  const [paramLocations, setParamLocations] = useState(null);

  const [shouldPreselectOtherLocations, setShouldPreselectOtherLocations] =
    useState(false);

  const variant = pathToVariant(onboardingBasePath);
  const isYukiVariant = variant === 'yuki';

  useEffect(() => {
    getShouldPreselectLocations(variant).then((value) =>
      setShouldPreselectOtherLocations(value)
    );
  }, [variant]);

  useEffect(() => {
    if (shouldPreselectOtherLocations) {
      // also get any locations we saved from unbound query param
      const getParamLocation = async () => {
        const paramValue = await getUnbounceParamFromLocalforage(
          UnbounceParam.desired_location
        );

        setParamLocations(paramValue);
      };

      getParamLocation();
    }
  });

  const autocompleteService = useGoogleAutocompleteService();

  const getResultsFromGoogle = async (input) => {
    const resp = await autocompleteService?.getPlacePredictions({
      input,
      componentRestrictions: { country: 'us' },
      types: ['(cities)'],
    });

    return resp?.predictions;
  };

  const addTag = useCallback(
    (tagObj) => {
      setOnboardingData((data) => {
        if (
          data.desiredLocations.every((location) => location.id !== tagObj.id)
          // using data.desiredLocations instead of selectedLocations here to
          // eliminate extra dependency and excessive firing of below effect
        ) {
          return {
            ...data,
            desiredLocations: [...data.desiredLocations, tagObj],
          };
        }

        return data;
      });
    },
    [setOnboardingData]
  );

  useEffect(() => {
    const addTagsFromGoogleMatches = async () => {
      paramLocations?.split(DELIMITER).forEach(async (paramLocation) => {
        if (paramLocation.trim()) {
          const results = await getResultsFromGoogle(paramLocation);

          if (results?.length > 0) {
            const locationValues = results.map(locationFromGooglePlace);
            const match = locationValues.find(
              (locationValue) =>
                locationValue?.label.replace(/ +/g, '').toLowerCase() ===
                paramLocation.replace(/ +/g, '').toLowerCase()
            );
            if (match) addTag(match);
          }
        }
      });
    };

    addTagsFromGoogleMatches();
  }, [paramLocations, addTag]);

  useEffect(() => {
    const fetchNewLocations = async () => {
      const customNewLocations = await getNewLocations({
        educationLevel,
        locationMotivation,
        nurseSpecialties: [...getNurseSpecialtyIds(nurseSpecialties)].concat(
          nurseSpecialtiesAttributes?.map(
            (nsa) => nsa.nurse_specialty_description_id
          ) || []
        ),
      });

      setNewLocations(customNewLocations);
    };

    fetchNewLocations();
  }, [
    educationLevel,
    locationMotivation,
    nurseSpecialties,
    nurseSpecialtiesAttributes,
  ]);

  const removeTag = ({ id }) => {
    const filteredTags = selectedLocations.filter((tag) => tag.id !== id);
    setOnboardingData({
      ...onboardingData,
      desiredLocations: filteredTags,
    });
  };

  const locationOptions =
    newLocations && newLocations.length
      ? newLocations.map((newLocation) => ({
          ...newLocation,
          label: (
            <>
              <strong>{normalizeMarketName(newLocation.market)}</strong>
              {!!newLocation.jobCount &&
                `${getJobCountText(newLocation.jobCount)}, `}
              {getSalaryText(newLocation.maxSalary)}
            </>
          ),
          value: newLocation.market,
          tagHidden: true,
        }))
      : [];
  const shownLocations = showTenLocations
    ? locationOptions
    : locationOptions.slice(0, 3);
  const getSelectedOptions = (selectedOptions) =>
    shownLocations.filter((selectedOption) =>
      selectedOptions.some((option) => option.value === selectedOption.value)
    );

  const tagFilteredSelectedOptions = selectedLocations.filter(
    (selectedLocation) => !selectedLocation.tagHidden
  );

  const specialtyText = !nurseSpecialties
    ? 'specialty'
    : simplePluralize(
        nurseSpecialties.length,
        'specialty',
        'specialties',
        false
      );

  return (
    <Onboarding
      autoSpacedStepContents={!isYukiVariant}
      currentStep={stepNumber}
      isNextDisabled={emptySelectedLocations}
      nextAction={nextAction}
      onboardingBasePath={onboardingBasePath}
      onboardingData={onboardingData}
      onboardingUpdates={onboardingUpdates}
      previousAction={previousAction}
      setOnboardingData={setOnboardingData}
      setOnboardingUpdates={setOnboardingUpdates}
      stepKeys={['desiredLocations']}
    >
      {!(newLocations && newLocations.length) && (
        <OnboardingTitle
          $reducedFontSize={isYukiVariant}
          $additionalTopSpacing={isYukiVariant}
        >
          Searching for matching jobs
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        </OnboardingTitle>
      )}
      {newLocations && newLocations.length > 0 && (
        <>
          <OnboardingTitle
            $reducedFontSize={isYukiVariant}
            $additionalTopSpacing={isYukiVariant}
          >
            Where would you like to work?
          </OnboardingTitle>
          <SelectedLocations>
            {tagFilteredSelectedOptions && (
              <LocationTags
                locations={tagFilteredSelectedOptions}
                removeTag={removeTag}
              />
            )}
            <AddLocation
              addTag={(newTag) => addTag(newTag)}
              defaultOptions={desiredLocationValuesByState}
              getResultsFromGoogle={getResultsFromGoogle}
            />
          </SelectedLocations>
          <Center>
            <span style={{ fontWeight: 900 }}>
              Popular areas for your {specialtyText}
            </span>
            <p>Tap to add areas you are interested in.</p>
            <ButtonSelect
              buttonProps={{
                style: {
                  borderRadius: '52px',
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '8px',
                },
              }}
              fillWidth
              multiSelect
              onChange={(val, option, remove) =>
                remove
                  ? setOnboardingData({
                      ...onboardingData,
                      desiredLocations: selectedLocations.filter(
                        (selectedLocation) =>
                          selectedLocation.value !== option.value
                      ),
                    })
                  : setOnboardingData({
                      ...onboardingData,
                      desiredLocations: [...selectedLocations, option],
                    })
              }
              selectedOptions={getSelectedOptions(selectedLocations)}
              options={shownLocations}
            />
            {!showTenLocations &&
              locationOptions.length > shownLocations.length && (
                <>
                  <br />
                  <Button
                    kind="minimal"
                    onClick={() => setShowTenLocations(true)}
                  >
                    Show more
                  </Button>
                </>
              )}
          </Center>
        </>
      )}
    </Onboarding>
  );
};

OnboardingLocationPersonalized.propTypes = {
  nextAction: PropTypes.string,
  previousAction: PropTypes.string,
  stepNumber: PropTypes.number.isRequired,
};
