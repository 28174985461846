import { Col, Grid, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import React from 'react';

import brandLogo from '../../../assets/images/brandlogo.png';

const SignUpNavTabContainer = ({ children }) => (
  <div className="employer-registration">
    <Helmet>
      <body className="employer employer-registration" />
    </Helmet>
    <div className="container-fluid sign-up-header">
      <a className="brand-logo" href="/">
        <img alt="logo" src={brandLogo} />
      </a>
    </div>
    <div className="main-page">
      <Grid>
        <Row>
          <Col className="content-container" md={8} mdOffset={2}>
            {children}
            <Row className="top-separator">
              <Col className="divider" xs={10} xsOffset={1} />
            </Row>
            <Row className="top-separator">
              <Col className="align-center have-account" xs={12}>
                Already have an account?
              </Col>
            </Row>

            <Row>
              <Col className="align-center bottom-buffer top-buffer" xs={12}>
                <span className="underline-link">
                  <a href="/users/sign_in">SIGN IN</a>
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
      </Grid>
    </div>
  </div>
);

SignUpNavTabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SignUpNavTabContainer;
