import React, { useState } from 'react';
import { connect } from 'react-redux';

import { cancelScreening } from 'shared/actions/screeningActions';

import { toaster } from 'shared/components/ihcl/toast';
import { Button } from 'shared/components/ihcl/button';
import { Stack } from 'shared/components/ihcl/patterns';
import { Text } from 'shared/components/ihcl/text';
import { LucideIcon } from 'shared/components/ihcl/lucideIcon';
import { ModalHeader } from 'shared/components/ihcl/modal';
import { getShortTimezone } from 'shared/helpers/timezones';

import { Screening } from 'talent/types';

import {
  BoldText,
  ExpandingModalBody,
  FixedModalFooter,
  InputStack,
  PrimaryCTAWrapper,
} from './styledComponents';
import { formattedDateValues } from './helpers';
import AppointmentDetail from './AppointmentDetail';
import Title from './Title';

function AppointmentSummary({
  screening,
  handleReschedule,
  cancelScreeningAction,
  closeModal,
}: {
  screening: Screening;
  cancelScreeningAction: Function;
  handleReschedule: Function;
  closeModal: Function;
}) {
  const [isCanceling, setIsCanceling] = useState(false);

  const handleCancel = () => {
    setIsCanceling(true);
    cancelScreeningAction(screening.id)
      .then(() => {
        toaster.info(
          <Stack $flexDirection="row" $gapSizing="scale500">
            <LucideIcon name="Check" size="unit24" />
            <div>Intro call canceled</div>
          </Stack>
        );
      })
      .then(() => {
        setIsCanceling(false);
        closeModal('talent.screening.canceled');
      });
  };

  const { monthDay, weekday, startTime, endTime } = formattedDateValues(
    new Date(screening.starts_at)
  );

  return (
    <>
      <ModalHeader>
        <Title onClick={() => closeModal('talent.screening.summary.close')}>
          My 10 min intro call
        </Title>
      </ModalHeader>
      <ExpandingModalBody>
        <Text variant="ParagraphMedium">
          This call is to confirm your experience and preferences. This
          isn&apos;t an interview — so no prep needed. We will call in the{' '}
          <BoldText>30 min window.</BoldText>
        </Text>
        <InputStack $flexDirection="column" $gapSizing="unit12">
          <AppointmentDetail
            label={`Time window selected (${getShortTimezone()})`}
            copy={
              <>
                <BoldText>{`${monthDay} (${weekday})`}</BoldText>{' '}
                {`${startTime} - ${endTime}`}
              </>
            }
          />
          <AppointmentDetail
            label="Email to receive calendar invite"
            copy={screening.email}
          />
          <AppointmentDetail
            label="Number to receive call"
            copy={screening.phone.text}
          />
          <AppointmentDetail
            label="Message for Nurse Screener"
            copy={screening.notes ? screening.notes : 'None'}
          />
          <Button
            size="default"
            color="alternate"
            kind="minimal"
            onClick={() => handleReschedule()}
            disabled={isCanceling}
            startEnhancer={<LucideIcon name="CalendarClock" />}
          >
            Reschedule
          </Button>
          <Button
            size="default"
            color="negative"
            kind="minimal"
            isLoading={isCanceling}
            onClick={() => handleCancel()}
            startEnhancer={<LucideIcon name="XCircle" />}
          >
            Cancel intro call
          </Button>
        </InputStack>
      </ExpandingModalBody>
      <FixedModalFooter>
        <PrimaryCTAWrapper>
          <Button
            onClick={() => {
              closeModal('talent.screening.summary.ok_close');
            }}
            size="default"
            color="alternate"
            kind="tertiary"
            fullWidth
          >
            <Text color="accent" variant="LabelMedium">
              Ok, got it
            </Text>
          </Button>
        </PrimaryCTAWrapper>
      </FixedModalFooter>
    </>
  );
}

export default connect(null, {
  cancelScreeningAction: cancelScreening,
})(AppointmentSummary);
