export function openExternalLink(url, safe = false) {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        type: 'url',
        payload: { url },
        minVersion: '1.0.5',
      })
    );
  } else {
    window.open(url, '_blank', safe ? '' : 'noopener,noreferrer');
  }
}

export function genExternalLinkHandler(
  safe = false,
  preOpenCallback = null,
  bustCache = false,
  _openExternalLink = openExternalLink
) {
  return (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (preOpenCallback) {
      preOpenCallback();
    }

    let { href } = event.currentTarget;
    if (bustCache) {
      const url = new URL(href);
      url.searchParams.append('bustCache', new Date().getTime());
      href = url.toString();
    }
    _openExternalLink(href, safe);
  };
}

export function isExternalURL(url) {
  try {
    const fullURL = new URL(url, window.location.origin);
    return fullURL.hostname !== window.location.hostname;
  } catch (_) {
    return true;
  }
}

export function goBack(history, fallbackURL) {
  if (history.location.state?.from) {
    history.goBack();
  } else {
    history.push(fallbackURL);
  }
}

export function appWebsiteURL(path, force_full_url = false) {
  const appWebsite = window.IH.baseAppURL || 'https://app.incrediblehealth.com';
  if (window.location.origin === appWebsite && !force_full_url) {
    return path;
  }
  return new URL(path, appWebsite).href;
}

export function getRelativePath(url = null) {
  return (url || window.location.href).replace(window.location.origin, '');
}
