const getKey = async (
  key: string,
  defaultWhenUnavailable: number
): Promise<number> => {
  if (!window.localforage) {
    return defaultWhenUnavailable;
  }
  try {
    return await window.localforage.getItem<number>(key);
  } catch (err) {
    console.error(`Localforage data fetch error: ${key}`, err);
    return defaultWhenUnavailable;
  }
};

const setKey = async (key: string, value: number) => {
  if (!window.localforage) {
    return;
  }
  try {
    await window.localforage.setItem<number>(key, value);
  } catch (err) {
    console.error(`Localforage data save error: ${key}`, err);
  }
};

export const isUntouched = async (
  key: string,
  defaultWhenUnavailable: boolean = false
): Promise<boolean> => {
  const lastShown = await getKey(key, undefined);
  if (lastShown === undefined) {
    return defaultWhenUnavailable;
  }

  return lastShown === null;
};

export const touch = async (key: string) => {
  await setKey(key, Date.now());
};

export const oncePerDaysInterval = async (
  key: string,
  days: number,
  defaultWhenUnavailable: boolean = false,
  force: boolean = false
): Promise<boolean> => {
  const lastShown = await getKey(key, undefined);
  if (lastShown === undefined) {
    return force || defaultWhenUnavailable;
  }

  const showAfterDate = Date.now() - days * 24 * 60 * 60 * 1000;
  const shouldDisplay =
    force || lastShown === null || showAfterDate > lastShown;

  if (shouldDisplay) {
    await touch(key);
  }
  return shouldDisplay;
};

export const oncePerWeek = (
  key: string,
  { defaultWhenUnavailable = false, force = false } = {}
) => oncePerDaysInterval(key, 7, defaultWhenUnavailable, force);

export const oncePer30Days = (
  key: string,
  { defaultWhenUnavailable = false, force = false } = {}
) => oncePerDaysInterval(key, 30, defaultWhenUnavailable, force);

export const getItem = async <T>(
  key: string,
  defaultWhenUnavailable: T = null
): Promise<T> => {
  if (!window.localforage) {
    return defaultWhenUnavailable;
  }
  try {
    return await window.localforage.getItem<T>(key);
  } catch (err) {
    console.error(`Localforage data fetch error: ${key}`, err);
    return defaultWhenUnavailable;
  }
};

export const setItem = async <T>(key: string, value: T) => {
  if (!window.localforage) {
    return;
  }
  try {
    await window.localforage.setItem<T>(key, value);
  } catch (err) {
    console.error(`Localforage data save error: ${key}`, err);
  }
};
