import React from 'react';

const withGoogleOptimize = (WrappedComponent) => {
  const HOC = class extends React.Component {
    componentDidMount() {
      if (window.IH && window.IH.googleOptimizeEnabled) {
        window.dataLayer.push({ event: 'optimize.activate' });
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
};

export default withGoogleOptimize;
