import { trackEvent, MixpanelEvent } from 'shared/helpers/tracking';

import { dispatch } from './messaging';
import { isNative } from './utils';

export const requestReview = (trigger) => {
  if (!isNative()) {
    return;
  }

  dispatch('requestReview', { trigger }, '1.1.0');

  trackEvent(MixpanelEvent.reviewRequestDispatch, { Trigger: trigger });
};
