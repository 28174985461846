import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import { Button } from 'shared/components/ihcl/button';
import { Checkbox } from 'shared/components/ihcl/checkbox';
import { trackClick } from 'shared/helpers/tracking';

import nurseChatBubblesImage from 'images/illustrations/nurse-chat-bubbles.svg';

import { useOnboardingStepContext } from '../providers/OnboardingStepProvider';
import { useOnboardingVariantContext } from '../providers/OnboardingVariantProvider';

import {
  BodyWrapper,
  ButtonWrapper,
  FooterWrapper,
  Header,
  Prompt,
} from './styledComponents';

const DEFAULT_HEADER = 'Interested in getting matched to permanent work?';
const DEFAULT_BODY_TEXT =
  'We have over 1500+ hospital employers nationwide with thousands of permanent job opportunities that we can match you to.';
const OPEN_TO_PERMANENT_JOB_OPPORTUNITIES =
  'Yes! I’m open to permanent job opportunities';

type OnboardingLicensesProps = {
  bodyText?: string;
  pageTalentProfileKeys: string[];
  title?: string;
};

const OnboardingLicenses = ({
  bodyText = DEFAULT_BODY_TEXT,
  pageTalentProfileKeys,
  title = DEFAULT_HEADER,
}: OnboardingLicensesProps) => {
  const {
    addTalentProfileKeys,
    maybeGoToNext,
    maybeGoToPrevious,
    isUpdatingProfile,
    localStepTalentProfile,
    setLocalStepTalentProfile,
  } = useOnboardingStepContext();

  const { currentStep, intent } = useOnboardingVariantContext();

  const [isChecked, setIsChecked] = useState(true);

  useEffect(() => {
    addTalentProfileKeys(pageTalentProfileKeys);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (Object.keys(localStepTalentProfile).length === 0) {
    return null;
  }

  const submit = () => {
    trackClick('talent.onboarding.submit', {
      Intent: intent,
    });
    maybeGoToNext();
  };

  const displayBackButton = currentStep > 1;
  return (
    <>
      <Helmet>
        <body className="onboarding-page" />
      </Helmet>
      <BodyWrapper>
        <Prompt>
          <img alt="Get permanent job matches" src={nurseChatBubblesImage} />
          <Header>{title}</Header>
          <p>{bodyText}</p>
          <Checkbox
            checked={isChecked}
            onChange={(e) => {
              setIsChecked(e.target.checked);
              setLocalStepTalentProfile((prevLocalStepTalentProfile) => ({
                ...prevLocalStepTalentProfile,
                hiring_activated: e.target.checked,
              }));
            }}
            label={OPEN_TO_PERMANENT_JOB_OPPORTUNITIES}
          >
            {OPEN_TO_PERMANENT_JOB_OPPORTUNITIES}
          </Checkbox>
        </Prompt>
      </BodyWrapper>

      <FooterWrapper>
        {displayBackButton && (
          <ButtonWrapper>
            <Button
              type="button"
              kind="minimal"
              onClick={maybeGoToPrevious}
              style={{
                padding: '10px 24px',
              }}
            >
              Back
            </Button>
          </ButtonWrapper>
        )}

        {!displayBackButton && <span />}

        <ButtonWrapper>
          <Button
            className="submit"
            onClick={submit}
            disabled={isUpdatingProfile}
            isLoading={isUpdatingProfile}
            style={{
              padding: '10px 24px',
            }}
          >
            Continue
          </Button>
        </ButtonWrapper>
      </FooterWrapper>
    </>
  );
};

export default OnboardingLicenses;
