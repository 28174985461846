import React from 'react';
import { Button } from 'shared/components/ihcl/button';
import { FloatModal } from 'shared/components/ihcl/floatModal';
import { styled } from 'shared/components/ihcl/styled';
import { Text } from 'shared/components/ihcl/text';
import { getShortTimezone } from 'shared/helpers/timezones';

import advocateImagePath from 'images/personal-talent-advocate.svg';

const ContentWrapper = styled('div', ({ $theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: $theme.sizing.unit24,
}));

const ImageWrapper = styled('div', () => ({
  textAlign: 'center',
  backgroundImage: `url(${advocateImagePath})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: '100% 100%',
  width: '50vw',
  height: '50vw',
  minWidth: '192px',
  minHeight: '192px',
  maxHeight: '384px',
}));

const HeaderWrapper = styled('div', ({ $theme }) => ({
  paddingRight: $theme.sizing.unit48,
}));

const CallUsLink = styled('a', ({ $theme }) => ({
  color: $theme.colors.accent,
}));

const TextSpacing = styled('span', ({ $theme }) => ({
  display: 'block',
  height: $theme.sizing.unit16,
}));

const BoldText = styled(Text, () => ({
  fontWeight: 800,
  display: 'inline',
}));

const ModalContents = ({ onClose }: { onClose: Function }) => {
  const handlePhoneLinkClick = (event: any) => {
    event.preventDefault();
    window.open(event.currentTarget.href, '_self');
  };

  return (
    <ContentWrapper>
      <HeaderWrapper>
        <Text variant="HeadingSmall">Call us at your convenience</Text>
      </HeaderWrapper>
      <ImageWrapper />
      <Text variant="ParagraphMedium">
        You can also call our team at{' '}
        <CallUsLink
          href="tel:+14157924961"
          onClick={handlePhoneLinkClick}
          target="_blank"
          rel="noopener noreferrer"
        >
          415-792-4961
        </CallUsLink>{' '}
        to have us confirm your experience and preferences.
        <TextSpacing />
        Hours:{' '}
        <BoldText variant="ParagraphMedium">
          10 AM - 6 PM ({getShortTimezone()})
        </BoldText>
        , Mon. - Fri.
        <TextSpacing />
        Tips: Press the number to call directly, or take a screenshot for later!
      </Text>
      <Button
        color="alternate"
        kind="tertiary"
        onClick={() => onClose('talent.screening.call_us.got_it')}
        fullWidth
      >
        OK, got it
      </Button>
    </ContentWrapper>
  );
};

const CallUsModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: Function;
}): JSX.Element => (
  <FloatModal
    isOpen={isOpen}
    innerPaddingBottom="40px"
    innerPaddingTop="24px"
    innerPaddingRight="24px"
    onClose={() => onClose('talent.screening.call_us.close')}
  >
    <ModalContents onClose={onClose} />
  </FloatModal>
);

export default CallUsModal;
