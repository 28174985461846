export default function simplePluralize(
  count,
  wordSingular,
  wordPlural,
  includeCount = true
) {
  const wordPluralized = `${count === 1 ? wordSingular : wordPlural}`;
  if (includeCount) {
    return `${count} ${wordPluralized}`;
  }
  return wordPluralized;
}
