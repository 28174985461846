import qs from 'qs';
import { UTM_PARAM_NAMES } from 'shared/constants/constants.json';

export function parsePageQuery(queryString?: string) {
  return qs.parse(queryString || window.location.search, {
    ignoreQueryPrefix: true,
  });
}

export function parseUtmParams() {
  const query = parsePageQuery();

  const utmParams = {};
  UTM_PARAM_NAMES.forEach((utmParamName) => {
    if (query[utmParamName]) {
      utmParams[utmParamName] = query[utmParamName];
    }
  });

  return utmParams;
}

export function parseIntParam(intParamName: string) {
  const query = parsePageQuery();

  const idString = query[intParamName];
  if (!idString) return null;

  const parsedId = parseInt(idString as string, 10);
  if (!parsedId) return null;

  return parsedId;
}

export function removeParam(paramName: string) {
  const url = new URL(window.location.href);
  url.searchParams.delete(paramName);
  window.history.replaceState({}, '', url);
}
