const getLongTimezone = (): string =>
  Intl.DateTimeFormat().resolvedOptions().timeZone;

const getDateTimeFormatter = (longTimezone: string) => {
  try {
    const dtFormatter = new Intl.DateTimeFormat('en-US', {
      timeZone: longTimezone,
      timeZoneName: 'longGeneric',
    });
    return dtFormatter;
  } catch (err) {
    // This fall-back is mainly for Node 16, Node 17 adds support for longGeneric
    const dtFormatter = new Intl.DateTimeFormat('en-US', {
      timeZone: longTimezone,
      timeZoneName: 'long',
    });
    return dtFormatter;
  }
};

export const getTimezone = (longTimezoneParam?: string): string => {
  let longTimezone = longTimezoneParam;
  if (!longTimezone) {
    longTimezone = getLongTimezone();
  }
  const dtFormatter = getDateTimeFormatter(longTimezone);
  const tzName = dtFormatter.format(new Date()).split(', ')[1];
  const tzNameArr = tzName.split(' ');
  if (tzNameArr.length === 3) {
    tzNameArr.splice(1, 1);
    return tzNameArr.join(' ');
  }
  return tzName;
};

export const getShortTimezone = (longTimezone?: string): string => {
  const tzName = getTimezone(longTimezone);
  const shortTzName = tzName
    .split(' ')
    .map((word) => word.charAt(0))
    .join('');
  return shortTzName;
};
