export default function getGoogleClickID(query) {
  if (query.gclid) {
    // based on: https://support.google.com/google-ads/answer/7012522?hl=en
    if (!query.gclsrc || query.gclsrc.indexOf('aw') !== -1) {
      return query.gclid;
    }
  }

  return null;
}
