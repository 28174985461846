import React, { Dispatch, SetStateAction, useContext } from 'react';
import { LocationMotivationButtonSelect } from 'registration/components/new_pages/OnboardingLocationMotivation/LocationMotivationButtonSelect';
import { LocationMotivation } from 'registration/components/new_pages/OnboardingLocationMotivation/constants';
import {
  Onboarding,
  OnboardingContext,
  OnboardingSubtitle,
  OnboardingTitle,
} from 'registration/containers/Onboarding';
import {
  BusinessDealIcon,
  MoneyBagsIcon,
  StairsAscendIcon,
} from 'shared/components/ihcl/icon';
import { Center } from 'shared/components/ihcl/positioning';
import { OnboardingData } from 'registration/helpers/types';
import { pathToVariant } from 'shared/helpers/activateABTest';

const motivationOptions = [
  {
    label: (
      <>
        <StairsAscendIcon /> Career advancement
      </>
    ),
    value: LocationMotivation.CareerAdvancement,
  },
  {
    label: (
      <>
        <BusinessDealIcon /> Possibility of getting hired
      </>
    ),
    value: LocationMotivation.PlacementPotential,
  },
  {
    label: (
      <>
        <MoneyBagsIcon /> Compensation
      </>
    ),
    value: LocationMotivation.Compensation,
  },
];

const getSelectedOptions = (selectedOptions) =>
  motivationOptions.filter((motivationOption) =>
    selectedOptions.includes(motivationOption.value)
  );

interface LocationMotivationProps {
  nextAction?: string;
  previousAction: string;
  stepNumber: number;
}

// eslint-disable-next-line react/require-default-props
const OnboardingLocationMotivation: React.FC<LocationMotivationProps> = ({
  // eslint-disable-next-line react/require-default-props
  nextAction,
  previousAction,
  stepNumber,
}) => {
  const { onboardingBasePath, onboardingData, setOnboardingData } = useContext<{
    onboardingBasePath: string;
    onboardingData: OnboardingData;
    onboardingUpdates: any;
    setOnboardingData: Dispatch<SetStateAction<any>>;
    setOnboardingUpdates: Dispatch<SetStateAction<any>>;
  }>(OnboardingContext);
  let { location_motivation: selectedLocationMotivations } = onboardingData;
  if (!selectedLocationMotivations) {
    selectedLocationMotivations = [];
  }

  const variant = pathToVariant(onboardingBasePath);
  const isYukiVariant = variant === 'yuki';

  const customStepContentsStyle = isYukiVariant
    ? {}
    : {
        justifyContent: 'space-evenly',
      };

  return (
    <Center>
      <Onboarding
        autoSpacedStepContents={!isYukiVariant}
        currentStep={stepNumber}
        customStepContentsStyle={customStepContentsStyle}
        isNextDisabled={!selectedLocationMotivations.length}
        nextAction={nextAction}
        previousAction={previousAction}
        stepKeys={['location_motivation']}
      >
        <div>
          <OnboardingTitle
            $reducedFontSize={isYukiVariant}
            $additionalTopSpacing={isYukiVariant}
          >
            What matters to you when deciding where to work?
          </OnboardingTitle>
          <OnboardingSubtitle>
            We&apos;ll use this info to find the best opportunities for you!
          </OnboardingSubtitle>
        </div>
        <div>
          <LocationMotivationButtonSelect
            onChange={(_val, option, remove) =>
              remove
                ? setOnboardingData({
                    ...onboardingData,
                    location_motivation: selectedLocationMotivations.filter(
                      (selectedLocationMotivation) =>
                        selectedLocationMotivation !== option.value
                    ),
                  })
                : setOnboardingData({
                    ...onboardingData,
                    location_motivation: [
                      ...selectedLocationMotivations,
                      option.value,
                    ].sort(),
                  })
            }
            options={motivationOptions}
            selectedOptions={getSelectedOptions(selectedLocationMotivations)}
          />
        </div>
      </Onboarding>
    </Center>
  );
};

OnboardingLocationMotivation.defaultProps = {
  nextAction: null,
};

export default OnboardingLocationMotivation;
