import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { getRelativePath, isExternalURL } from 'shared/helpers/linking';
import { trackClick } from 'shared/helpers/tracking';

function genClickHandler(isAnchor, eventName, eventProperties, onClick) {
  if (!eventName) {
    return onClick;
  }

  const originalOnClick = onClick || (() => {});

  if (isAnchor) {
    return (e) => {
      e.preventDefault();
      const { href } = e.currentTarget;

      trackClick(eventName, eventProperties, () => {
        originalOnClick(e);
        window.location.href = href;
      });
    };
  }

  return (e) => {
    trackClick(eventName, eventProperties, () => {
      originalOnClick(e);
    });
  };
}

// eslint-disable-next-line react/require-default-props
export default function ILink({
  // eslint-disable-next-line react/require-default-props
  children,
  // eslint-disable-next-line react/require-default-props
  eventName,
  // eslint-disable-next-line react/require-default-props
  eventProperties,
  // eslint-disable-next-line react/require-default-props
  isExternal,
  // eslint-disable-next-line react/require-default-props
  onClick,
  // eslint-disable-next-line react/require-default-props
  state,
  // eslint-disable-next-line react/require-default-props
  text,
  to,
  ...props
}) {
  const TagName = isExternal || isExternalURL(to) ? 'a' : Link;

  const handleClick = genClickHandler(
    TagName === 'a',
    eventName,
    eventProperties,
    onClick
  );

  const attrs = { onClick: handleClick, ...props };
  if (TagName === 'a') {
    attrs.href = to;
  } else {
    attrs.to = {
      pathname: to,
      state: {
        ...(state || {}),
        from: getRelativePath(),
      },
    };
  }

  return <TagName {...attrs}>{text || children}</TagName>;
}

ILink.propTypes = {
  children: PropTypes.node,
  eventName: PropTypes.string,
  eventProperties: PropTypes.object,
  isExternal: PropTypes.bool,
  onClick: PropTypes.func,
  state: PropTypes.object,
  text: PropTypes.string,
  to: PropTypes.string.isRequired,
};

ILink.defaultProps = {
  children: null,
  eventName: null,
  eventProperties: {},
  isExternal: false,
  onClick: () => {},
  state: null,
  text: null,
};
