import React from 'react';
import {
  Banner as BaseUiBanner,
  BannerProps as BaseUiBannerProps,
  HIERARCHY,
  KIND,
} from 'baseui/banner';

type Props = Pick<
  BaseUiBannerProps,
  'action' | 'artwork' | 'children' | 'hierarchy' | 'kind' | 'title'
>;

export const Banner = ({
  action,
  artwork,
  children,
  hierarchy,
  kind,
  title,
}: Props) => (
  <BaseUiBanner
    action={action}
    artwork={artwork}
    hierarchy={hierarchy}
    kind={kind}
    title={title}
    overrides={{
      Root: {
        style: ({ $theme }) => ({
          borderRadius: $theme.sizing.unit10,
          ...(hierarchy === HIERARCHY.high &&
            kind === KIND.warning && {
              backgroundColor: $theme.colors.warning,
            }),
        }),
      },
      LeadingContent: {
        style: ({ $theme }) => ({
          alignItems: 'flex-start',
          paddingTop: $theme.sizing.unit16,
          [$theme.mediaQuery.medium]: {
            width: '100%',
          },
          [$theme.mediaQuery.lt.medium]: {
            width: $theme.sizing.scale0,
          },
        }),
      },
      TrailingIconButton: {
        style: ({ $theme }) => ({
          alignItems: 'flex-start',
          paddingTop: $theme.sizing.unit16,
        }),
      },
    }}
  >
    {children}
  </BaseUiBanner>
);
