import React, { useEffect, useState } from 'react';
import { generatePath } from 'react-router';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';

import activateABTest, {
  getRegVariantChar,
} from 'shared/helpers/activateABTest';
import ErrorBoundary from 'shared/components/ErrorBoundary';
import withTracking from 'shared/helpers/withTrackingFunctional';
import { parsePageQuery } from 'shared/helpers/query';

import OnboardingFormGamora from '../components/non_hiring/OnboardingFormGamora';
import { getPreviewTrackingParams } from '../helpers/getPreviewTrackingParams';

export const nonHiringIntents = [
  'advice',
  'education',
  'mental_health',
  'network',
  'resume',
  'salary',
];
export const nonHiringIntentRegex = `(${nonHiringIntents.join('|')})`;

const TalentOnboardLiteContainer = () => {
  const history = useHistory();
  const location = useLocation();
  const { intent, type } = useParams();

  const { path } = useRouteMatch();
  const funnelChar = useParams().funnelChar || '';

  let abTestVariantGroup;
  switch (intent) {
    case 'salary':
      abTestVariantGroup = 'onboardingLiteSalary';
      break;
    case 'advice':
      abTestVariantGroup = 'onboardingLiteAdvice';
      break;
    case 'education':
    case 'network':
      abTestVariantGroup = 'onboardingLiteEducation';
      break;
    case 'resume':
      abTestVariantGroup = 'onboardingLiteResume';
      break;
    default:
      abTestVariantGroup = 'onboardingLite';
  }

  const [regVariant] = useState(
    activateABTest('regVariant', undefined, abTestVariantGroup)
  );
  useEffect(() => {
    const query = parsePageQuery(location.search, {
      ignoreQueryPrefix: true,
    });

    const mpOptions = getPreviewTrackingParams(
      query.preview_checked,
      query.preview_enrolled
    );

    mpOptions['Onboard Lite Variant'] = regVariant;
    mixpanel.track('Onboard Lite Visited', mpOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const regVariantChar = getRegVariantChar(regVariant);
  if (regVariantChar !== funnelChar && nonHiringIntents.includes(intent)) {
    const newPath = generatePath(path, {
      type,
      intent,
      funnelChar: regVariantChar,
    });
    history.replace({
      pathname: newPath,
      search: location.search,
    });
    // Avoids a redirect loop that recursively appends `/1` to the URL when the
    // funnelChar is missing from the URL.
    return null;
  }

  return (
    <Switch>
      <ErrorBoundary>
        <Route path={`${path}/:step?`}>
          <OnboardingFormGamora intent={intent} />
        </Route>
      </ErrorBoundary>
    </Switch>
  );
};

TalentOnboardLiteContainer.propTypes = {};

export default withTracking(TalentOnboardLiteContainer);
