import React from 'react';
import { styled } from 'shared/components/ihcl/styled';
import { CloseIcon } from 'shared/components/ihcl/icon';

const CloseButtonWrapper = styled('button', ({ $theme }) => ({
  background: 'transparent',
  outline: 0,
  paddingLeft: 0,
  paddingTop: 0,
  paddingRight: 0,
  paddingBottom: 0,
  borderLeftColor: 'transparent',
  borderRightColor: 'transparent',
  borderTopColor: 'transparent',
  borderBottomColor: 'transparent',
  color: $theme.colors.modalCloseColor,
  ':hover': {
    color: $theme.colors.modalCloseColorHover,
  },
  ':focus': {
    outline: `3px solid ${$theme.colors.accent}`,
  },
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  width: $theme.sizing.scale600,
  height: $theme.sizing.scale600,
}));

const CloseButton = ({ ...props }) => (
  <CloseButtonWrapper {...props}>
    <CloseIcon />
  </CloseButtonWrapper>
);

export default CloseButton;
