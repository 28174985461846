export enum LocationMotivation {
  CareerAdvancement = 'career_advancement',
  Compensation = 'compensation',
  IdealLocation = 'ideal_location',
  PlacementPotential = 'placement_potential',
}

export type LocationMotivationButtonSelectOption = {
  label: JSX.Element;
  value: LocationMotivation;
};
