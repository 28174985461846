import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from 'shared/components/ihcl/styled';

import { Button } from 'shared/components/ihcl/button';
import { Checkbox, STYLE_TYPE } from 'shared/components/ihcl/checkbox';
import { Select } from 'shared/components/ihcl/select';
import { Center } from 'shared/components/ihcl/positioning';

import {
  Onboarding,
  OnboardingTitle,
} from 'registration/containers/Onboarding';
import { pathToVariant } from 'shared/helpers/activateABTest';
import { OnboardingContext } from '../../containers/Onboarding';

const DEFAULT_ONBOARDING_TITLE_TEXT =
  'Where do you have an active nursing license?';

const StateLicenseRow = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
});

const StateRows = styled('ul', () => ({
  listStyleType: 'none',
  paddingLeft: 0,
}));

const StateRow = styled('li', ({ $theme }) => ({
  borderBottomColor: $theme.colors.grayLine,
  borderBottomStyle: 'solid',
  borderBottomWidth: '1px',
  paddingTop: '20px',
  paddingBottom: '20px',
}));

export const NoLicense = styled('div', {
  marginTop: '1em',
  marginBottom: '1em',
});

const DefaultStateAction = ({ state, licenseStates, setLicenseStates }) => (
  <Checkbox
    ariaLabel={`${state.name}`}
    checked={state.hasActiveLicense}
    checkmarkType={STYLE_TYPE.toggle_round}
    onChange={(e) => {
      setLicenseStates(
        licenseStates.map((licenseState) => {
          if (state.abbreviation === licenseState.abbreviation) {
            return {
              ...licenseState,
              hasActiveLicense: e.target.checked,
            };
          }
          return licenseState;
        })
      );
    }}
  />
);

DefaultStateAction.propTypes = {
  licenseStates: PropTypes.arrayOf(
    PropTypes.shape({
      abbreviation: PropTypes.string.isRequired,
    })
  ).isRequired,
  setLicenseStates: PropTypes.func.isRequired,
  state: PropTypes.shape({
    abbreviation: PropTypes.string,
    hasActiveLicense: PropTypes.bool,
    name: PropTypes.string,
  }).isRequired,
};

const DefaultCompactAction = ({
  children,
  state,
  licenseStates,
  setLicenseStates,
}) => (
  <Checkbox
    checked={state.hasCompactLicense}
    onChange={(e) => {
      setLicenseStates(
        licenseStates.map((licenseState) => {
          if (state.abbreviation === licenseState.abbreviation) {
            return {
              ...licenseState,
              hasCompactLicense: e.target.checked,
            };
          }
          return licenseState;
        })
      );
    }}
  >
    {children}
  </Checkbox>
);

DefaultCompactAction.propTypes = {
  children: PropTypes.node.isRequired,
  licenseStates: PropTypes.arrayOf(
    PropTypes.shape({
      abbreviation: PropTypes.string.isRequired,
    })
  ).isRequired,
  setLicenseStates: PropTypes.func.isRequired,
  state: PropTypes.shape({
    abbreviation: PropTypes.string.isRequired,
    hasActiveLicense: PropTypes.bool,
    hasCompactLicense: PropTypes.bool,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

// eslint-disable-next-line react/require-default-props
export const ActiveLicenses = ({
  // eslint-disable-next-line react/require-default-props
  CompactAction,
  licenseStates,
  setLicenseStates,
  // eslint-disable-next-line react/require-default-props
  StateAction,
  stateOptions,
}) => {
  const [isAddingState, setIsAddingState] = useState(false);

  const selectOverrides = {
    Popover: {
      props: {
        overrides: {
          Body: {
            style: {
              zIndex: 1201, // 1 higher than TalentModal zIndex
            },
          },
        },
      },
    },
  };

  return (
    <div>
      <StateRows>
        {licenseStates.map((state) => (
          <StateRow key={state.id || state.state_id}>
            <StateLicenseRow>
              {state.name || state.label}
              <StateAction
                state={state}
                licenseStates={licenseStates}
                setLicenseStates={setLicenseStates}
              />
            </StateLicenseRow>
            {state.compact && state.hasActiveLicense && (
              <CompactAction
                state={state}
                licenseStates={licenseStates}
                setLicenseStates={setLicenseStates}
              >
                This is a compact license (eNLC)
              </CompactAction>
            )}
          </StateRow>
        ))}
      </StateRows>
      <Center>
        {!isAddingState && (
          <Button kind="minimal" onClick={() => setIsAddingState(true)}>
            + Add another state
          </Button>
        )}
        {isAddingState && (
          <Select
            instanceId="stateLicenseOptions"
            autoFocus
            startOpen
            options={stateOptions}
            onChange={({ option }) => {
              if (option && option.id) {
                if (
                  !licenseStates.some(
                    (licenseState) => licenseState.id === option.id
                  )
                ) {
                  setLicenseStates([
                    ...licenseStates,
                    {
                      ...option,
                      hasActiveLicense: true,
                    },
                  ]);
                }
                setIsAddingState(false);
              }
            }}
            overrides={selectOverrides}
          />
        )}
      </Center>
    </div>
  );
};
ActiveLicenses.propTypes = {
  CompactAction: PropTypes.elementType,
  licenseStates: PropTypes.arrayOf(PropTypes.object).isRequired,
  setLicenseStates: PropTypes.func.isRequired,
  StateAction: PropTypes.elementType,
  stateOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
};
ActiveLicenses.defaultProps = {
  CompactAction: DefaultCompactAction,
  StateAction: DefaultStateAction,
};

// eslint-disable-next-line react/require-default-props
const OnboardingLicenses = ({
  nextAction = null,
  previousAction = null,
  stepNumber,
}) => {
  const {
    onboardingBasePath,
    onboardingData,
    onboardingOptions,
    onboardingUpdates,
    setOnboardingData,
  } = useContext(OnboardingContext);
  const {
    currentLocation,
    desiredLocations,
    license_being_processed: licenseBeingProcessed,
  } = onboardingData;
  const { other_locations_attributes: otherLocationsAttributes } =
    onboardingUpdates;
  const locations = [
    currentLocation,
    ...(otherLocationsAttributes || []),
    ...(desiredLocations || []),
  ];
  const statesToCheck = Object.keys(
    locations.reduce((statesMap, location) => {
      const states = { ...statesMap };
      if (location && location.state) {
        states[location.state] = true;
      }
      return states;
    }, {})
  );
  const initialLicenseStates = onboardingData.state_licenses || [];
  onboardingOptions.states
    .filter((state) => statesToCheck.includes(state.abbreviation))
    .forEach((state) => {
      if (
        !initialLicenseStates.find(
          (licenseState) => licenseState.id === state.id
        )
      ) {
        initialLicenseStates.push(state);
      }
    });
  initialLicenseStates.sort((a, b) => a.name.localeCompare(b.name));
  const [noActiveLicense, setNoActiveLicense] = useState(false);
  const [licenseStates, setLicenseStates] = useState(initialLicenseStates);
  const setStateLicenses = (newLicenseStates) => {
    if (
      newLicenseStates.some((licenseState) => licenseState.hasActiveLicense) &&
      noActiveLicense
    ) {
      setNoActiveLicense(false);
    }
    setLicenseStates(newLicenseStates);
    setOnboardingData({
      ...onboardingData,
      state_licenses_attributes: newLicenseStates
        .filter((licenseState) => licenseState.hasActiveLicense)
        .map((licenseState) => ({
          state_id: licenseState.id,
          compact: licenseState.compact
            ? !!licenseState.hasCompactLicense
            : undefined,
        })),
    });
  };

  const variant = pathToVariant(onboardingBasePath);
  const isYukiVariant = variant === 'yuki';

  let onboardingTitleText = DEFAULT_ONBOARDING_TITLE_TEXT;
  if (onboardingData.signup_intent === 'resume') {
    onboardingTitleText =
      '🎉 Last one! Where do you have an active nursing license?';
  }
  return (
    <Onboarding
      autoSpacedStepContents={!isYukiVariant}
      currentStep={stepNumber}
      isNextDisabled={
        !licenseStates.some((licenseState) => licenseState.hasActiveLicense) &&
        !noActiveLicense
      }
      nextAction={nextAction}
      previousAction={previousAction}
      stepKeys={['state_licenses_attributes', 'license_being_processed']}
    >
      <OnboardingTitle
        $reducedFontSize={isYukiVariant}
        $additionalTopSpacing={isYukiVariant}
      >
        {onboardingTitleText}
      </OnboardingTitle>
      <ActiveLicenses
        licenseStates={licenseStates}
        setLicenseStates={setStateLicenses}
        stateOptions={onboardingOptions.states.map((state) => ({
          ...state,
          disabled: licenseStates.some(
            (licenseState) => licenseState.id === state.id
          ),
          label: state.name,
        }))}
      />
      <NoLicense>
        <Checkbox
          checked={noActiveLicense}
          onChange={(e) => {
            if (e.target.checked) {
              setStateLicenses(
                licenseStates.map((licenseState) => ({
                  ...licenseState,
                  hasActiveLicense: false,
                }))
              );
            }
            setNoActiveLicense(e.target.checked);
          }}
        >
          I don&apos;t have an active license
        </Checkbox>
        <Checkbox
          checked={licenseBeingProcessed}
          onChange={(e) => {
            setOnboardingData({
              ...onboardingData,
              license_being_processed: e.target.checked,
            });
          }}
        >
          My license is being processed
        </Checkbox>
      </NoLicense>
    </Onboarding>
  );
};
OnboardingLicenses.propTypes = {
  nextAction: PropTypes.string,
  previousAction: PropTypes.string,
  stepNumber: PropTypes.number.isRequired,
};

export default OnboardingLicenses;
