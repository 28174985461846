import React from 'react';
import { styled } from 'shared/components/ihcl/styled';
import { Button } from 'shared/components/ihcl/button';
import { LucideIcon } from 'shared/components/ihcl/lucideIcon';
import { Stack } from 'shared/components/ihcl/patterns';
import { Text } from 'shared/components/ihcl/text';
import mug from 'images/mug.webp';
import { BoldText } from './styledComponents';

const Notice = styled('div', ({ $theme }) => ({
  backgroundColor: $theme.colors.gray00,
  borderRadius: $theme.sizing.unit16,
  paddingTop: $theme.sizing.unit32,
  paddingRight: $theme.sizing.unit40,
  paddingBottom: $theme.sizing.unit32,
  paddingLeft: $theme.sizing.unit40,
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: $theme.sizing.unit8,
}));

const CenteredText = styled(Text, () => ({
  textAlign: 'center',
}));
const Image = styled('img', () => ({
  width: '108px',
}));

const NoAvailableTimesNotice = ({ showCallUs }: { showCallUs: Function }) => (
  <Notice>
    <Image src={mug} alt="Coffee Mug" />
    <Text variant="ParagraphLarge" color="bodyCopyGray">
      <BoldText>We apologize...</BoldText>
    </Text>
    <CenteredText variant="ParagraphSmall" color="grayLabel">
      There isn&apos;t any intro call window left. Please check back a few hours
      later.
    </CenteredText>
    <Button
      onClick={() => {
        showCallUs();
      }}
      size="default"
      kind="minimal"
      color="alternate"
      fullWidth
    >
      <Stack $alignItems="center" $flexDirection="row" $gapSizing="unit8">
        <Text variant="LabelSmall" color="accent">
          Call us at your convenience
        </Text>
        <LucideIcon name="ArrowRight" size="unit16" />
      </Stack>
    </Button>
  </Notice>
);

export default NoAvailableTimesNotice;
