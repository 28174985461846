import { doPost } from 'shared/helpers/http';
import Sentry from 'shared/helpers/sentry';

export function syncPushToken(token, deviceOS, deviceMetadata) {
  doPost('/push_tokens', {
    token,
    device_os: deviceOS,
    device_metadata: deviceMetadata,
  }).catch((error) => {
    Sentry.captureException(error);
  });
}

export function trackIterablePushOpen(
  campaignId,
  messageId,
  templateId,
  dataFields
) {
  doPost('/iterable_push_opens', {
    campaign_id: campaignId,
    message_id: messageId,
    template_id: templateId,
    data_fields: dataFields,
  }).catch((error) => {
    Sentry.captureException(error);
  });
}
