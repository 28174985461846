import { useEffect, useState } from 'react';

const getAutocompleteService = () => {
  if (window.google?.maps?.places?.AutocompleteService) {
    return new window.google.maps.places.AutocompleteService();
  }
  return null;
};

export const useGoogleAutocompleteService = () => {
  const [autocompleteService, setAutocompleteService] = useState(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!autocompleteService) {
      setAutocompleteService(getAutocompleteService());
    }
  });

  return autocompleteService;
};
