import sharedConstants from 'shared/constants/constants.json';

export const getEndDate = (date: Date) => {
  const duration = sharedConstants.SCREENER_AVAILABILITY_BLOCK_SIZE * 60 * 1000;
  return new Date(date.getTime() + duration);
};

export const formattedDateValues = (date: Date) => {
  const optionsDate: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    weekday: 'short',
  };
  const formattedDate = date.toLocaleDateString('en-US', optionsDate);

  const optionsTime: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };
  const startTime = date.toLocaleTimeString('en-US', optionsTime);
  const endDate = getEndDate(date);
  const endTime = endDate.toLocaleTimeString('en-US', optionsTime);

  const [weekday, monthDay] = formattedDate.includes(', ')
    ? formattedDate.split(', ')
    : [formattedDate, ''];

  return { monthDay, weekday, startTime, endTime };
};

export const formattedDateTime = (date: Date): string => {
  const { monthDay, weekday, startTime, endTime } = formattedDateValues(date);

  return `${monthDay} (${weekday}) ${startTime} - ${endTime}`;
};
