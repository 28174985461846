import React, { useState } from 'react';
import { locationFromGooglePlace } from 'registration/helpers/talentProfileConversions';
import { Center } from 'shared/components/ihcl/positioning';
import { Select } from 'shared/components/ihcl/select';

export const AddLocation = ({
  addTag,
  defaultOptions,
  getResultsFromGoogle,
  // eslint-disable-next-line react/require-default-props
  recentGrad,
}: {
  addTag: (x: any) => any;
  defaultOptions: any;
  getResultsFromGoogle: (x: any) => any;
  // eslint-disable-next-line react/require-default-props
  recentGrad?: boolean;
}) => {
  const [locationOptions, setLocationOptions] = useState(defaultOptions);

  const handleInputChange = async (evt) => {
    const { value } = evt.currentTarget;
    if (!value) {
      setLocationOptions(defaultOptions);
      return;
    }

    const results = await getResultsFromGoogle(value);
    if (results) {
      const newLocationOptions = results.map(locationFromGooglePlace);
      if (newLocationOptions[0]?.label === value) {
        addTag(newLocationOptions[0]);
      }
      setLocationOptions(newLocationOptions);
    } else {
      setLocationOptions(defaultOptions);
    }
  };

  return (
    <Center style={{ marginTop: '16px', marginBottom: '16px' }}>
      <Select
        instanceId="desiredLocationOptions"
        clearable={false}
        placeholder={
          recentGrad ? '+ Add Location' : '+ Add a specific location'
        }
        options={locationOptions}
        maxDropdownHeight="85vh"
        onInputChange={handleInputChange}
        onBlur={() => setLocationOptions(defaultOptions)}
        onChange={({ value }) => {
          addTag(value[0]);
          setLocationOptions(defaultOptions);
        }}
      />
    </Center>
  );
};
