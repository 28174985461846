import { Stack } from 'shared/components/ihcl/patterns';
import { styled, withStyle } from 'shared/components/ihcl/styled';
import { ModalFooter } from 'shared/components/ihcl/modal';
import { ModalBody } from 'baseui/modal';

export const InputStack = withStyle(Stack, () => ({}));

export const ExpandingModalBody = withStyle(ModalBody, ({ $theme }) => ({
  flex: '1 1 0', // tell ModalBody to take all available space
  gap: $theme.sizing.unit24,
  display: 'flex',
  flexDirection: 'column',
}));

export const FixedModalFooter = withStyle(ModalFooter, ({ $theme }) => ({
  position: 'sticky',
  left: 0,
  bottom: 0,
  textAlign: 'center',
  marginTop: '16px',
  display: 'flex',
  alignItems: 'flex-start',
  width: '100%',
  justifyContent: 'center',
  gap: $theme.sizing.unit8,
  background: $theme.colors.white,
  paddingTop: $theme.sizing.unit24,
  paddingBottom: $theme.sizing.unit40,
  paddingRight: $theme.sizing.unit24,
  paddingLeft: $theme.sizing.unit24,
  marginLeft: 0,
  marginRight: 0,
  borderTop: `1px solid ${$theme.colors.grayLine}`,
}));

export const PrimaryCTAWrapper = styled('div', () => ({
  flex: '1 0 0',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const BoldText = styled('span', () => ({
  fontWeight: 800,
}));
