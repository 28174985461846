import React, { useEffect, useState } from 'react';
import { HIERARCHY, KIND } from 'baseui/banner';
import { Banner } from 'shared/components/ihcl/banner';
import {
  AlertTriangleIcon,
  ClosePaddedIcon,
} from 'shared/components/ihcl/icon';
import { Text } from 'shared/components/ihcl/text';
import supportedBrowsers from 'shared/helpers/supportedBrowsers';
import { trackEvent } from 'shared/helpers/tracking';
import { useStyletron } from 'shared/components/ihcl/styled';
import { isUntouched, touch } from 'shared/helpers/localforage';
import { isNative } from 'shared/helpers/native';

const UNSUPPORTED_BROWSER_KEY = 'unsupported-browser';
const BANNER_CLOSED_KEY = 'unsupported-browser-banner-closed';

export const UnsupportedBrowserBanner = () => {
  const [, theme] = useStyletron();
  const [isDismissed, setIsDismissed] = useState(true);

  const { userAgent } = navigator;
  const isUnsupportedBrowser =
    !isNative() && !supportedBrowsers.test(userAgent);

  useEffect(() => {
    if (isUnsupportedBrowser) {
      isUntouched(UNSUPPORTED_BROWSER_KEY).then(async (isUntouchedVal) => {
        if (isUntouchedVal) {
          await touch(UNSUPPORTED_BROWSER_KEY);
          trackEvent('Unsupported Browser', { userAgent });
        }
      });
      isUntouched(BANNER_CLOSED_KEY).then(async (isUntouchedVal) => {
        if (isUntouchedVal) setIsDismissed(false);
      });
    }
  }, [isUnsupportedBrowser, userAgent]);

  const handleClick = async () => {
    await touch(BANNER_CLOSED_KEY);
    setIsDismissed(true);
  };

  return (
    isUnsupportedBrowser &&
    !isDismissed && (
      <Banner
        action={{
          label: 'Dismiss',
          // eslint-disable-next-line react/no-unstable-nested-components
          icon: () => <ClosePaddedIcon title="Dismiss" />,
          onClick: handleClick,
        }}
        artwork={{
          // eslint-disable-next-line react/no-unstable-nested-components
          icon: () => <AlertTriangleIcon />,
        }}
        hierarchy={HIERARCHY.high}
        kind={KIND.warning}
        title={
          <Text variant="LabelSmall">
            Hi! It looks like you’re using an outdated or unsupported web
            browser.
          </Text>
        }
      >
        <Text variant="LabelXSmall" marginTop={theme.sizing.unit8}>
          For an optimal experience, we recommend upgrading or using the latest
          version of Google Chrome.
        </Text>
      </Banner>
    )
  );
};
