import * as React from 'react';
import { styled, useStyletron } from 'shared/components/ihcl/styled';

type TextProps = {
  element?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
  variant: string;
  color?: string;
  marginTop?: string;
  marginRight?: string;
  marginBottom?: string;
  marginLeft?: string;
};

export const examplePageFontSort = (
  fontVariants: string[],
  fontSections: string[]
) => {
  const fontVariantsObj: any = fontSections.reduce(
    (obj, section) => ({ ...obj, [section]: [] }),
    {}
  );
  fontVariants.forEach((fontVariant) => {
    const fontVariantSection = fontSections.filter((fs) =>
      fontVariant.startsWith(fs)
    )[0];
    if (fontVariantSection === undefined) {
      throw new Error(`Missing font section for ${fontVariant}`);
    }
    fontVariantsObj[fontVariantSection].push(
      fontVariant.replace(fontVariantSection, '')
    );
  });

  if (fontVariantsObj.font) {
    fontVariantsObj.font.sort((a, b) =>
      a.localeCompare(b, 'en', { numeric: true })
    );
  }
  const sortedFontVariantsArr = [];
  fontSections.forEach((section) => {
    fontVariantsObj[section].forEach((variant) => {
      sortedFontVariantsArr.push(`${section}${variant}`);
    });
  });
  return sortedFontVariantsArr;
};
// eslint-disable-next-line react/require-default-props
export const Text: React.FC<TextProps> = ({
  element = 'div',
  variant,
  // eslint-disable-next-line react/require-default-props
  color,
  // eslint-disable-next-line react/require-default-props
  marginTop,
  // eslint-disable-next-line react/require-default-props
  marginRight,
  // eslint-disable-next-line react/require-default-props
  marginBottom,
  // eslint-disable-next-line react/require-default-props
  marginLeft,
  ...props
}) => {
  const [, theme] = useStyletron();
  if (theme.typography[variant]) {
    if (!theme.colors[color]) {
      throw new Error(`${color} not found in theme colors`);
    }
    const TextComponent = styled(element, {
      ...theme.typography[variant],
      color: theme.colors[color],
      marginTop,
      marginRight,
      marginBottom,
      marginLeft,
    });
    return <TextComponent {...props} />;
  }
  throw new Error(`${variant} not found in theme typography`);
};
Text.defaultProps = {
  element: 'div',
  color: 'black',
  marginTop: '0',
  marginRight: '0',
  marginBottom: '0',
  marginLeft: '0',
};
