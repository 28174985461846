import { Collection, Map } from 'immutable';
import lodash from 'lodash';
import { EmailValidator } from 'commons-validator-js';

import { Place } from '../records/Place';

export const REQUIRED_ERROR_MESSAGE = 'Required';

const MIN_HOURLY_WAGE = 1;
const MAX_HOURLY_WAGE = 300;

export function validateRequired(state, ...fields) {
  let errors = Map();

  fields.forEach((fieldName) => {
    const fieldValue = state[fieldName];
    if (
      fieldValue === null ||
      typeof fieldValue === 'undefined' ||
      Number.isNaN(fieldValue) ||
      fieldValue.length === 0
    ) {
      errors = errors.set(fieldName, REQUIRED_ERROR_MESSAGE);
    } else if (fieldValue instanceof Place && !fieldValue.city) {
      errors = errors.set(fieldName, REQUIRED_ERROR_MESSAGE);
    } else if (fieldValue instanceof Collection && fieldValue.isEmpty()) {
      errors = errors.set(fieldName, REQUIRED_ERROR_MESSAGE);
    }
  });

  return errors;
}

export function validateEmail(email) {
  let error = '';

  const validator = new EmailValidator();

  if (!validator.isValid(email)) {
    error = "This isn't a valid email address.";
  }

  return error;
}

export function validateNumRange(input, min, max) {
  const num = Number(input);
  if (Number.isNaN(num) || num < min || num > max) {
    return `Must be between ${min} and ${max}`;
  }
  return '';
}

export function validateHourlyWage(wage) {
  if (wage == null || wage === '') return '';
  const num = wage.toString().trim().replace('$', '');
  return validateNumRange(num, MIN_HOURLY_WAGE, MAX_HOURLY_WAGE);
}

export function validateNumUpTo(max, input) {
  if (input == null || input === '') return '';
  const num = parseInt(input.toString().trim(), 10);
  if (Number.isNaN(num) || num < 0 || num > max) {
    return `Must be between 0 and ${max}`;
  }
  return '';
}

export function validateURL(str) {
  const a = document.createElement('a');
  a.href = str;
  if (!a.host || a.host === window.location.host) {
    return 'Must be a valid url';
  }
  return '';
}

/**
 * Scrolls the topmost element with an error into view.
 *
 * @param {Array} errorElementList - A array of two parameter arrays
 *   where the first element is the error key to look for and the second
 *   parameter is the id of the element that should be scrolled into view if
 *   the key is found. The items should be order based on where they appear on
 *   the page.
 *   An array containing only the error keys can also be passed, in that case
 *   it will be assumed that the element id is the element key in dashed case
 *   with '-label' appended.
 * @param {Map} errors - the list of errors.
 */
export function scrollToError(errorElementArray, errors) {
  for (let i = 0; i < errorElementArray.length; i += 1) {
    const entry = errorElementArray[i];
    let errorKey = null;
    let elementId = null;

    if (Array.isArray(entry)) {
      [errorKey, elementId] = entry;
    } else {
      errorKey = entry;
      elementId = `${lodash.kebabCase(errorKey)}-label`;
    }

    if (errors.has(errorKey)) {
      document.getElementById(elementId).scrollIntoView();
      break;
    }
  }
}
