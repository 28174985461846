import React from 'react';
import { styled } from 'shared/components/ihcl/styled';
import PropTypes from 'prop-types';

import { Button } from './button';
import { CloseIcon as UnsizedCloseIcon } from './icon';

export const UNSELECTED = 'unselected';

const ButtonWrapperVertical = styled(
  'div',
  ({ $theme, $fillWidth, $rowGap }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: $fillWidth ? 'initial' : 'center',
    rowGap: $rowGap || $theme.sizing.scale600,
  })
);

const ButtonWrapperHorizontal = styled('div', ({ $theme, $columnGap }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  columnGap: $columnGap || $theme.sizing.scale600,
  rowGap: $theme.sizing.scale600,
  justifyContent: 'center',
  flexWrap: 'wrap',
}));

const optionType = PropTypes.shape({
  label: PropTypes.node.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
  ]).isRequired,
});

const getSelectedOption = (selectedItem, options) => {
  if (!selectedItem || !options) {
    return null;
  }
  let selectedOption = options.find((option) => option.value === selectedItem);
  if (!selectedOption && selectedItem.value) {
    selectedOption = options.find(
      (option) => option.value === selectedItem.value
    );
  }
  return selectedOption;
};

const CloseIcon = () => <UnsizedCloseIcon height="12" width="12" />;

// eslint-disable-next-line react/require-default-props
export const ButtonSelect = ({
  // eslint-disable-next-line react/require-default-props
  buttonComponent,
  // eslint-disable-next-line react/require-default-props
  buttonProps,
  // eslint-disable-next-line react/require-default-props
  fillWidth,
  // eslint-disable-next-line react/require-default-props
  multiSelect,
  options,
  onChange,
  // eslint-disable-next-line react/require-default-props
  orientation,
  // eslint-disable-next-line react/require-default-props
  padding,
  // eslint-disable-next-line react/require-default-props
  radioSelect,
  // eslint-disable-next-line react/require-default-props
  selectedOptions,
  // eslint-disable-next-line react/require-default-props
  itemGap,
}) => {
  const Wrapper =
    orientation === 'horizontal'
      ? ButtonWrapperHorizontal
      : ButtonWrapperVertical;
  const $fillWidth = orientation === 'vertical' && fillWidth;
  let selectedOptionObj = selectedOptions;
  const isSelected = (option) => {
    if (!Array.isArray(selectedOptions)) {
      return false;
    }
    return selectedOptions.some(
      (selectedOption) => selectedOption.value === option.value
    );
  };
  const getButtonKind = (option) => {
    if (!selectedOptions || !(multiSelect || radioSelect)) {
      return 'tertiary';
    }
    if (isSelected(option)) {
      return 'primary';
    }
    return 'tertiary';
  };
  let visibleOptions;
  if (['string', 'boolean', 'number'].includes(typeof selectedOptionObj)) {
    selectedOptionObj = getSelectedOption(selectedOptionObj, options);
  }
  if (Array.isArray(selectedOptionObj) && !multiSelect) {
    selectedOptionObj = selectedOptionObj[0];
  }

  const ButtonOption = buttonComponent;
  if (
    selectedOptionObj &&
    selectedOptionObj.label &&
    !multiSelect &&
    !radioSelect
  ) {
    visibleOptions = (
      <Button
        onClick={() => onChange(null)}
        endEnhancer={CloseIcon}
        padding={padding}
      >
        {selectedOptionObj.label}
      </Button>
    );
  } else {
    visibleOptions = options.map((option) => (
      <ButtonOption
        padding={padding}
        key={option.value.toString()}
        onClick={() => onChange(option.value, option, isSelected(option))}
        kind={getButtonKind(option)}
        {...buttonProps}
      >
        {option.label}
      </ButtonOption>
    ));
  }
  return (
    <Wrapper
      $optionCount={options.length}
      $fillWidth={$fillWidth}
      $rowGap={itemGap}
      $columnGap={itemGap}
    >
      {visibleOptions}
    </Wrapper>
  );
};
ButtonSelect.propTypes = {
  buttonComponent: PropTypes.elementType,
  buttonProps: PropTypes.object,
  fillWidth: PropTypes.bool,
  itemGap: PropTypes.string,
  multiSelect: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(optionType).isRequired,
  orientation: PropTypes.oneOf(['vertical', 'horizontal']),
  padding: PropTypes.oneOf(['default', 'minimal']),
  radioSelect: PropTypes.bool,
  selectedOptions: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.number,
        optionType,
      ])
    ),
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
    optionType,
  ]),
};
ButtonSelect.defaultProps = {
  buttonComponent: Button,
  buttonProps: null,
  fillWidth: false,
  itemGap: null,
  multiSelect: false,
  orientation: 'vertical',
  padding: 'default',
  radioSelect: false,
  selectedOptions: [],
};
