import { handleActions } from 'redux-actions';

import {
  serverErrorsReceived,
  updateTalentProfileStarted,
} from 'shared/actions/talentProfileActions';

const serverErrorsReducer = handleActions(
  {
    [serverErrorsReceived]: (state, action) => action.payload.errors,
    [updateTalentProfileStarted]: () => ({}),
  },
  {}
);

export default serverErrorsReducer;
