import React from 'react';
import { LocationMotivationButtonSelectOption } from 'registration/components/new_pages/OnboardingLocationMotivation/constants';
import { ButtonSelect } from 'shared/components/ihcl/button_select';

export const LocationMotivationButtonSelect = ({
  onChange,
  options,
  selectedOptions,
}: {
  onChange: (
    value: string,
    option: LocationMotivationButtonSelectOption,
    remove: boolean
  ) => void;
  options: LocationMotivationButtonSelectOption[];
  selectedOptions: LocationMotivationButtonSelectOption[];
}) => (
  <ButtonSelect
    buttonProps={{
      style: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '8px',
      },
    }}
    fillWidth
    multiSelect
    onChange={onChange}
    selectedOptions={selectedOptions}
    options={options}
  />
);
