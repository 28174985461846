/* eslint-disable max-classes-per-file */
import {
  Checkbox,
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  Grid,
  Row,
} from 'react-bootstrap';
import { List, Map, Record } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import isEmpty from 'just-is-empty';
import moment from 'moment';
import Select from 'react-select';
import stringHash from 'string-hash';
import { LucideIcon } from 'shared/components/ihcl/lucideIcon';
import { trackClick } from 'shared/helpers/tracking';
import { validateRequired } from 'shared/helpers/validations';
import applyItemsToRailsNestedAttributes from 'shared/helpers/applyItemsToRailsNestedAttributes';
import IButton from 'shared/components/IButton';
import MonthSelector from 'shared/components/MonthSelector';

const workExperienceParams = {
  id: null,
  companyName: '',
  location: '',
  jobTitle: '',
  startMonth: '',
  startYear: '',
  endMonth: '',
  endYear: '',
  currentlyThere: false,
  duties: '',
  workExperienceSpecialties: List(),
  _destroy: false,
};

export class WorkExperience extends Record(workExperienceParams) {
  isEmpty() {
    return (
      isEmpty(this.companyName) &&
      isEmpty(this.location) &&
      isEmpty(this.jobTitle) &&
      isEmpty(this.startMonth) &&
      isEmpty(this.startYear) &&
      isEmpty(this.endMonth) &&
      isEmpty(this.endYear) &&
      isEmpty(this.duties) &&
      this.getKeptSpecialties().isEmpty()
    );
  }

  getDate(type) {
    let date = '';
    const month = this.get(`${type}Month`);
    const year = this.get(`${type}Year`);
    if (month && year) {
      date = `${year}-${month}-01`;
    }
    return date;
  }

  getStartDate() {
    return this.getDate('start');
  }

  getEndDate() {
    return this.currentlyThere ? '' : this.getDate('end');
  }

  getKeptSpecialties() {
    // OnboardingResume uses Immutable merge() which converts plain JS object
    // to Map when manually adding or removing specialties.
    // However, WorkExperienceControl treats this as a plain JS object.
    return (
      this.workExperienceSpecialties?.filter((wes) =>
        Map.isMap(wes) ? wes.get('_destroy') !== true : wes._destroy !== true
      ) || List()
    );
  }

  equals(other) {
    return (
      this.companyName === other.companyName &&
      this.location === other.location &&
      this.jobTitle === other.jobTitle &&
      this.startMonth === other.startMonth &&
      this.startYear === other.startYear &&
      this.endMonth === other.endMonth &&
      this.endYear === other.endYear &&
      this.currentlyThere === other.currentlyThere &&
      this.duties === other.duties &&
      this.workExperienceSpecialties.equals(other.workExperienceSpecialties)
    );
  }

  hashCode() {
    return stringHash(
      [
        this.id,
        this.companyName,
        this.location,
        this.jobTitle,
        this.startMonth,
        this.startYear,
        this.endMonth,
        this.endYear,
        this.currentlyThere,
        this.duties,
        this.workExperienceSpecialties,
      ].join(' - ')
    );
  }
}

export function parseServerWorkExperience(we) {
  const startMoment = moment(we.start_date);
  const endMoment = we.end_date ? moment(we.end_date) : null;

  return new WorkExperience({
    id: we.id,
    companyName: we.company_name,
    location: we.location,
    jobTitle: we.job_title,
    startMonth: startMoment.month() + 1,
    startYear: startMoment.year(),
    endMonth: endMoment ? endMoment.month() + 1 : '',
    endYear: endMoment ? endMoment.year() : '',
    currentlyThere: endMoment == null,
    duties: we.duties || '',
    workExperienceSpecialties: List(we.work_experience_specialties),
    _destroy: false,
  });
}

const WorkExperienceValidationResult = Record({
  errors: Map(),
  validWorkExperiences: List(),
});

export const validateWorkExperiences = function (workExperiences) {
  let errors = Map();
  let validWorkExperiences = List();
  workExperiences.forEach((we, i) => {
    if (!we.isEmpty()) {
      let curErrors = validateRequired(we, 'companyName', 'jobTitle');

      if (we.getStartDate() === '') {
        curErrors = curErrors.set('startDate', 'Required');
      }

      if (we.getEndDate() === '' && !we.currentlyThere) {
        curErrors = curErrors.set('endDate', 'Required');
      }

      if (we.location && we.location.indexOf(',') === -1) {
        curErrors = curErrors.set(
          'location',
          'Please separate city and state/country with a comma. For example: "Chicago, IL"'
        );
      }

      if (curErrors.size === 0) {
        validWorkExperiences = validWorkExperiences.push(we);
      } else {
        errors = errors.set(i, curErrors);
      }
    }
  });

  return WorkExperienceValidationResult({
    validWorkExperiences,
    errors,
  });
};

export function WorkExperienceSpecialtySkillEntry({
  genOnChange,
  nurseSpecialtyOptions,
  nurseSpecialtySkills,
  selectedSkillIds,
  specialtyId,
}) {
  const allSkillsForSpecialty = nurseSpecialtySkills.get(specialtyId);
  if (!allSkillsForSpecialty) {
    return null;
  }

  const specialtyName = nurseSpecialtyOptions.get(specialtyId).label;

  return (
    <>
      <Row>
        <Col xs={12}>
          <ControlLabel className="specialty-skill-label">
            {specialtyName} Functions/Skills Performed
          </ControlLabel>
        </Col>
      </Row>
      {allSkillsForSpecialty.map((skill) => {
        const selected = selectedSkillIds.some((ssi) => ssi === skill.id);

        return (
          <Row key={`specialty-${specialtyId}-skill-${skill.id}`}>
            <Col xs={12}>
              <Checkbox
                className={
                  selected
                    ? 'specialty-skill-selected'
                    : 'specialty-skill-deselected'
                }
                checked={selected}
                onChange={genOnChange(skill.id)}
              >
                {skill.description}
              </Checkbox>
            </Col>
          </Row>
        );
      })}
    </>
  );
}

WorkExperienceSpecialtySkillEntry.propTypes = {
  genOnChange: PropTypes.func.isRequired,
  nurseSpecialtyOptions: PropTypes.object.isRequired,
  nurseSpecialtySkills: PropTypes.object.isRequired,
  selectedSkillIds: PropTypes.array.isRequired,
  specialtyId: PropTypes.number.isRequired,
};

export const newWorkExperienceSpecialty = (specialtyId) => ({
  nurse_specialty_description_id: specialtyId,
  work_experience_specialty_skills: [],
});

export const newWorkExperienceSpecialtySkill = (skillId) => ({
  nurse_specialty_skill_description_id: skillId,
});

class WorkExperienceControl extends React.Component {
  constructor(props) {
    super(props);

    this.getId = this.getId.bind(this);
    this.getInputName = this.getInputName.bind(this);
    this.handleAddWorkExperience = this.handleAddWorkExperience.bind(this);
    this.setField = this.setField.bind(this);

    this.includeSpecialties = props.nurseSpecialtyOptions.size > 0;
  }

  componentDidMount() {
    if (!this.includeSpecialties) {
      return;
    }

    this.props.workExperiences.forEach((we, i) => {
      this.props.onChange(
        this.props.workExperiences.set(i, this.addDefaultSpecialties(we))
      );
    });
  }

  handleAddWorkExperience(e, scrollToBottom = false) {
    e.preventDefault();

    const we = this.addDefaultSpecialties(new WorkExperience());
    this.props.onChange(this.props.workExperiences.push(we));
    if (scrollToBottom) {
      window.location.hash = `work-experience-entry-${
        this.props.workExperiences.size + 1
      }`;
    }
  }

  setField(field, i, value) {
    const we = this.props.workExperiences.get(i).set(field, value);
    this.props.onChange(this.props.workExperiences.set(i, we));
  }

  setSkills(i, specialtyId, skills) {
    const we = this.props.workExperiences.get(i);
    const specialties = we.workExperienceSpecialties;
    const specialtyIdx = specialties.findIndex(
      (s) => s.nurse_specialty_description_id === specialtyId
    );
    const specialtyObj = specialties.get(specialtyIdx);
    specialtyObj.work_experience_specialty_skills = skills;
    we.set(
      'workExperienceSpecialties',
      specialties.set(specialtyIdx, specialtyObj)
    );
    this.props.onChange(this.props.workExperiences.set(i, we));
  }

  getInputName(attr) {
    return `${this.props.name}[][${attr}]`;
  }

  getId(attr, i) {
    const { instanceId } = this.props;
    let id = null;
    if (instanceId) {
      id = `${instanceId}-${i}-${attr}`;
    }
    return id;
  }

  addDefaultSpecialties(we) {
    if (we.isEmpty()) {
      const { talentSpecialtyIds: specialtyIds } = this.props;
      const specialtyCount = specialtyIds ? specialtyIds.length : 0;

      /* eslint-disable yoda */
      if (1 <= specialtyCount && specialtyCount <= 5) {
        return we.set(
          'workExperienceSpecialties',
          List(specialtyIds.map(newWorkExperienceSpecialty))
        );
      }
      /* eslint-enable yoda */
    }

    return we;
  }

  genHandleFieldChange(field, i) {
    return (e) => this.setField(field, i, e.target.value);
  }

  genHandleDateChange(field, i) {
    return (option) => this.setField(field, i, option && option.value);
  }

  genHandleCheckboxChange(field, i) {
    return (e) => this.setField(field, i, e.target.checked);
  }

  genHandleSpecialtiesChange(i) {
    const we = this.props.workExperiences.get(i);

    return (options) => {
      const selectedSpecialtyIds = options.map((o) => o.value);
      const changedSpecialties = applyItemsToRailsNestedAttributes(
        selectedSpecialtyIds,
        we.workExperienceSpecialties,
        'nurse_specialty_description_id',
        newWorkExperienceSpecialty
      );
      this.setField('workExperienceSpecialties', i, List(changedSpecialties));
    };
  }

  genHandleSpecialtySkillChange(i, specialtyId) {
    const we = this.props.workExperiences.get(i);

    return (skillId) => (event) => {
      const specialtyObj = we.workExperienceSpecialties.find(
        (wes) => wes.nurse_specialty_description_id === specialtyId
      );
      let selectedSkillIds = specialtyObj.work_experience_specialty_skills
        .filter((wess) => wess._destroy !== true)
        .map((wess) => wess.nurse_specialty_skill_description_id);

      if (event.target.checked) {
        selectedSkillIds.push(skillId);
      } else {
        selectedSkillIds = selectedSkillIds.filter((sk) => sk !== skillId);
      }

      const changedSkills = applyItemsToRailsNestedAttributes(
        selectedSkillIds,
        specialtyObj.work_experience_specialty_skills,
        'nurse_specialty_skill_description_id',
        newWorkExperienceSpecialtySkill
      );

      this.setSkills(i, specialtyId, changedSkills);
    };
  }

  genRemoveWorkExperience(i) {
    return () => {
      const { workExperiences, onChange } = this.props;
      const workExperience = workExperiences.get(i);
      if (workExperience.id == null) {
        onChange(workExperiences.delete(i));
      } else {
        this.setField('_destroy', i, true);
      }
    };
  }

  render() {
    const { nurseSpecialtyOptions, nurseSpecialtySkills, workExperiences } =
      this.props;

    const workExperienceForms = workExperiences.map((we, i) => {
      let removeButton = null;
      if (i > 0) {
        removeButton = (
          <LucideIcon
            name="X"
            className="clickable"
            strokeWidth={3}
            onClick={() => {
              trackClick('talent.work_experiences.remove');
              this.genRemoveWorkExperience(i);
            }}
          />
        );
      }

      let hiddenInputs = null;
      if (we.companyName && we.jobTitle) {
        const inputPairs = [
          ['id', we.get('id')],
          ['company_name', we.get('companyName')],
          ['location', we.get('location')],
          ['job_title', we.get('jobTitle')],
          ['start_date', we.getStartDate()],
          ['end_date', we.getEndDate()],
          ['duties', we.get('duties')],
          ['_destroy', we.get('_destroy')],
        ];

        /* eslint-disable react/no-array-index-key */
        hiddenInputs = inputPairs.map((inputPair) => (
          <input
            key={`hidden-${inputPair[0]}-${i}`}
            name={this.getInputName(inputPair[0])}
            type="hidden"
            value={inputPair[1] || ''}
          />
        ));

        we.workExperienceSpecialties.forEach(
          (workExperienceSpecialty, wesIdx) => {
            const specialtyId =
              workExperienceSpecialty.nurse_specialty_description_id;
            const keyPrefix = `hidden-wes-${specialtyId}`;
            /* eslint-disable prefer-template */
            const namePrefix =
              this.getInputName('work_experience_specialties_attributes') +
              `[${wesIdx}]`;
            /* eslint-enable prefer-template */

            const wesInputs = [
              'id',
              '_destroy',
              'nurse_specialty_description_id',
            ];
            wesInputs.forEach((inputName) => {
              hiddenInputs.push(
                <input
                  key={`${keyPrefix}-${inputName}`}
                  name={`${namePrefix}[${inputName}]`}
                  type="hidden"
                  value={workExperienceSpecialty[inputName] || ''}
                />
              );
            });

            workExperienceSpecialty.work_experience_specialty_skills.forEach(
              (workExperienceSpecialtySkill, wessIdx) => {
                const skillId =
                  workExperienceSpecialtySkill.nurse_specialty_skill_description_id;
                const skillKeyPrefix = `${keyPrefix}-wess-${skillId}`;
                const skillNamePrefix = `${namePrefix}[work_experience_specialty_skills_attributes]\
[${wessIdx}]`;

                const wessInputs = [
                  'id',
                  '_destroy',
                  'nurse_specialty_skill_description_id',
                ];

                wessInputs.forEach((inputName) => {
                  hiddenInputs.push(
                    <input
                      key={`${skillKeyPrefix}-${inputName}`}
                      name={`${skillNamePrefix}[${inputName}]`}
                      type="hidden"
                      value={workExperienceSpecialtySkill[inputName] || ''}
                    />
                  );
                });
              }
            );
          }
        );
        /* eslint-enable react/no-array-index-key */
      }

      if (we.get('_destroy')) {
        return <div className="destroyed-work-experience">{hiddenInputs}</div>;
      }

      const errors = this.props.errors
        ? this.props.errors.get(i, Map())
        : Map();

      const dutiesPrompt = this.includeSpecialties
        ? 'Any more details about this position?'
        : 'What did you do?';
      const dutiesPlaceholder = this.includeSpecialties
        ? 'e.g., briefly describe duties & additional skills performed in role.'
        : 'i.e., brief description of duties or subspecialties of this role.';

      return (
        <Grid
          className="work-experience top-buffer"
          fluid
          // eslint-disable-next-line react/no-array-index-key
          key={i}
        >
          <div className="anchor" id={`work-experience-entry-${i + 1}`} />
          <Row>
            <Col xs={12}>
              <FormGroup
                controlId={this.getId('company-name', i)}
                validationState={errors.get('companyName') ? 'error' : null}
              >
                <Grid fluid>
                  <Row className="top-buffer">
                    <Col className="nopadding" xs={8}>
                      <ControlLabel>Company Name</ControlLabel>
                    </Col>
                    <Col className="align-right nopadding" xs={4}>
                      {removeButton}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormControl
                        onChange={this.genHandleFieldChange('companyName', i)}
                        type="text"
                        value={we.companyName}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="help-block">
                      {errors.get('companyName')}
                    </Col>
                  </Row>
                </Grid>
              </FormGroup>
            </Col>

            <Col xs={12}>
              <FormGroup
                controlId={this.getId('location', i)}
                validationState={errors.get('location') ? 'error' : null}
              >
                <Grid fluid>
                  <Row className="top-buffer">
                    <Col className="nopadding" xs={12}>
                      <ControlLabel>Location</ControlLabel>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormControl
                        onChange={this.genHandleFieldChange('location', i)}
                        type="text"
                        value={we.location}
                        placeholder="City, State"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="help-block">{errors.get('location')}</Col>
                  </Row>
                </Grid>
              </FormGroup>
            </Col>

            <Col xs={12}>
              <FormGroup
                controlId={this.getId('job-title', i)}
                validationState={errors.get('jobTitle') ? 'error' : null}
              >
                <Grid fluid>
                  <Row className="top-buffer">
                    <Col className="nopadding" xs={12}>
                      <ControlLabel htmlFor={this.getId('job-title', i)}>
                        Title
                      </ControlLabel>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormControl
                        id={this.getId('job-title', i)}
                        onChange={this.genHandleFieldChange('jobTitle', i)}
                        type="text"
                        value={we.jobTitle}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="help-block">{errors.get('jobTitle')}</Col>
                  </Row>
                </Grid>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <FormGroup
                id={this.getId('start-date', i)}
                validationState={errors.get('startDate') ? 'error' : null}
              >
                <Grid fluid>
                  <Row className="top-buffer">
                    <Col>
                      <ControlLabel>Start</ControlLabel>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <MonthSelector
                        id={this.getId('start-date', i)}
                        monthValue={we.startMonth}
                        onMonthChange={this.genHandleDateChange(
                          'startMonth',
                          i
                        )}
                        onYearChange={this.genHandleDateChange('startYear', i)}
                        yearValue={we.startYear}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="help-block">{errors.get('startDate')}</Col>
                  </Row>
                </Grid>
              </FormGroup>
            </Col>

            <Col xs={12}>
              <FormGroup
                id={this.getId('end-date', i)}
                validationState={errors.get('endDate') ? 'error' : null}
              >
                <Grid fluid>
                  <Row className="top-buffer">
                    <Col>
                      <ControlLabel htmlFor={this.getId('end-date', i)}>
                        End
                      </ControlLabel>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <MonthSelector
                        disabled={we.currentlyThere}
                        id={this.getId('end-date', i)}
                        monthValue={we.currentlyThere ? '' : we.endMonth}
                        onMonthChange={this.genHandleDateChange('endMonth', i)}
                        onYearChange={this.genHandleDateChange('endYear', i)}
                        yearValue={we.currentlyThere ? '' : we.endYear}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Checkbox
                        checked={we.currentlyThere}
                        onChange={this.genHandleCheckboxChange(
                          'currentlyThere',
                          i
                        )}
                      >
                        I currently work here
                      </Checkbox>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="help-block">{errors.get('endDate')}</Col>
                  </Row>
                </Grid>
              </FormGroup>
            </Col>
          </Row>

          {this.includeSpecialties && (
            <Row id={this.getId('specialty-skills', i)}>
              <Col xs={12}>
                <FormGroup
                  controlId={this.getId('specialties', i)}
                  validationState={errors.get('specialties') ? 'error' : null}
                >
                  <Grid fluid>
                    <Row className="top-buffer">
                      <Col className="nopadding" xs={12}>
                        <ControlLabel>
                          Specialties Practiced in this Role
                        </ControlLabel>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Select
                          clearable={false}
                          multi
                          instanceId={this.getId('specialties', i)}
                          onChange={this.genHandleSpecialtiesChange(i)}
                          options={nurseSpecialtyOptions.toArray()}
                          value={we
                            .getKeptSpecialties()
                            .map((wes) => wes.nurse_specialty_description_id)
                            .toArray()}
                          placeholder="Select specialties"
                        />
                      </Col>
                    </Row>
                  </Grid>
                </FormGroup>
                {we.getKeptSpecialties().map((wes) => {
                  const specialtyId = wes.nurse_specialty_description_id;
                  const selectedSkillIds = wes.work_experience_specialty_skills
                    .filter((wess) => wess._destroy !== true)
                    .map((wess) => wess.nurse_specialty_skill_description_id);
                  return (
                    <WorkExperienceSpecialtySkillEntry
                      key={`specialty-${specialtyId}-skills`}
                      genOnChange={this.genHandleSpecialtySkillChange(
                        i,
                        specialtyId
                      )}
                      nurseSpecialtyOptions={nurseSpecialtyOptions}
                      nurseSpecialtySkills={nurseSpecialtySkills}
                      selectedSkillIds={selectedSkillIds}
                      specialtyId={specialtyId}
                    />
                  );
                })}
              </Col>
            </Row>
          )}

          <Row className="top-buffer">
            <Col xs={12}>
              <ControlLabel htmlFor={this.getId('duties', i)}>
                {dutiesPrompt}
              </ControlLabel>
            </Col>
          </Row>

          <Row className="bottom-buffer">
            <Col xs={12}>
              <FormControl
                componentClass="textarea"
                id={this.getId('duties', i)}
                onChange={this.genHandleFieldChange('duties', i)}
                rows={5}
                value={we.duties}
                placeholder={dutiesPlaceholder}
              />
            </Col>
          </Row>
          {hiddenInputs}
        </Grid>
      );
    });

    return (
      <Grid
        className="nopadding work-experience-control"
        fluid
        id={this.props.instanceId}
      >
        {!workExperiences.isEmpty() && (
          <>
            <div className="anchor" id="work-experience-section-addition" />
            <Row>
              <Col className="text-center">
                <IButton
                  icon="add"
                  text="ADD ANOTHER POSITION"
                  bsSize="small"
                  className="add-work-experience-link add-work-experience-link--top"
                  onClick={(e) => {
                    trackClick('talent.work_experiences.add_top');
                    this.handleAddWorkExperience(e, true);
                  }}
                />
              </Col>
            </Row>
          </>
        )}

        {workExperienceForms}

        {workExperiences.isEmpty() ? (
          <Row className="top-buffer">
            <Col xs={10}>
              <a
                className="clickable"
                href="#"
                onClick={(e) => {
                  trackClick('talent.work_experiences.add_bottom');
                  this.handleAddWorkExperience(e);
                }}
              >
                Add
              </a>
            </Col>
          </Row>
        ) : (
          <>
            <div className="anchor" id="work-experience-section-addition" />
            <Row>
              <Col className="text-center">
                <IButton
                  icon="add"
                  text="ADD ANOTHER POSITION"
                  bsSize="small"
                  className="add-work-experience-link"
                  onClick={(e) => {
                    trackClick('talent.work_experiences.add_bottom');
                    this.handleAddWorkExperience(e);
                  }}
                />
              </Col>
            </Row>
          </>
        )}
      </Grid>
    );
  }
}

WorkExperienceControl.propTypes = {
  errors: PropTypes.instanceOf(Map),
  instanceId: PropTypes.string,
  name: PropTypes.string,
  nurseSpecialtyOptions: PropTypes.object,
  nurseSpecialtySkills: PropTypes.object,
  onChange: PropTypes.func,
  talentSpecialtyIds: PropTypes.array,
  workExperiences: PropTypes.instanceOf(List).isRequired,
};

WorkExperienceControl.defaultProps = {
  errors: null,
  instanceId: null,
  name: null,
  nurseSpecialtyOptions: Map(),
  nurseSpecialtySkills: Map(),
  onChange: () => {},
  talentSpecialtyIds: [],
};

export default WorkExperienceControl;
