import React from 'react';
import { Stack } from 'shared/components/ihcl/patterns';
import { styled, withStyle } from 'shared/components/ihcl/styled';
import { Button, StyledBaseButton } from 'shared/components/ihcl/button';

import { getEndDate } from './helpers';

const TimeslotButton = withStyle(StyledBaseButton, ({ $theme }) => ({
  paddingRight: $theme.sizing.unit16,
  paddingLeft: $theme.sizing.unit16,
  ...$theme.typography.LabelSmall,
}));

const ButtonTimeWrapper = styled('div', () => ({
  width: '103.6px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flex: '1 1 0',
}));

const TimeWrapper = styled('div', ({ $theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: $theme.sizing.unit8,
  flexShrink: 0,
  width: '103.6px',
  paddingRight: $theme.sizing.unit16,
  paddingBottom: $theme.sizing.unit10,
  paddingLeft: $theme.sizing.unit16,
  paddingTop: $theme.sizing.unit10,
}));

const AppointmentTimeInput = ({
  time,
  isSelected,
  onSelect,
}: {
  time: Date;
  isSelected: boolean;
  onSelect: Function;
}) => {
  const format: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };
  const endTime = getEndDate(time);

  return (
    <ButtonTimeWrapper>
      <Button
        data-testid={`time-${time.getTime()}`}
        fullWidth
        onClick={() => onSelect(time)}
        kind={isSelected ? 'primary' : 'tertiary'}
        color="neutral"
        size="compact"
        overrides={{
          BaseButton: {
            component: TimeslotButton,
          },
        }}
      >
        <TimeWrapper>
          <Stack $alignItems="flex-start">
            <div>{time.toLocaleString('en-US', format)} -</div>
            <div>{endTime.toLocaleString('en-US', format)}</div>
          </Stack>
        </TimeWrapper>
      </Button>
    </ButtonTimeWrapper>
  );
};

const TimeSlotWrapper = styled('div', ({ $theme }) => ({
  display: 'flex',
  width: '100%',
  alignItems: 'flex-start',
  alignContent: 'flex-start',
  flexWrap: 'wrap',
  gap: $theme.sizing.unit8,
  marginBottom: $theme.sizing.unit24,
}));

const FixedNumberStacks = withStyle(Stack, () => ({
  width: '100%',
}));

export const AppointmentTimes = ({
  times,
  date,
  timeOfDay,
  time,
  setTime,
}: {
  times: Array<Date>;
  date: Date;
  timeOfDay: string;
  time: Date;
  setTime: Function;
}) => {
  const [availTimes, setAvailTimes] = React.useState<Array<Date>>([]);

  React.useEffect(() => {
    const filteredTimes: Array<Date> = times.filter((t) => {
      if (date?.getDate() !== t.getDate()) {
        return false;
      }
      if (timeOfDay === 'Morning') {
        return t.getHours() < 12;
      }
      if (timeOfDay === 'Afternoon') {
        const hours = t.getHours();
        return hours >= 12 && hours < 17;
      }
      if (timeOfDay === 'Evening') {
        return t.getHours() >= 17;
      }

      return true;
    });
    setAvailTimes(filteredTimes);
    setTime(filteredTimes.length > 0 ? filteredTimes[0] : null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [times, date, timeOfDay]);

  const rows = Math.ceil(availTimes.length / 3);

  return (
    <TimeSlotWrapper>
      {Array.from(Array(rows).keys()).map((row) => (
        <FixedNumberStacks
          key={`times-${availTimes[row * 3].getTime()}`}
          $flexDirection="row"
          $gapSizing="unit8"
        >
          <AppointmentTimeInput
            time={availTimes[row * 3]}
            isSelected={availTimes[row * 3].getTime() === time?.getTime()}
            onSelect={setTime}
          />
          {row * 3 + 1 < availTimes.length ? (
            <AppointmentTimeInput
              time={availTimes[row * 3 + 1]}
              isSelected={availTimes[row * 3 + 1].getTime() === time?.getTime()}
              onSelect={setTime}
            />
          ) : (
            <ButtonTimeWrapper />
          )}
          {row * 3 + 2 < availTimes.length ? (
            <AppointmentTimeInput
              time={availTimes[row * 3 + 2]}
              isSelected={availTimes[row * 3 + 2].getTime() === time?.getTime()}
              onSelect={setTime}
            />
          ) : (
            <ButtonTimeWrapper />
          )}
        </FixedNumberStacks>
      ))}
    </TimeSlotWrapper>
  );
};
