import React from 'react';
import PropTypes from 'prop-types';

import { styled, useStyletron, withStyle } from 'shared/components/ihcl/styled';
import {
  LocationPinThinIcon,
  MessageBubbleIcon,
  TruckIcon,
  Wallet,
} from 'shared/components/ihcl/icon';

const JobCardWrapper = styled(
  'div',
  ({
    $animationDuration,
    $endScale,
    $endRotation,
    $endRightOffset,
    $endTop,
  }) => ({
    backgroundColor: 'white',
    width: '300px',
    fontSize: '14px',
    textAlign: 'left',
    boxShadow: '0px 4px 12px 0px #14142A52',
    position: 'absolute',
    animationDuration: $animationDuration,
    scale: 0,
    right: '-300px',
    animationFillMode: 'forwards',
    animationName: {
      from: {
        right: '-300px',
        scale: 0.3,
        top: 0,
        transform: 'rotate(-10deg)',
      },
      to: {
        right: $endRightOffset,
        scale: $endScale,
        top: $endTop,
        transform: `rotate(${$endRotation})`,
      },
    },
  })
);

const JobCardContents = styled('div', {
  paddingTop: '16px',
  paddingRight: '16px',
  paddingBottom: '16px',
  paddingLeft: '16px',
});

const JobDetailsList = styled('ul', {
  listStyleType: 'none',
  padding: 0,
  margin: 0,
});

const JobDetail = styled('li', {
  display: 'flex',
  alignItems: 'center',
  columnGap: '8px',
});

const JobDetailLabel = styled('span', ({ $theme }) => ({
  color: '#000000',
  [$theme.mediaQuery.small]: {
    fontSize: '12px',
  },
}));

const JobTitle = styled('h3', {
  marginTop: '8px',
  fontSize: '18px',
  lineHeight: '24px',
});

const HospitalImage = styled('img', {
  height: '56px',
  width: '56px',
  marginRight: '16px',
});

const HospitalName = styled('span', ({ $theme }) => ({
  color: $theme.colors.primary,
}));

const ExpandLabel = styled('span', ({ $theme }) => ({
  color: $theme.colors.grayLabel,
  textDecoration: 'underline',
}));

const ButtonWrapper = styled('div', {
  width: '100%',
  display: 'flex',
});

const BaseButton = styled('div', {
  width: '50%',
  textAlign: 'center',
  paddingTop: '8px',
  paddingBottom: '8px',
  fontSize: '16px',
});

const DeclineButton = withStyle(BaseButton, ({ $theme }) => ({
  backgroundColor: $theme.colors.primary00,
  color: $theme.colors.primary,
}));

const ScheduleButton = withStyle(BaseButton, ({ $theme }) => ({
  backgroundColor: $theme.colors.accent,
  color: $theme.colors.white,
}));

// eslint-disable-next-line react/require-default-props
const JobCard = ({
  // eslint-disable-next-line react/require-default-props
  animationDuration,
  // eslint-disable-next-line react/require-default-props
  bonus,
  // eslint-disable-next-line react/require-default-props
  hasRelocationAssistance,
  hospitalLogo,
  hospitalName,
  jobTitle,
  // eslint-disable-next-line react/require-default-props
  location,
  // eslint-disable-next-line react/require-default-props
  message,
  // eslint-disable-next-line react/require-default-props
  recruiterName,
  rightOffset,
  rotation,
  scale,
  top,
}) => {
  const [, theme] = useStyletron();
  return (
    <JobCardWrapper
      $animationDuration={animationDuration}
      $endScale={scale}
      $endRotation={rotation}
      $endRightOffset={rightOffset}
      $endTop={top}
    >
      <JobCardContents>
        <HospitalImage src={hospitalLogo} height="56px" width="56px" />
        <HospitalName>{hospitalName}</HospitalName>
        <JobTitle>Opportunity in {jobTitle}</JobTitle>
        <JobDetailsList>
          {bonus && (
            <JobDetail>
              <Wallet color={theme.colors.positive} />
              <span>
                <JobDetailLabel>Bonus:</JobDetailLabel> {bonus}
              </span>
            </JobDetail>
          )}
          {hasRelocationAssistance && (
            <JobDetail>
              <TruckIcon color={theme.colors.positive} />
              <JobDetailLabel>Moving support</JobDetailLabel>
            </JobDetail>
          )}
          {location && (
            <JobDetail>
              <LocationPinThinIcon width="16" height="16" />
              <span>
                <JobDetailLabel>Location:</JobDetailLabel> {location}
              </span>
            </JobDetail>
          )}
          {message && (
            <JobDetail>
              <MessageBubbleIcon />
              <span>
                {recruiterName && (
                  <JobDetailLabel>From {recruiterName}: </JobDetailLabel>
                )}
                {message}
                <ExpandLabel>Expand</ExpandLabel>
              </span>
            </JobDetail>
          )}
        </JobDetailsList>
      </JobCardContents>
      <ButtonWrapper>
        <DeclineButton>Decline</DeclineButton>
        <ScheduleButton>Schedule Interview</ScheduleButton>
      </ButtonWrapper>
    </JobCardWrapper>
  );
};

JobCard.propTypes = {
  animationDuration: PropTypes.string,
  bonus: PropTypes.string,
  hasRelocationAssistance: PropTypes.bool,
  hospitalLogo: PropTypes.string.isRequired,
  hospitalName: PropTypes.string.isRequired,
  jobTitle: PropTypes.string.isRequired,
  location: PropTypes.string,
  message: PropTypes.string,
  recruiterName: PropTypes.string,
  rightOffset: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  rotation: PropTypes.string.isRequired,
  scale: PropTypes.number.isRequired,
  top: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
JobCard.defaultProps = {
  animationDuration: '2s',
  bonus: null,
  hasRelocationAssistance: false,
  location: null,
  message: null,
  recruiterName: null,
};

export default JobCard;
