import React from 'react';
import PropTypes from 'prop-types';

const OnboardingVariantContext = React.createContext(null);

export const OnboardingVariantProvider = ({ config, children }) => (
  <OnboardingVariantContext.Provider value={config}>
    {children}
  </OnboardingVariantContext.Provider>
);

OnboardingVariantProvider.propTypes = {
  children: PropTypes.node.isRequired,
  config: PropTypes.object.isRequired,
};

export const useOnboardingVariantContext = () =>
  React.useContext(OnboardingVariantContext);
