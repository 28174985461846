import { handleActions } from 'redux-actions';
import lodash from 'lodash';

import { updateTalentProfileComplete } from 'shared/actions/talentProfileActions';

import { cacheTalentProfileChanges } from '../actions/talentProfileActions';

const talentProfileReducer = handleActions(
  {
    [updateTalentProfileComplete]: {
      next(state, action) {
        return action.payload;
      },
      throw(state) {
        return state;
      },
    },

    [cacheTalentProfileChanges]: (state, action) => {
      const { payload } = action;
      let nextState = {};
      const profileChanges = lodash.pick(payload, [
        'first_name',
        'last_name',
        'current_city',
        'current_state',
        'current_zip_code',
        'avatar',
        'education_level',
        'years_experience',
        'certifications',
        'job_search_status',
        'start_date',
        'why_change',
        'phone',
        'resume',
        'comments',
        'full_time',
        'part_time',
        'per_diem',
        'night_shift',
        'day_shift',
        'mid_shift',
        'shifts_not_sure',
        'work_personality',
        'start_date_option',
        'current_onboard_step',
        'current_reactivation_step',
        'acute_care_experience',
        'clinical_care_experience',
        'care_settings',
      ]);

      if (payload.user_attributes && payload.user_attributes.email) {
        profileChanges.email = payload.user_attributes.email;
      }

      if (payload.first_name && payload.last_name) {
        profileChanges.full_name = [payload.first_name, payload.last_name].join(
          ' '
        );
      }

      if (payload.state_licenses_attributes) {
        profileChanges.state_licenses = payload.state_licenses_attributes;
      }

      if (payload.nurse_specialties_attributes) {
        profileChanges.nurse_specialties = payload.nurse_specialties_attributes;
      }

      if (payload.other_locations_attributes) {
        profileChanges.other_locations = payload.other_locations_attributes;
      }

      if (payload.work_experiences_attributes) {
        profileChanges.work_experiences = payload.work_experiences_attributes;
      }

      nextState = { ...state, ...profileChanges };

      return nextState;
    },
  },
  {}
);

export default talentProfileReducer;
