export const getNurseSpecialtyDescriptions = (state) =>
  state.nurse_specialty_descriptions;

export const getServerErrors = (state) => state.serverErrors;

export const getStateOptions = (state) => state.stateOptions;

export const getTalentProfile = (state) => state.talent_profile;

export const getTalentProfileCache = (state) => state.talentProfileCache;

export const getUiState = (state) => state.uiState;
