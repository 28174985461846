import { createReduxEnhancer } from '@sentry/react';

export default function () {
  return [
    createReduxEnhancer({
      attachReduxState: false,
      actionTransformer: (action) => ({
        type: action.type,
        // drop payload to avoid sending data updates in every breadcrumb
      }),
      // our redux state is large, never send it to Sentry
      stateTransformer: () => null,
    }),
  ];
}
