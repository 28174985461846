import React from 'react';
import { Slide } from 'react-slideshow-image';
import { styled } from 'shared/components/ihcl/styled';
import PropTypes from 'prop-types';

export const SlideFrame = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export const Title = styled('header', ({ $theme }) => ({
  ...$theme.typography.HeadingLarge,
  fontWeight: 800,
  marginBottom: '25px',
  textAlign: 'center',
}));

export const Image = styled('img', {
  maxHeight: '250px',
  marginBottom: '25px',
});

export const Text = styled('p', {
  fontSize: '18px',
  textAlign: 'center',
});

// eslint-disable-next-line react/require-default-props
export const Slideshow = ({
  // eslint-disable-next-line react/require-default-props
  onChange,
  slides,
  // eslint-disable-next-line react/require-default-props
  style,
  // eslint-disable-next-line react/require-default-props
  slideImageStyle,
  // eslint-disable-next-line react/require-default-props
  slideStyle,
}) => (
  <div style={style}>
    <Slide easing="ease" indicators autoplay={false} onChange={onChange}>
      {slides.map((slide, i) => (
        <div className="each-slide" key={slide.title || i} style={slideStyle}>
          <SlideFrame>
            <Title>{slide.title}</Title>
            <Image
              src={slide.image}
              alt={slide.imageAlt}
              style={slideImageStyle}
            />
            <Text>{slide.text}</Text>
          </SlideFrame>
        </div>
      ))}
    </Slide>
  </div>
);
Slideshow.propTypes = {
  onChange: PropTypes.func,
  slideImageStyle: PropTypes.object,
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.node,
      image: PropTypes.string,
      imageAlt: PropTypes.string,
      text: PropTypes.node,
    })
  ).isRequired,
  slideStyle: PropTypes.object,
  style: PropTypes.object,
};
Slideshow.defaultProps = {
  onChange: () => {},
  slideImageStyle: {},
  slideStyle: {},
  style: {},
};
