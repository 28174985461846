import PropTypes from 'prop-types';
import React from 'react';
import nurseWithWrench from 'images/nurse-with-wrench.svg';

import Sentry from 'shared/helpers/sentry';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { eventId: null };
    this.handleErrorReport = this.handleErrorReport.bind(this);
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  handleErrorReport() {
    Sentry.showReportDialog({ eventId: this.state.eventId });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div onClick={this.handleErrorReport} className="error-container">
          <img
            alt="Nurse with Wrench"
            src={nurseWithWrench}
            className="card-header-image"
          />
          <p className="error-message">
            <b>Oh no, we’re so sorry!</b>
            <span> </span>
            <span>
              Something has gone wrong. We’ve already been notified, but you can
              also click <a className="faux-link">here</a> to provide us more
              details to help us resolve this issue.
            </span>
          </p>
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
};

ErrorBoundary.defaultProps = {
  children: null,
};

export default ErrorBoundary;
