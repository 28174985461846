import { List } from 'immutable';

import constants from './constants.json';

export const educationLevels = [
  { value: 'bs_bsn', label: 'BS/BSN' },
  { value: 'msn', label: 'MSN' },
  { value: 'adn_asn', label: 'ADN/ASN' },
  { value: 'lpn', label: 'LPN' },
  { value: 'dnp', label: 'DNP' },
  { value: 'phd', label: 'PhD' },
  { value: 'rn_diploma', label: 'RN Diploma' },
  { value: 'student', label: 'Current Nursing Student' },
];

export const educationLevelsExcludingStudent = educationLevels.filter(
  (educationLevel) => educationLevel.value !== 'student'
);

export const jobSearchOptions = [
  { value: 'just_started', label: 'Just started looking' },
  { value: 'in_process', label: 'In the interview process' },
  { value: 'not_looking', label: 'Not currently looking' },
];

export const recentGradOptions = [
  { label: 'Less than 1 year', value: true },
  { label: 'More than 1 year', value: false },
];

export const recentGradWithStudentOptions = [
  { label: "I'm a current RN student", value: 'student' },
  {
    label: "I graduated but don't have my RN license yet",
    value: 'recent_grad_no_license',
  },
  { label: 'Less than 1 year', value: true },
  { label: 'More than 1 year', value: false },
];

export const startDateOptions = List([
  { value: 'immediately', label: 'Immediately' },
  { value: 'few_weeks', label: 'Few weeks' },
  { value: 'few_months', label: 'Few months' },
  { value: 'specific_date', label: 'I have a specific date' },
]);

export const startDateOptionsNotLooking = List([
  { value: 'not_sure', label: 'Not Sure' },
  { value: 'few_weeks', label: 'Few Weeks' },
  { value: 'few_months', label: 'Few Months' },
  { value: 'specific_date', label: 'I have a specific date' },
]);

export const yearOptions = Array.from({ length: 60 }, (v, k) => k + 1).map(
  (i) => ({ value: i, label: i })
);

export const yesNoOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const noYesOptions = [...yesNoOptions].reverse();

export const shiftOptions = new List(
  constants.SHIFT_OPTION_MAP.map((a) => ({ value: a[0], label: a[1] }))
);

export const referenceOccupationOptions =
  constants.TALENT_REFERENCE_OCCUPATION_MAP.map((a) => ({
    value: a[0],
    label: a[1],
  }));

export const referenceRelationshipOptions =
  constants.TALENT_REFERENCE_RELATIONSHIP_MAP.map((a) => ({
    value: a[0],
    label: a[1],
  }));

export const licenseTypeOptions = constants.NURSE_LICENSE_TYPE_MAP.map((a) => ({
  value: a[0],
  label: a[1],
}));

export const qualificationOptions =
  constants.NURSE_PRACTITIONER_QUALIFICATION_MAP.map((a) => ({
    value: a[0],
    label: a[1],
  }));

export const degreeTypeOptions = constants.TALENT_EDUCATION_DEGREE_TYPE_MAP.map(
  ([value, label]) => ({
    value,
    label,
  })
);

export const employmentTypeOptions = [
  { label: 'Full-time', id: 'full_time' },
  { label: 'Part-time', id: 'part_time' },
  { label: 'Per-diem', id: 'per_diem' },
];
