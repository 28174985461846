import Cookie from 'js-cookie';
import qs from 'qs';
import stringhash from 'string-hash';

export const DEFAULT_GROUP = 'onboarding';

export const onboardingVariantsMap = window?.IH?.abSplits?.onboarding || {
  xena: 100,
  yuki: 0,
};

export const activeOnboardingVariants = Object.keys(onboardingVariantsMap);

export const onboardingLiteVariantsMap = window?.IH?.abSplits
  ?.onboardingLite || { gamora: 100 };
export const activeOnboardingLiteVariants = Object.keys(
  onboardingLiteVariantsMap
);

export const variantGroupMap = {
  onboarding: {
    activeVariants: activeOnboardingVariants,
    pathRoot: '/talent_onboard/',
    validVariants: [...activeOnboardingVariants],
    variantCharLookup: {},
    weightedActiveVariantsMap: onboardingVariantsMap,
  },
  onboardingLite: {
    activeVariants: activeOnboardingLiteVariants,
    pathRoot: '/users/onboard/',
    validVariants: [...activeOnboardingLiteVariants],
    variantCharLookup: {},
    weightedActiveVariantsMap: onboardingLiteVariantsMap,
  },
  onboardingLiteResume: {
    activeVariants: activeOnboardingLiteVariants,
    pathRoot: '/users/sign_up/resume',
    validVariants: activeOnboardingLiteVariants,
    variantCharLookup: {},
    weightedActiveVariantsMap:
      window?.IH?.abSplits?.onboardingLiteResume || onboardingLiteVariantsMap,
  },
  onboardingLiteSalary: {
    activeVariants: activeOnboardingLiteVariants,
    pathRoot: '/users/sign_up/salary',
    validVariants: activeOnboardingLiteVariants,
    variantCharLookup: {},
    weightedActiveVariantsMap:
      window?.IH?.abSplits?.onboardingLiteSalary || onboardingLiteVariantsMap,
  },
  onboardingLiteEducation: {
    activeVariants: activeOnboardingLiteVariants,
    pathRoot: '/users/sign_up/education',
    validVariants: activeOnboardingLiteVariants,
    variantCharLookup: {},
    weightedActiveVariantsMap:
      window?.IH?.abSplits?.onboardingLiteEducation ||
      onboardingLiteVariantsMap,
  },
  onboardingLiteAdvice: {
    activeVariants: activeOnboardingLiteVariants,
    pathRoot: '/users/sign_up/advice',
    validVariants: activeOnboardingLiteVariants,
    variantCharLookup: {},
    weightedActiveVariantsMap:
      window?.IH?.abSplits?.onboardingLiteAdvice || onboardingLiteVariantsMap,
  },
};

export const getRegVariantChar = (variant, variantGroup = DEFAULT_GROUP) =>
  variantGroupMap[variantGroup].variantCharLookup[variant] || variant[0];

export const charToVariantMap = (variantGroup = DEFAULT_GROUP) =>
  variantGroupMap[variantGroup].validVariants.reduce((map, variant) => {
    const variantChar = getRegVariantChar(variant, variantGroup);
    return {
      ...map,
      [variantChar]: variant,
    };
  }, {});

export const pathToVariantChar = (path, variantGroup = DEFAULT_GROUP) => {
  const { pathRoot } = variantGroupMap[variantGroup];
  const pathRE = new RegExp(String.raw`${pathRoot}(\w)`);
  const pathMatch = path?.match(pathRE);
  if (pathMatch && pathMatch[1]) {
    return pathMatch[1];
  }
  return null;
};

export const pathToVariant = (path, variantGroup = DEFAULT_GROUP) =>
  charToVariantMap(variantGroup)[pathToVariantChar(path, variantGroup)];

const getOverrideHash = () => {
  const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const queryHash = query.abTest || {};

  const cookieString = Cookie.get('abTestOverride');
  const cookieHash = cookieString ? JSON.parse(cookieString) : {};

  const overrideHash = { ...cookieHash, ...queryHash };
  Cookie.set('abTestOverride', overrideHash);
  return overrideHash;
};

const getVariantFromIndex = (index, weightedVariantsMap) => {
  let weightSum = 0;
  let i = 0;
  const variants = Object.keys(weightedVariantsMap);
  while (i < variants.length) {
    weightSum += weightedVariantsMap[variants[i]];
    if (index < weightSum) {
      return variants[i];
    }
    i += 1;
  }
  return variants[variants.length - 1];
};

export function getUserVariant(weightedVariantsMap) {
  if (weightedVariantsMap == null) return null;
  if (Object.keys(weightedVariantsMap).length === 0) return null;

  const totalWeight = Object.values(weightedVariantsMap).reduce(
    (sum, value) => sum + value,
    0
  );
  const index = stringhash(window.IH.userUuid) % totalWeight;
  const selectedVariant = getVariantFromIndex(index, weightedVariantsMap);
  return selectedVariant;
}

const activateABTest = (
  experiment,
  variantOverride,
  variantGroup = DEFAULT_GROUP
) => {
  const overrideHash = getOverrideHash();
  const variantGroupObj = variantGroupMap[variantGroup];
  const { validVariants, weightedActiveVariantsMap } = variantGroupObj;
  if (
    overrideHash[experiment] &&
    validVariants.includes(overrideHash[experiment])
  ) {
    return overrideHash[experiment];
  }
  if (variantOverride && validVariants.includes(variantOverride)) {
    return variantOverride;
  }

  return getUserVariant(weightedActiveVariantsMap);
};

export default activateABTest;
