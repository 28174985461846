import { estimatedJobMatchCounts } from '../constants/estimatedJobMatchCountConstants';

export default function estimatedJobMatchCount(
  onboardingData,
  onboardingOptions
) {
  const {
    acute_care_experience: acuteCareExperience,
    education_level: educationLevel,
    care_settings: careSettings,
    desiredLocations,
    nurseSpecialties,
  } = onboardingData;
  const { nurse_specialty_descriptions: nurseSpecialtyDescriptions } =
    onboardingOptions;

  const hasAcuteCareExperience = acuteCareExperience === 'yes';
  const RN = educationLevel !== 'student' && educationLevel !== 'lpn';
  const hasAcuteCareSetting =
    careSettings && careSettings.some((cs) => cs.short_name === 'acute_care');

  if (!hasAcuteCareExperience || !RN || !hasAcuteCareSetting) {
    return 0;
  }

  const relevantSpecialties = [
    'Emergency',
    'Critical Care',
    'Operating Room / Surgical',
  ].filter((relevantNSDesc) => {
    const nsdID = nurseSpecialtyDescriptions.find(
      (nsd) => nsd.description === relevantNSDesc
    )?.id;
    return nsdID && nurseSpecialties.get(nsdID);
  });

  let matchCount = 0;
  relevantSpecialties.forEach((nsd) => {
    desiredLocations.forEach((dl) => {
      const { city, state } = dl;
      matchCount += estimatedJobMatchCounts[state]?.[city]?.[nsd] || 0;
    });
  });

  return matchCount ? Math.floor(matchCount / 2) : matchCount;
}
