import { FormControl } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';

import { Input } from 'shared/components/ihcl/input';
import { Place } from '../records/Place';
import { getZipCodeInfo } from '../actions/zipCodeActions';

export class ZipCodeInput extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const { getZipCodeInfoBound, onChange } = this.props;

    const zipCode = e.currentTarget.value.substr(0, 5);
    if (!zipCode.match(/\d{5}/)) {
      onChange(new Place({ zipCode }), false);
      return;
    }

    const inputModifiedDuringRequest = () =>
      this.props.place.zipCode !== zipCode;

    onChange(new Place({ zipCode }), true);
    getZipCodeInfoBound(zipCode)
      .then((json) => {
        if (!inputModifiedDuringRequest()) {
          onChange(
            new Place({
              city: json.city,
              state: json.state,
              zipCode: json.zip_code,
            }),
            false
          );
        }
      })
      .catch(() => {
        if (!inputModifiedDuringRequest()) {
          onChange(new Place({ zipCode }), false);
        }
      });
  }

  render() {
    const { caption, error, id, label, placeholder, useIhcl } = this.props;
    const { zipCode } = this.props.place;

    const hiddenInputs = [];
    if (this.props.cityInputName && this.props.place.city) {
      hiddenInputs.push(
        <input
          key="hiddenCity"
          name={this.props.cityInputName}
          type="hidden"
          value={this.props.place.city}
        />
      );
    }

    if (this.props.stateInputName && this.props.place.state) {
      hiddenInputs.push(
        <input
          key="hiddenState"
          name={this.props.stateInputName}
          type="hidden"
          value={this.props.place.state}
        />
      );
    }

    if (this.props.zipCodeInputName && this.props.place.zipCode) {
      hiddenInputs.push(
        <input
          key="hiddenZipCode"
          name={this.props.zipCodeInputName}
          type="hidden"
          value={this.props.place.zipCode}
        />
      );
    }

    if (useIhcl) {
      return (
        <div>
          <Input
            id={id.length > 0 ? id : undefined}
            onChange={this.handleChange}
            error={error}
            type="text"
            value={zipCode || ''}
            caption={caption}
            label={label}
          />
          {hiddenInputs}
        </div>
      );
    }

    return (
      <div>
        <FormControl
          id={id.length > 0 ? id : undefined}
          onChange={this.handleChange}
          type="text"
          value={zipCode || ''}
          placeholder={placeholder}
        />
        {hiddenInputs}
      </div>
    );
  }
}

ZipCodeInput.propTypes = {
  caption: PropTypes.node,
  cityInputName: PropTypes.string,
  error: PropTypes.bool,
  getZipCodeInfoBound: PropTypes.func.isRequired,
  id: PropTypes.string,
  label: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  place: PropTypes.instanceOf(Place).isRequired,
  placeholder: PropTypes.string,
  stateInputName: PropTypes.string,
  useIhcl: PropTypes.bool,
  zipCodeInputName: PropTypes.string,
};

ZipCodeInput.defaultProps = {
  caption: null,
  error: false,
  id: '',
  cityInputName: null,
  label: null,
  placeholder: '',
  stateInputName: null,
  useIhcl: false,
  zipCodeInputName: null,
};

export default connect(null, {
  getZipCodeInfoBound: getZipCodeInfo,
})(ZipCodeInput);
