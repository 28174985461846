/* eslint-disable max-len */
export const byOpenings = [
  {
    market: 'Philadelphia-Camden-Wilmington, PA-NJ-DE-MD',
    maxSalary: '128000.0',
    hospitalLocations:
      'Abington,PA,19001;Bryn Mawr,PA,19010;Carneys Point Township,NJ,08069;Chadds Ford,PA,19317;Cherry Hill,NJ,08002;Claymont,DE,19703;Downingtown,PA,19335;East Norriton,PA,19403;Elkins Park,PA,19027;Elkton,MD,21921;Exton,PA,19341;Kennett Square,PA,19348;King of Prussia,PA,19406;Langhorne,PA,19047;Lansdale,PA,19446;Malvern,PA,19355;Media,PA,19063;Middletown,DE,19709;Newark,DE,19702;Newark,DE,19713;New Castle,DE,19720;North East,MD,21901;Paoli,PA,19301;Philadelphia,PA,19102; Philadelphia,PA,19107;Philadelphia,PA,19107;Philadelphia,PA,19111;Philadelphia,PA,19114;Philadelphia,PA,19115;Philadelphia,PA,19124;Philadelphia,PA,19125;Philadelphia,PA,19140;Philadelphia,PA,19141;Philadelphia,PA,19148;Richlandtown,PA,18955;Stratford,NJ,08084;Thorofare,NJ,08086;Warrington,PA,18976;Washington Township,NJ,08080;West Chester,PA,19380;West Grove,PA,19390;Wilmington,DE,19801;Wilmington,DE,19803;Wilmington,DE,19807;Wilmington,DE,19808;Woodstown,NJ,08098;Wynnewood,PA,19096',
  },
  {
    market: 'Washington-Arlington-Alexandria, DC-VA-MD-WV',
    maxSalary: '128000.0',
    hospitalLocations:
      'Alexandria,VA,22304;Alexandria,VA,22306;Bethesda,MD,20814;Clinton,MD,20735;Fairfax,VA,22033;Falls Church,VA,22042;Frederick,MD,21701;Fredericksburg,VA,22408;Gaithersburg,MD,20878;Hyattsville,MD,20781;Lanham,MD,20706;LaPlata,MD,20646;Leesburg,VA,20176;Olney,MD,20832;Port Republic,MD,20676;Port Tobacco,MD,20677;Prince Frederick,MD,20678;Rockville,MD,20850;Rockville,MD,20852;Washington,DC,20003;Washington,DC,20007;Washington,DC,20010;Washington,DC,20016;Washington ,DC,20036',
  },
  {
    market: 'Miami-Fort Lauderdale-West Palm Beach, FL',
    maxSalary: '115000.0',
    hospitalLocations:
      'Atlantis,FL,33462;Aventura,FL,33180;Boca Raton,FL,33434;Boca Raton,FL,33486;Boca Raton,FL,33496;Boynton Beach,FL,33435;Boynton Beach,FL,33472;Coral Gables,FL,33134;Coral Gables,FL,33146;Coral Springs,FL,33065;Davie,FL,33328;Delray Beach,FL,33484;Doral,FL,33166;Doral,FL,33178;Hialeah,FL,33013;Hialeah,FL,33016;Homestead,FL,33033;Jupiter,FL,33458;Loxahatchee,FL,33470;Margate,FL,33063;Miami Beach,FL,33129;Miami Beach,FL,33139;Miami Beach,FL,33140;Miami,FL,33133;Miami,FL,33136;Miami,FL,33139;Miami,FL,33143;Miami,FL,33146;Miami,FL,33156;Miami,FL,33165;Miami,FL,33169;Miami,FL,33173;Miami,FL,33175;Miami,FL,33176;Miami,FL,33177;Miami,FL,33178;Miami,FL,33184;Miami,FL,33185;Miami,FL,33186;Miami,FL,33196;Miami Lakes,FL,33014;North Miami,FL,33150;Palm Beach Gardens,FL,33410;Palmetto Bay,FL,33157;Pembroke Pines ,FL,33027;Plantation,FL,33324;Sunrise,FL,33323;Sunrise,FL,33351;Tamarac,FL,33321;Wellington,FL,33414;Weston,FL,33326;West Palm Beach,FL,33401;West Palm Beach,FL,33407',
  },
  {
    market: 'Dallas-Fort Worth-Arlington, TX',
    maxSalary: '120000.0',
    hospitalLocations:
      'Allen,TX,75013;Arlington,TX,76006;Arlington,TX,76011;Arlington,TX,76012;Arlington,TX,76013;Arlington,TX,76015;Arlington,TX,76018;Azle,TX,76020;Bedford,TX,76022;Bridgeport,TX,76426;Cleburne,TX,76033;Colleyville,TX,76034;Cooper,TX,75432;Dallas,TX,75226;Dallas,TX,75230;Dallas,TX,75231;Dallas,TX,75234;Dallas,TX,75243;Dallas,TX,75246;Dallas,TX,75251;Decatur,TX,76234;Denton,TX,76201;Denton,TX,76202;Denton,TX,76208;Denton,TX,76210;Euless,TX,76040;Flower Mound,TX,75028;Fort Worth,TX,76102;Fort Worth,TX,76104;Fort Worth,TX,76109;Fort Worth ,TX,76119;Fort Worth,TX,76132;Fort Worth,TX,76177;Fort Worth,TX,76196;Fort Worth,TX,76244;Frisco,TX,75033;Frisco,TX,75034;Frisco,TX,75035;Grapevine,TX,76051;Greenville,TX,75401;Irving,TX,75039;Irving,TX,75061;Kaufman,TX,75142;Lewisville,TX,75057;Mansfield,TX,76063;McKinney,TX,75069;McKinney,TX,75071;North Richland Hills,TX,76180;Plano,TX,75075;Plano,TX,75093;Prosper,TX,75078;Rowlett,TX,75088;Watauga,TX,76148;Waxahachie,TX,75165;Weatherford,TX,76086',
  },
  {
    market: 'Baltimore-Columbia-Towson, MD',
    maxSalary: '128000.0',
    hospitalLocations:
      'Annapolis,MD,21401;Baltimore,MD,21201;Baltimore,MD,21215;Baltimore,MD,21218;Baltimore,MD,21224;Baltimore,MD,21225;Baltimore,MD,21229;Baltimore,MD,21237;Baltimore,MD,21239;Baltimore,MD,21287;Bel Air,MD,21014;Catonsville,MD,21228;Centreville,MD,21617;Columbia,MD,21044;Edgewater,MD,21037;Ellicott City,MD,21043;Forest Hill,MD,21050;Parkton,MD,21120;West Friendship,MD,21794;Westminster,MD,21157',
  },
  {
    market: 'San Francisco-Oakland-Hayward, CA',
    maxSalary: '190000.0',
    hospitalLocations:
      'Alameda,CA,94501;Antioch,CA,94509;Antioch,CA,94531;Dublin,CA,94568;Fremont,CA,94538;Kentfield,CA,94904;Livermore,CA,94550;Livermore,CA,94551;Martinez,CA,94553;Novato,CA,94945;Oakland,CA,94609;Oakland,CA,94611;Pleasanton,CA,94588;Redwood City,CA,94063;Richmond,CA,94801;San Francisco,CA,94103;San Francisco,CA,94110;San Francisco,CA,94115;San Francisco,CA,94118;San Francisco,CA,94143;San Francisco,CA,94158;San Fransisco,CA,94903;San Leandro,CA,94577;San Rafael,CA,94901;San Rafael,CA,94903;San Ramon,CA,94583;South San Francisco,CA,94080;Walnut Creek,CA,94596;Walnut Creek,CA,94598',
  },
  {
    market: 'San Jose-Sunnyvale-Santa Clara, CA',
    maxSalary: '190000.0',
    hospitalLocations:
      'Gilroy,CA,95020;Palo Alto,CA,94304;Palo Alto,CA,94305;San Jose,CA,95116;San Jose,CA,95119;San Jose,CA,95124;San Jose,CA,95148;Santa Clara,CA,95051;Stanford,CA,94305',
  },
  {
    market: 'Houston-The Woodlands-Sugar Land, TX',
    maxSalary: '145000.0',
    hospitalLocations:
      'Baytown,TX,77520;Baytown,TX,77521;Bellaire,TX,77401;Channelview,TX,77530;Conroe,TX,77304;Crosby,TX,77532;Cypress,TX,77429;Cypress,TX,77433;Deer Park,TX,77536;Friendswood,TX,77546;Galena Park,TX,77547;Galveston,TX,77554;Houston,TX,77002;Houston,TX,77004;Houston,TX,77007;Houston,TX,77008;Houston,TX,77009;Houston,TX,77012;Houston,TX,77015;Houston,TX,77017;Houston,TX,77018;Houston,TX,77021;Houston,TX,77024;Houston,TX,77026;Houston,TX,77028;Houston,TX,77029;Houston,TX,77030;Houston,TX,77031;Houston,TX,77039;Houston,TX,77044;Houston,TX,77047;Houston,TX,77049;Houston,TX,77054;Houston,TX,77058;Houston,TX,77070;Houston,TX,77074;Houston,TX,77081;Houston,TX,77082;Houston,TX,77084;Houston,TX,77088;Houston,TX,77090;Houston,TX,77094;Houston,TX,77099;Houston,TX,77345;Humble,TX,77338;Humble,TX,77346;Katy,TX,77449;Katy,TX,77450;Katy,TX,77494;Kemah,TX,77565;Kingwood,TX,77339;La Porte,TX,77571;League City,TX,77573;Liberty,TX,77575;Missouri City,TX,77459;Mont Belvieu,TX,77520;Mont Belvieu,TX,77580;Pasadena,TX,77502;Pasadena,TX,77504;Pasadena,TX,77506;Pearland,TX,77584;Rosenberg,TX,77471;Seabrook,TX,77586;Shenandoah,TX,77380;Spring,TX,77379;Stafford,TX,77477;Sugar Land,TX,77479;Texas City,TX,77591;The Woodlands,TX,77381;Tomball,TX,77375;Waller,TX,77484;Webster,TX,77598;Willis,TX,77378',
  },
  {
    market: 'Chicago-Naperville-Elgin, IL-IN-WI',
    maxSalary: '100000.0',
    hospitalLocations:
      'Arlington Heights,IL,60004;Chicago,IL,60611;DeKalb,IL,60115;Des Plaines,IL,60018;Geneva,IL,60134;Harvard,IL,60033;Huntley,IL,60142;Joliet,IL,60436;Lake Forest,IL,60045;McHenry,IL,60050;Palos Heights,IL,60463;Sandwich,IL,60548;Wheaton,IL,60187;Winfield,IL,60190;Woodstock,IL,60098',
  },
  {
    market: 'Tampa-St. Petersburg-Clearwater, FL',
    maxSalary: '128000.0',
    hospitalLocations:
      "Brandon,FL,33511;Brooksville,FL,34613;Clearwater,FL,33755;Clearwater,FL,33756;Dade City,FL,33525;Dunedin,FL,34698;Hudson,FL,34667;Land O' Lakes,FL,34639;Largo,FL,33770;Lutz,FL,33558;New Port Richey,FL,34652;Plant City,FL,33563;Riverview,FL,33578;Safety Harbor,FL,34695;Saint Petersburg,FL,33705;St. Petersburg,FL,33701;St. Petersburg,FL,33707;St. Petersburg,FL,33709;St. Petersburg,FL,33710;Sun City Center,FL,33573;Tampa,FL,33603;Tampa,FL,33606;Tampa,FL,33607;Tampa,FL,33609;Tampa,FL,33615;Tarpin Springs,FL,34689;Trinity,FL,34655",
  },
];

export const bySalary = [
  {
    market: 'San Francisco-Oakland-Hayward, CA',
    maxSalary: '190000.0',
    hospitalLocations:
      'Alameda,CA,94501;Antioch,CA,94509;Antioch,CA,94531;Dublin,CA,94568;Fremont,CA,94538;Kentfield,CA,94904;Livermore,CA,94550;Livermore,CA,94551;Martinez,CA,94553;Novato,CA,94945;Oakland,CA,94609;Oakland,CA,94611;Pleasanton,CA,94588;Redwood City,CA,94063;Richmond,CA,94801;San Francisco,CA,94103;San Francisco,CA,94110;San Francisco,CA,94115;San Francisco,CA,94118;San Francisco,CA,94143;San Francisco,CA,94158;San Fransisco,CA,94903;San Leandro,CA,94577;San Rafael,CA,94901;San Rafael,CA,94903;San Ramon,CA,94583;South San Francisco,CA,94080;Walnut Creek,CA,94596;Walnut Creek,CA,94598',
  },
  {
    market: 'San Jose-Sunnyvale-Santa Clara, CA',
    maxSalary: '190000.0',
    hospitalLocations:
      'Gilroy,CA,95020;Palo Alto,CA,94304;Palo Alto,CA,94305;San Jose,CA,95116;San Jose,CA,95119;San Jose,CA,95124;San Jose,CA,95148;Santa Clara,CA,95051;Stanford,CA,94305',
  },
  {
    market: 'Salinas, CA',
    maxSalary: '180000.0',
    hospitalLocations: 'Monterey,CA,93942;Salinas,CA,93901',
  },
  {
    market: 'Santa Cruz-Watsonville, CA',
    maxSalary: '180000.0',
    hospitalLocations: 'Santa Cruz,CA,95065',
  },
  {
    market: 'Yuba City, CA',
    maxSalary: '160000.0',
    hospitalLocations:
      'Marysville,CA,95901;Yuba City,CA,95991;Yuba City,CA,95993',
  },
  {
    market: 'San Diego-Carlsbad, CA',
    maxSalary: '150000.0',
    hospitalLocations:
      'Chula Vista,CA,91910;Chula Vista,CA,91911;Coronado,CA,92118;Encinitas,CA,92024;La Mesa,CA,91942;National City,CA,91950;Oceanside,CA,92056;San Diego,CA,92103;San Diego,CA,92105;San Diego,CA,92111;San Diego,CA,92120;San Diego,CA,92121;San Diego,CA,92123',
  },
  {
    market: 'Riverside-San Bernardino-Ontario, CA',
    maxSalary: '150000.0',
    hospitalLocations:
      'Adelanto,CA,92301;Apple Valley,CA,92307;Apple Valley,CA,92308; Barstow,CA,92311;Barstow,CA,92311;Chino,CA,91710;Colton,CA,92324;Corona,CA,92879;Fontana,CA,92335;Montclair,CA,91763;Moreno Valley,CA,92555;Murrieta,CA,92562;Rancho Cucamonga,CA,91730;Riverside,CA,92501;Riverside,CA,92505;Riverside,CA,92506;San Bernardino,CA,92405;San Bernardino,CA,92407;San Diego,CA,92307;Tulare,CA,92374;Upland,CA,91786;Victorville,CA,92395',
  },
  {
    market: 'Oxnard-Thousand Oaks-Ventura, CA',
    maxSalary: '150000.0',
    hospitalLocations:
      'Simi Valley,CA,93065;Thousand Oaks,CA,91360;Thousand Oaks,CA,91361;Ventura,CA,93003',
  },
  {
    market: 'Houston-The Woodlands-Sugar Land, TX',
    maxSalary: '145000.0',
    hospitalLocations:
      'Baytown,TX,77520;Baytown,TX,77521;Bellaire,TX,77401;Channelview,TX,77530;Conroe,TX,77304;Crosby,TX,77532;Cypress,TX,77429;Cypress,TX,77433;Deer Park,TX,77536;Friendswood,TX,77546;Galena Park,TX,77547;Galveston,TX,77554;Houston,TX,77002;Houston,TX,77004;Houston,TX,77007;Houston,TX,77008;Houston,TX,77009;Houston,TX,77012;Houston,TX,77015;Houston,TX,77017;Houston,TX,77018;Houston,TX,77021;Houston,TX,77024;Houston,TX,77026;Houston,TX,77028;Houston,TX,77029;Houston,TX,77030;Houston,TX,77031;Houston,TX,77039;Houston,TX,77044;Houston,TX,77047;Houston,TX,77049;Houston,TX,77054;Houston,TX,77058;Houston,TX,77070;Houston,TX,77074;Houston,TX,77081;Houston,TX,77082;Houston,TX,77084;Houston,TX,77088;Houston,TX,77090;Houston,TX,77094;Houston,TX,77099;Houston,TX,77345;Humble,TX,77338;Humble,TX,77346;Katy,TX,77449;Katy,TX,77450;Katy,TX,77494;Kemah,TX,77565;Kingwood,TX,77339;La Porte,TX,77571;League City,TX,77573;Liberty,TX,77575;Missouri City,TX,77459;Mont Belvieu,TX,77520;Mont Belvieu,TX,77580;Pasadena,TX,77502;Pasadena,TX,77504;Pasadena,TX,77506;Pearland,TX,77584;Rosenberg,TX,77471;Seabrook,TX,77586;Shenandoah,TX,77380;Spring,TX,77379;Stafford,TX,77477;Sugar Land,TX,77479;Texas City,TX,77591;The Woodlands,TX,77381;Tomball,TX,77375;Waller,TX,77484;Webster,TX,77598;Willis,TX,77378',
  },
  {
    market: 'Seattle-Tacoma-Bellevue, WA',
    maxSalary: '145000.0',
    hospitalLocations:
      'Bellevue,WA,98004;Bothell,WA,98021;Edmonds,WA,98026;Everett,WA,98201;Everett,WA,98203;Everett,WA,98208;Graham,WA,98338;Issaquah,WA,98029;North Bend,WA,98045;Redmond,WA,98052;Seattle,WA,98104;Seattle,WA,98105;Seattle,WA,98107;Seattle,WA,98109;Seattle,WA,98122;Seattle,WA,98133;Seattle,WA,98195;Tacoma,WA,98409',
  },
];
/* eslint-enable max-len */
