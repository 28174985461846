export const estimatedJobMatchCounts = {
  CA: {
    Alameda: {
      'Critical Care': 43,
      'Medical / Surgical': 29,
      'Operating Room / Surgical': 45,
    },
    Anaheim: {
      'Operating Room / Surgical': 21,
    },
    Antioch: {
      'Critical Care': 43,
      'Medical / Surgical': 29,
      'Operating Room / Surgical': 45,
    },
    Bakersfield: {
      Emergency: 22,
      'Medical / Surgical': 28,
    },
    'Baldwin Park': {
      'Operating Room / Surgical': 21,
    },
    'Chula Vista': {
      'Critical Care': 22,
      'Operating Room / Surgical': 22,
    },
    Clearlake: {
      Emergency: 23,
      'Medical / Surgical': 23,
    },
    Downey: {
      'Critical Care': 22,
      'Operating Room / Surgical': 25,
    },
    Dublin: {
      'Critical Care': 43,
      'Medical / Surgical': 29,
      'Operating Room / Surgical': 45,
    },
    Fontana: {
      'Operating Room / Surgical': 21,
    },
    Fremont: {
      'Critical Care': 58,
      'Medical / Surgical': 38,
      'Operating Room / Surgical': 54,
    },
    Fresno: {
      'Critical Care': 56,
      Emergency: 23,
      'Medical / Surgical': 42,
      'Operating Room / Surgical': 58,
    },
    Glendale: {
      Emergency: 24,
      'Medical / Surgical': 33,
    },
    Hanford: {
      Emergency: 23,
      'Medical / Surgical': 23,
    },
    'Harbor City': {
      'Operating Room / Surgical': 21,
    },
    Irvine: {
      'Operating Room / Surgical': 22,
    },
    'La Mesa': {
      'Critical Care': 22,
      'Operating Room / Surgical': 22,
    },
    Livermore: {
      'Critical Care': 43,
      'Medical / Surgical': 29,
      'Operating Room / Surgical': 45,
    },
    Lodi: {
      Emergency: 23,
      'Medical / Surgical': 23,
    },
    'Los Angeles': {
      'Critical Care': 37,
      Emergency: 44,
      'Medical / Surgical': 44,
      'Operating Room / Surgical': 40,
    },
    Martinez: {
      'Critical Care': 43,
      'Medical / Surgical': 29,
      'Operating Room / Surgical': 45,
    },
    Marysville: {
      Emergency: 23,
      'Medical / Surgical': 23,
    },
    Modesto: {
      'Critical Care': 43,
      'Medical / Surgical': 29,
      'Operating Room / Surgical': 45,
    },
    Napa: {
      'Critical Care': 55,
      Emergency: 22,
      'Medical / Surgical': 41,
      'Operating Room / Surgical': 57,
    },
    Oakland: {
      'Critical Care': 58,
      'Medical / Surgical': 38,
      'Operating Room / Surgical': 54,
    },
    'Panorama City': {
      'Operating Room / Surgical': 21,
    },
    Pleasanton: {
      'Critical Care': 43,
      'Medical / Surgical': 29,
      'Operating Room / Surgical': 45,
    },
    Redding: {
      'Critical Care': 21,
    },
    'Redwood City': {
      'Critical Care': 43,
      'Medical / Surgical': 29,
      'Operating Room / Surgical': 45,
    },
    Reedley: {
      Emergency: 23,
      'Medical / Surgical': 23,
    },
    Richmond: {
      'Critical Care': 43,
      'Medical / Surgical': 29,
      'Operating Room / Surgical': 45,
    },
    Riverside: {
      'Critical Care': 23,
      'Operating Room / Surgical': 25,
    },
    Roseville: {
      'Critical Care': 48,
      Emergency: 33,
      'Medical / Surgical': 52,
      'Operating Room / Surgical': 48,
    },
    Sacramento: {
      'Critical Care': 48,
      'Medical / Surgical': 29,
      'Operating Room / Surgical': 50,
    },
    'San Diego': {
      'Critical Care': 45,
      Emergency: 44,
      'Medical / Surgical': 29,
      'Operating Room / Surgical': 47,
    },
    'San Francisco': {
      'Critical Care': 58,
      'Medical / Surgical': 38,
      'Operating Room / Surgical': 54,
    },
    'San Jose': {
      'Critical Care': 49,
      'Medical / Surgical': 33,
      'Operating Room / Surgical': 49,
    },
    'San Leandro': {
      'Critical Care': 43,
      'Medical / Surgical': 29,
      'Operating Room / Surgical': 45,
    },
    'San Rafael': {
      'Critical Care': 58,
      'Medical / Surgical': 38,
      'Operating Room / Surgical': 54,
    },
    'San Ramon': {
      'Critical Care': 43,
      'Medical / Surgical': 29,
      'Operating Room / Surgical': 45,
    },
    'Santa Clara': {
      'Critical Care': 43,
      'Medical / Surgical': 29,
      'Operating Room / Surgical': 45,
    },
    'Santa Rosa': {
      'Critical Care': 55,
      Emergency: 22,
      'Medical / Surgical': 41,
      'Operating Room / Surgical': 57,
    },
    'Simi Valley': {
      Emergency: 24,
      'Medical / Surgical': 27,
    },
    Sonora: {
      Emergency: 21,
      'Medical / Surgical': 21,
    },
    'South San Francisco': {
      'Critical Care': 43,
      'Medical / Surgical': 29,
      'Operating Room / Surgical': 45,
    },
    Stockton: {
      'Critical Care': 47,
      'Medical / Surgical': 32,
      'Operating Room / Surgical': 49,
    },
    Tehachapi: {
      Emergency: 22,
      'Medical / Surgical': 22,
    },
    Tulare: {
      Emergency: 23,
      'Medical / Surgical': 23,
    },
    Vacaville: {
      'Critical Care': 43,
      'Medical / Surgical': 29,
      'Operating Room / Surgical': 45,
    },
    Vallejo: {
      'Critical Care': 43,
      'Medical / Surgical': 29,
      'Operating Room / Surgical': 45,
    },
    Ventura: {
      'Operating Room / Surgical': 21,
    },
    'Walnut Creek': {
      'Critical Care': 43,
      'Medical / Surgical': 29,
      'Operating Room / Surgical': 45,
    },
    'Woodland Hills': {
      'Operating Room / Surgical': 21,
    },
  },
  DC: {
    Washington: {
      'Medical / Surgical': 43,
    },
  },
  DE: {
    Claymont: {
      'Critical Care': 23,
      'Medical / Surgical': 23,
      'Operating Room / Surgical': 21,
    },
    Middletown: {
      'Critical Care': 23,
      'Medical / Surgical': 23,
      'Operating Room / Surgical': 21,
    },
    'New Castle': {
      'Critical Care': 23,
      'Medical / Surgical': 23,
      'Operating Room / Surgical': 21,
    },
    Newark: {
      'Critical Care': 23,
      'Medical / Surgical': 23,
      'Operating Room / Surgical': 21,
    },
    Smyrna: {
      'Critical Care': 23,
      'Medical / Surgical': 23,
      'Operating Room / Surgical': 21,
    },
    Wilmington: {
      'Critical Care': 23,
      'Medical / Surgical': 23,
      'Operating Room / Surgical': 21,
    },
  },
  FL: {
    'Avon Park': {
      'Medical / Surgical': 25,
    },
    Belleview: {
      'Medical / Surgical': 25,
    },
    'Boca Raton': {
      Emergency: 22,
    },
    'Boynton Beach': {
      Emergency: 22,
    },
    Brandon: {
      'Medical / Surgical': 35,
    },
    Clearwater: {
      'Medical / Surgical': 39,
    },
    'Coral Gables': {
      Emergency: 22,
      'Medical / Surgical': 36,
    },
    'Coral Springs': {
      Emergency: 22,
    },
    'Dade City': {
      'Medical / Surgical': 25,
    },
    Davie: {
      'Critical Care': 25,
      Emergency: 35,
      'Medical / Surgical': 24,
      'Operating Room / Surgical': 30,
    },
    'Daytona Beach': {
      'Medical / Surgical': 25,
    },
    DeLand: {
      'Medical / Surgical': 25,
    },
    Deltona: {
      'Medical / Surgical': 25,
    },
    Dunedin: {
      'Medical / Surgical': 39,
    },
    Gainesville: {
      'Medical / Surgical': 25,
    },
    Hialeah: {
      'Medical / Surgical': 25,
    },
    Homestead: {
      Emergency: 22,
    },
    Jacksonville: {
      'Medical / Surgical': 35,
    },
    'Lake Placid': {
      'Medical / Surgical': 25,
    },
    Largo: {
      'Medical / Surgical': 35,
    },
    Marathon: {
      Emergency: 22,
    },
    Miami: {
      'Critical Care': 25,
      Emergency: 35,
      'Medical / Surgical': 49,
      'Operating Room / Surgical': 30,
    },
    'Miami Beach': {
      Emergency: 22,
    },
    'Miami Lakes': {
      Emergency: 22,
    },
    'North Miami': {
      'Medical / Surgical': 25,
    },
    Ocala: {
      'Medical / Surgical': 25,
    },
    Orlando: {
      'Medical / Surgical': 25,
    },
    Plantation: {
      'Critical Care': 25,
      Emergency: 35,
      'Medical / Surgical': 24,
      'Operating Room / Surgical': 30,
    },
    Riverview: {
      'Medical / Surgical': 39,
    },
    Sebring: {
      'Medical / Surgical': 38,
    },
    'St. Petersburg': {
      'Critical Care': 22,
      Emergency: 23,
      'Medical / Surgical': 67,
    },
    Sunrise: {
      Emergency: 22,
    },
    Tallahassee: {
      'Medical / Surgical': 25,
    },
    Tampa: {
      'Critical Care': 28,
      Emergency: 29,
      'Medical / Surgical': 49,
      'Operating Room / Surgical': 25,
    },
    Tavernier: {
      Emergency: 22,
    },
    Wellington: {
      Emergency: 22,
    },
    'West Palm Beach': {
      'Critical Care': 25,
      Emergency: 35,
      'Medical / Surgical': 49,
      'Operating Room / Surgical': 30,
    },
    Weston: {
      Emergency: 22,
    },
  },
  MD: {
    Baltimore: {
      'Medical / Surgical': 43,
    },
    Bethesda: {
      'Medical / Surgical': 32,
    },
    Columbia: {
      'Medical / Surgical': 32,
    },
    Elkton: {
      'Critical Care': 23,
      'Medical / Surgical': 23,
      'Operating Room / Surgical': 21,
    },
    Rockville: {
      'Medical / Surgical': 32,
    },
  },
  NE: {
    Oakland: {
      'Medical / Surgical': 24,
    },
  },
  NJ: {
    Woodstown: {
      'Critical Care': 23,
      'Medical / Surgical': 23,
      'Operating Room / Surgical': 21,
    },
  },
  NY: {
    'Bay Shore': {
      'Critical Care': 27,
      'Operating Room / Surgical': 28,
    },
    Huntington: {
      'Critical Care': 27,
      'Operating Room / Surgical': 28,
    },
    'New York': {
      'Critical Care': 23,
      'Operating Room / Surgical': 24,
    },
    Plainview: {
      'Critical Care': 27,
      'Operating Room / Surgical': 28,
    },
    'Staten Island': {
      'Critical Care': 21,
      'Operating Room / Surgical': 22,
    },
    Tarrytown: {
      'Critical Care': 21,
      'Operating Room / Surgical': 22,
    },
  },
  PA: {
    Allentown: {
      'Critical Care': 23,
      Emergency: 23,
      'Medical / Surgical': 26,
    },
    Bethlehem: {
      'Critical Care': 23,
      Emergency: 23,
      'Medical / Surgical': 26,
    },
    'Chadds Ford': {
      'Critical Care': 23,
      'Medical / Surgical': 23,
      'Operating Room / Surgical': 21,
    },
    'East Stroudsburg': {
      'Critical Care': 23,
      Emergency: 23,
      'Medical / Surgical': 26,
    },
    Hazleton: {
      'Critical Care': 23,
      Emergency: 23,
      'Medical / Surgical': 26,
    },
    Philadelphia: {
      'Critical Care': 36,
      Emergency: 34,
      'Medical / Surgical': 33,
      'Operating Room / Surgical': 34,
    },
    Pottsville: {
      'Critical Care': 23,
      Emergency: 23,
      'Medical / Surgical': 26,
    },
  },
  TX: {
    Allen: {
      'Medical / Surgical': 23,
    },
    Arlington: {
      'Critical Care': 29,
      Emergency: 48,
      'Medical / Surgical': 50,
      'Operating Room / Surgical': 23,
    },
    Azle: {
      'Critical Care': 28,
      Emergency: 34,
      'Medical / Surgical': 42,
    },
    Baytown: {
      'Critical Care': 39,
      Emergency: 56,
      'Medical / Surgical': 40,
      'Operating Room / Surgical': 57,
    },
    Bedford: {
      'Medical / Surgical': 23,
    },
    Cleburne: {
      'Medical / Surgical': 23,
    },
    'College Station': {
      Emergency: 34,
    },
    Dallas: {
      'Critical Care': 24,
      Emergency: 62,
      'Medical / Surgical': 45,
      'Operating Room / Surgical': 35,
    },
    Denton: {
      'Critical Care': 24,
      Emergency: 62,
      'Medical / Surgical': 45,
      'Operating Room / Surgical': 35,
    },
    'Fort Worth': {
      'Critical Care': 43,
      Emergency: 81,
      'Medical / Surgical': 65,
      'Operating Room / Surgical': 38,
    },
    Frisco: {
      'Critical Care': 24,
      Emergency: 62,
      'Medical / Surgical': 45,
      'Operating Room / Surgical': 35,
    },
    Grapevine: {
      'Critical Care': 33,
      Emergency: 50,
      'Medical / Surgical': 33,
    },
    Houston: {
      'Critical Care': 55,
      Emergency: 59,
      'Medical / Surgical': 55,
      'Operating Room / Surgical': 56,
    },
    Humble: {
      'Critical Care': 51,
      Emergency: 55,
      'Medical / Surgical': 55,
      'Operating Room / Surgical': 52,
    },
    Irving: {
      Emergency: 46,
      'Medical / Surgical': 22,
      'Operating Room / Surgical': 26,
    },
    Katy: {
      'Critical Care': 51,
      Emergency: 55,
      'Medical / Surgical': 55,
      'Operating Room / Surgical': 52,
    },
    Kaufman: {
      'Medical / Surgical': 23,
    },
    Lewisville: {
      Emergency: 30,
      'Medical / Surgical': 31,
      'Operating Room / Surgical': 21,
    },
    McKinney: {
      Emergency: 46,
      'Medical / Surgical': 22,
      'Operating Room / Surgical': 26,
    },
    Pasadena: {
      'Critical Care': 38,
      Emergency: 38,
      'Medical / Surgical': 39,
      'Operating Room / Surgical': 39,
    },
    Plano: {
      'Critical Care': 24,
      Emergency: 62,
      'Medical / Surgical': 45,
      'Operating Room / Surgical': 35,
    },
    Prosper: {
      'Medical / Surgical': 23,
    },
    'Round Rock': {
      Emergency: 34,
    },
    Rowlett: {
      Emergency: 32,
    },
    Stephenville: {
      'Medical / Surgical': 23,
    },
    Taylor: {
      Emergency: 34,
    },
    Temple: {
      'Critical Care': 28,
      Emergency: 48,
      'Medical / Surgical': 30,
      'Operating Room / Surgical': 22,
    },
    Waxahachie: {
      Emergency: 32,
    },
  },
};
