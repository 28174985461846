import PropTypes from 'prop-types';
import React from 'react';
import { TagLocationPinIcon } from 'registration/containers/Onboarding';
import { Center } from 'shared/components/ihcl/positioning';
import { Tag, SIZE as TAG_SIZE } from 'shared/components/ihcl/tag';

const locationTagType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
});
const locationsType = PropTypes.arrayOf(locationTagType);

export const LocationTags = ({ locations, removeTag }) => (
  <Center>
    {locations.map((location) =>
      location.id ? (
        <Tag
          $fadeIn
          $fullWidth
          key={location.id}
          size={TAG_SIZE.medium}
          onActionClick={() => removeTag(location)}
        >
          {location.zipCode && <TagLocationPinIcon />}
          {location.label}
        </Tag>
      ) : null
    )}
  </Center>
);

LocationTags.propTypes = {
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  removeTag: PropTypes.func.isRequired,
};

const NewLocationTags = ({ locations, moveTag }) => (
  <Center>
    {locations.map((location) => (
      <Tag
        key={location.id}
        closeable={false}
        kind="primary"
        size={TAG_SIZE.medium}
        onClick={() => moveTag(location)}
        variant="solid"
      >
        {location.label}
      </Tag>
    ))}
  </Center>
);

NewLocationTags.propTypes = {
  locations: locationsType.isRequired,
  moveTag: PropTypes.func.isRequired,
};
