import React, { useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Center } from 'shared/components/ihcl/positioning';
import {
  MonthPicker,
  dateMatchYYYYMM,
} from 'shared/components/ihcl/month_picker';
import {
  Onboarding,
  OnboardingContext,
  OnboardingSubtitle,
  OnboardingText,
} from 'registration/containers/Onboarding';

import { pathToVariant } from 'shared/helpers/activateABTest';

const validYearRE = /^([1-2][0-9]{3})(-)/;
const currentYear = new Date().getFullYear();
const minYear = currentYear - 100;
const maxYear = currentYear + 100;

export const dateStringStartsWithValidYear = (dateString) => {
  const dateStringMatch =
    dateString && dateString.match && dateString.match(validYearRE);
  if (!dateStringMatch) {
    return false;
  }
  const dateStringYear = dateStringMatch[1];
  const isSaneYear =
    dateStringYear && dateStringYear > minYear && dateStringYear < maxYear;
  return dateStringYear && isSaneYear;
};

// eslint-disable-next-line react/require-default-props
const OnboardingRNLicenseDate = ({
  // eslint-disable-next-line react/require-default-props
  nextAction,
  // eslint-disable-next-line react/require-default-props
  nextActionForCurrentStudents,
  // eslint-disable-next-line react/require-default-props
  previousAction,
  stepNumber,
}) => {
  const {
    onboardingBasePath,
    onboardingData,
    onboardingUpdates,
    setOnboardingData,
  } = useContext(OnboardingContext);
  const [licenseDate, setLicenseDate] = useState(
    onboardingUpdates.license_issue_date?.replace(/-01$/, '')
  );
  const [graduationDate, setGraduationDate] = useState(
    onboardingUpdates.graduation_date?.replace(/-01$/, '')
  );
  const nextActionButtonRef = useRef(null);
  const isStudent = onboardingUpdates.education_level === 'student';
  if (!onboardingData || Object.keys(onboardingData).length < 1) {
    return null;
  }

  let subtitleText = 'When did you first obtain your RN license?';
  if (isStudent) {
    if (onboardingUpdates.license_issue_date === null) {
      subtitleText = 'When did you graduate?';
    } else {
      subtitleText = 'When will you graduate?';
    }
  }

  const variant = pathToVariant(onboardingBasePath);

  return (
    <Onboarding
      autoSpacedStepContents={variant !== 'yuki'}
      currentStep={stepNumber}
      isNextDisabled={
        (isStudent && !dateStringStartsWithValidYear(graduationDate)) ||
        (!isStudent && !dateStringStartsWithValidYear(licenseDate))
      }
      nextAction={isStudent ? nextActionForCurrentStudents : nextAction}
      nextActionButtonRef={nextActionButtonRef}
      previousAction={previousAction}
      stepKeys={['license_issue_date', 'graduation_date']}
    >
      <Center>
        <Center>
          <OnboardingSubtitle>{subtitleText}</OnboardingSubtitle>
          <OnboardingText>
            {isStudent
              ? "It's okay to estimate"
              : "This is your license's original issue date."}
          </OnboardingText>
          {isStudent ? (
            <MonthPicker
              value={graduationDate || undefined}
              min={`${minYear}-01`}
              max={`${maxYear}-12`}
              onChange={(date) => {
                setGraduationDate(date);

                const dateMatch = date?.match(dateMatchYYYYMM);

                let newGraduationDate;
                if (dateMatch) {
                  const [, year, month] = dateMatch;
                  newGraduationDate = `${year}-${month}-01`;
                }

                setOnboardingData({
                  ...onboardingData,
                  graduation_date: newGraduationDate,
                });
              }}
            />
          ) : (
            <MonthPicker
              value={licenseDate || undefined}
              min={`${minYear}-01`}
              max={`${maxYear}-12`}
              onChange={(date) => {
                setLicenseDate(date);

                const dateMatch = date?.match(dateMatchYYYYMM);

                let licenseIssueDate;

                if (dateMatch) {
                  const [, year, month] = dateMatch;
                  licenseIssueDate = `${year}-${month}-01`;
                }
                setOnboardingData({
                  ...onboardingData,
                  license_issue_date: licenseIssueDate,
                });
              }}
            />
          )}
        </Center>
      </Center>
    </Onboarding>
  );
};
OnboardingRNLicenseDate.propTypes = {
  nextAction: PropTypes.string,
  nextActionForCurrentStudents: PropTypes.string,
  previousAction: PropTypes.string,
  stepNumber: PropTypes.number.isRequired,
};
OnboardingRNLicenseDate.defaultProps = {
  nextAction: null,
  nextActionForCurrentStudents: null,
  previousAction: null,
};

export default OnboardingRNLicenseDate;
