import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import ErrorBoundary from 'shared/components/ErrorBoundary';
import { usePrevious } from 'shared/helpers/hooks';
import withGATracker from 'shared/helpers/withGATracker';

import {
  augmentOptions,
  getAugmentedOnboardingData,
} from '../helpers/talentProfileConversions';

import OnboardingCurrentLocation from '../components/new_pages/OnboardingCurrentLocation';
import OnboardingLicenses from '../components/new_pages/OnboardingLicenses';
import OnboardingLocation from '../components/new_pages/OnboardingLocation';
import OnboardingPermanentJob from '../components/new_pages/OnboardingPermanentJob';
import OnboardingSpecialtiesList from '../components/new_pages/OnboardingSpecialtiesList';
import SpecialtiesWantsToDo from '../components/new_pages/SpecialtiesWantsToDo';
import { OnboardingContext } from './Onboarding';
import ReactivationCompleted from '../components/new_pages/ReactivationCompleted';
import OnboardingResume from '../components/new_pages/OnboardingResume';

export const setUrl = (currentOnboardStep, history, match) => {
  if (currentOnboardStep && currentOnboardStep >= 1) {
    history.push(`${match.path}/${currentOnboardStep}`);
  } else {
    history.push(`${match.path}/1`);
  }
};

const TalentReactivationContainer = ({ match }) => {
  const [onboardingData, setOnboardingData] = useState({});
  const [onboardingUpdates, setOnboardingUpdates] = useState({});
  const [isRegistrationDataParsed, setIsRegistrationDataParsed] =
    useState(false);
  const [onboardingOptions, setOnboardingOptions] = useState({});
  const [isUpdatingProfile, setIsUpdatingProfile] = useState(false);
  const [currentStep, setCurrentStep] = useState(null);
  const priorStep = usePrevious(currentStep);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const node = document.getElementById('registration-app');
    const { talent_profile: talentProfile, ...options } = JSON.parse(
      node.getAttribute('data')
    );
    document.documentElement.classList.add('reactivation');
    // This fixes some odd scroll behavior on iOS when the onscreen keyboard is
    // hidden.
    document.addEventListener('focusout', () => {
      if (document.documentElement.scrollTop > 0) {
        document.documentElement.scrollTop = 0;
      }
    });
    setOnboardingOptions(augmentOptions(options, Object.keys(options)));
    setOnboardingData(
      getAugmentedOnboardingData(onboardingData, talentProfile, options)
    );
    if (
      talentProfile.current_reactivation_step &&
      talentProfile.current_reactivation_step >= 1
    ) {
      history.push(`${match.path}/${talentProfile.current_reactivation_step}`);
    } else {
      const stepNumber =
        talentProfile.job_search_status === 'not_looking' ? 1 : 2;
      history.push(`${match.path}/${stepNumber}`);
    }
    setIsRegistrationDataParsed(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!Object.keys(onboardingData).length) {
      return;
    }
    const step = location.pathname.replace(`${match.path}/`, '');
    let stepNumber = Number(step);
    if (Number.isNaN(stepNumber) || Number(stepNumber) === 0) {
      stepNumber = 1;
    }
    if (stepNumber && currentStep !== stepNumber) {
      setCurrentStep(stepNumber);
    }
  }, [currentStep, location.pathname, match.path, onboardingData]);

  if (!isRegistrationDataParsed) {
    return null;
  }

  const totalSteps = 7;
  return (
    <OnboardingContext.Provider
      value={{
        history,
        isUpdatingProfile,
        onboardingBasePath: match.path,
        onboardingData,
        onboardingOptions,
        onboardingUpdates,
        priorStep,
        setIsUpdatingProfile,
        setOnboardingData,
        setOnboardingUpdates,
        totalSteps,
      }}
    >
      <BrowserRouter>
        <Switch>
          <Redirect exact from={match.url} to={`${match.path}/1`} />
          <ErrorBoundary>
            <Route
              path={`${match.path}/:stepNumber`}
              render={(props) => {
                switch (currentStep) {
                  case 1:
                    return (
                      <OnboardingPermanentJob
                        {...props}
                        stepNumber={currentStep}
                        nextAction={`${match.path}/2`}
                      />
                    );
                  case 2:
                    return (
                      <OnboardingSpecialtiesList
                        {...props}
                        isReactivation
                        hideRecentGradYearEntry
                        stepNumber={currentStep}
                        nextAction={`${match.path}/3`}
                        nextActionForRecentGrads={`${match.path}/4`}
                      />
                    );
                  case 3:
                    return (
                      <SpecialtiesWantsToDo
                        {...props}
                        stepNumber={currentStep}
                      />
                    );
                  case 4:
                    return (
                      <OnboardingCurrentLocation
                        {...props}
                        stepNumber={currentStep}
                        nextAction={
                          onboardingData.openToRelocation
                            ? `${match.path}/5`
                            : `${match.path}/6`
                        }
                        previousAction={
                          onboardingData.recent_grad
                            ? `${match.path}/2`
                            : `${match.path}/3`
                        }
                      />
                    );
                  case 5:
                    return (
                      <OnboardingLocation {...props} stepNumber={currentStep} />
                    );
                  case 6:
                    return (
                      <OnboardingLicenses
                        {...props}
                        stepNumber={currentStep}
                        nextAction={`${match.path}/7`}
                        previousAction={
                          onboardingData.openToRelocation
                            ? `${match.path}/5`
                            : `${match.path}/4`
                        }
                      />
                    );
                  case 7:
                    // Proxy characteristics of just converted from recent_grad
                    if (
                      onboardingData.years_experience <= 1 ||
                      onboardingData.work_experiences.length === 0 ||
                      onboardingData.nurse_specialties.every(
                        (ns) => ns.years_experience <= 1
                      )
                    ) {
                      return (
                        <OnboardingResume
                          {...props}
                          stepNumber={currentStep}
                          nextAction="/talent/home"
                          totalSteps={totalSteps}
                          isReactivation
                          collapsedExperienceEntry
                        />
                      );
                    }
                    return (
                      <ReactivationCompleted
                        {...props}
                        currentStep={currentStep}
                        nextAction="/talent/home"
                        totalSteps={totalSteps}
                      />
                    );
                  default: {
                    return null;
                  }
                }
              }}
            />
          </ErrorBoundary>
        </Switch>
      </BrowserRouter>
    </OnboardingContext.Provider>
  );
};

TalentReactivationContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
      stepNumber: PropTypes.string,
    }),
  }).isRequired,
  talentProfile: PropTypes.object.isRequired,
  uiState: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  talentProfile: state.talent_profile,
  uiState: state.uiState,
});

export default withGATracker(
  connect(mapStateToProps)(TalentReactivationContainer)
);
