import React from 'react';
import { Button, StyledBaseButton } from 'shared/components/ihcl/button';
import { LucideIcon } from 'shared/components/ihcl/lucideIcon';
import { styled, withStyle } from 'shared/components/ihcl/styled';
import { Stack } from 'shared/components/ihcl/patterns';

const TabButton = withStyle(StyledBaseButton, ({ $theme }) => ({
  paddingRight: $theme.sizing.unit8,
  paddingLeft: $theme.sizing.unit8,
  ...$theme.typography.LabelSmall,
}));

const TabWrapper = styled('div', ({ $theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: $theme.sizing.unit4,
}));

export const AppointmentTimeOfDayInput = ({
  text,
  isSelected,
  onSelect,
}: {
  text: string;
  isSelected: boolean;
  onSelect: Function;
}) => {
  const iconMap = {
    Morning: 'Sun',
    Afternoon: 'Sunset',
    Evening: 'Moon',
  };
  return (
    <Button
      key={`timeOfDay-${text}`}
      onClick={() => onSelect(text)}
      kind={isSelected ? 'primary' : 'tertiary'}
      color="neutral"
      size="compact"
      fullWidth
      // eslint-disable-next-line react/no-unstable-nested-components
      startEnhancer={() => <LucideIcon name={iconMap[text]} size="unit16" />}
      overrides={{
        BaseButton: {
          component: TabButton,
        },
      }}
    >
      <TabWrapper>{text}</TabWrapper>
    </Button>
  );
};

const FullWidth = styled('div', () => ({
  width: '100%',
}));

export const AppointmentTimeOfDays = ({
  times,
  date,
  timeOfDay,
  setTimeOfDay,
}: {
  times: Array<Date>;
  date: Date;
  timeOfDay: string;
  setTimeOfDay: Function;
}) => {
  const [showFilters, setShowFilters] = React.useState<boolean>(false);

  React.useEffect(() => {
    const filteredTimes: Array<Date> = times.filter(
      (t) => date?.getDate() === t.getDate()
    );
    let filtersCounter = 0;
    let defaultFilter = null;
    if (filteredTimes.some((t) => t.getHours() < 12)) {
      filtersCounter += 1;
      defaultFilter = 'Morning';
    }
    if (filteredTimes.some((t) => t.getHours() >= 12 && t.getHours() < 17)) {
      filtersCounter += 1;
      defaultFilter = defaultFilter || 'Afternoon';
    }
    if (filteredTimes.some((t) => t.getHours() >= 17)) {
      filtersCounter += 1;
      defaultFilter = defaultFilter || 'Evening';
    }

    setShowFilters(filtersCounter >= 2);
    setTimeOfDay(filtersCounter >= 2 ? defaultFilter : null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [times, date]);

  if (!showFilters) {
    return null;
  }

  return (
    <FullWidth>
      <Stack $flexDirection="row" $gapSizing="unit8">
        <AppointmentTimeOfDayInput
          isSelected={timeOfDay === 'Morning'}
          onSelect={setTimeOfDay}
          text="Morning"
        />
        <AppointmentTimeOfDayInput
          isSelected={timeOfDay === 'Afternoon'}
          onSelect={setTimeOfDay}
          text="Afternoon"
        />
        <AppointmentTimeOfDayInput
          isSelected={timeOfDay === 'Evening'}
          onSelect={setTimeOfDay}
          text="Evening"
        />
      </Stack>
    </FullWidth>
  );
};
