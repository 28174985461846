import React, { useContext, useState } from 'react';
import { styled } from 'shared/components/ihcl/styled';

import ILink from 'shared/components/ILink';
import { Center } from 'shared/components/ihcl/positioning';

import {
  OnboardingContext,
  OnboardingTitle,
  OnboardingText,
  OnboardingWrapper,
} from 'registration/containers/Onboarding';

import ScheduleScreeningButton from 'shared/components/ScheduleScreeningButton';
import { pathToVariant } from 'shared/helpers/activateABTest';
import { doPost } from 'shared/helpers/http';
import { trackClick } from 'shared/helpers/tracking';
import { OnboardingData } from 'registration/helpers/types';
import AppointmentModal from 'talent/components/screening/AppointmentModal';

const SkipLink = styled('div', {
  marginTop: '20vh',
});

const sendUpdate = ({ talentProfileId, nextAction }) => {
  const updates = {
    talent_tags: ['onboard_schedule_screen_sept2021'],
  };
  doPost(`/talent_profile/${talentProfileId}/tag`, updates)
    .catch((error) => {
      console.error(error);
    })
    .finally(() => {
      window.location.assign(new URL(nextAction, window.location.origin));
    });
};

type ScheduleScreeningProps = {
  nextAction: string;
};

export const ScheduleScreening = ({ nextAction }: ScheduleScreeningProps) => {
  const { onboardingBasePath, onboardingData: talentProfile } = useContext<{
    onboardingBasePath: string;
    onboardingData: OnboardingData;
  }>(OnboardingContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isChangingLocation, setIsChangingLocation] = useState(false);

  const variant = pathToVariant(onboardingBasePath);

  const isYukiVariant = variant === 'yuki';
  const customStyles = isYukiVariant ? {} : { justifyContent: 'space-between' };
  return (
    <>
      <Center>
        <OnboardingWrapper
          style={customStyles}
          $compactMaxWidth={isYukiVariant}
        >
          <span>&nbsp;</span>

          <OnboardingTitle $reducedFontSize={isYukiVariant}>
            Activate your job search
          </OnboardingTitle>
          <OnboardingText data-testid="copy">
            <>
              To activate your job search, schedule a quick call with a nurse on
              our team to make sure we understand your needs and experience.
              This isn&apos;t an interview — we&apos;re on your side!
            </>
          </OnboardingText>
          <ScheduleScreeningButton
            buttonSize="default"
            callToAction="Activate Now"
            talentProfile={talentProfile}
            onClick={() => setIsModalOpen(true)}
            onScreeningScheduled={() => {
              setIsChangingLocation(true);
              sendUpdate({ talentProfileId: talentProfile.id, nextAction });
            }}
            isLoading={isChangingLocation}
            eventProperties={{
              active: talentProfile.active,
              has_job_matches: talentProfile.has_job_matches,
              screen_status: talentProfile.screen_status,
            }}
          />
          <SkipLink>
            <ILink
              to={nextAction}
              onClick={() => {
                trackClick(
                  'talent.screening.schedule_call.do_later',
                  {},
                  sendUpdate({ talentProfileId: talentProfile.id, nextAction })
                );
              }}
            >
              I&apos;ll do this later
            </ILink>
          </SkipLink>
        </OnboardingWrapper>
      </Center>
      <AppointmentModal
        isOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        talentProfile={talentProfile}
        title="Activate your job search"
      />
    </>
  );
};

export default ScheduleScreening;
