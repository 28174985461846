import { styled } from 'shared/components/ihcl/styled';
import React from 'react';

import { ProgressSteps } from 'shared/components/ihcl/progress_steps';

import { useOnboardingVariantContext } from './providers/OnboardingVariantProvider';

const HeaderWrapper = styled('div', {
  textAlign: 'left',
});

const StepProgress = () => {
  const { currentStep, totalSteps } = useOnboardingVariantContext();
  return (
    <HeaderWrapper>
      <ProgressSteps totalSteps={totalSteps} currentStep={currentStep} />
    </HeaderWrapper>
  );
};

export default StepProgress;
