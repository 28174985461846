import { List } from 'immutable';

import { Place } from 'shared/records/Place';
import constants from '../../shared/constants/constants.json';

const desiredLocationOptions = List(
  constants.DESIRED_LOCATIONS.map((loc) => {
    if (loc.value) {
      return { ...loc, value: new Place(loc.value) };
    }
    return loc;
  })
);

// above doesn't work with tsx files
const desiredLocationOptionsFrozen = Object.freeze(
  constants.DESIRED_LOCATIONS.map((loc) => {
    if (loc.value) {
      return { ...loc, value: new Place(loc.value) };
    }

    return loc;
  })
);

export { desiredLocationOptionsFrozen, desiredLocationOptions as default };
