export function checkResponseStatus(response, { method } = {}) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  if (response.status === 401) {
    mixpanel.track('User redirected to sign-in', {});
    window.location.reload();
    return null;
  }

  const url = new URL(response.url);
  const errorMsg =
    `HTTP ${response.status} (${response.statusText}) for ` +
    `${method || '<unknown method>'} ${url.pathname}${url.search}`;
  const error = new Error(errorMsg);
  error.response = response;
  throw error;
}
