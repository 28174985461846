import React from 'react';
import PropTypes from 'prop-types';
import { useStyletron, withStyle } from 'shared/components/ihcl/styled';
import { Caption } from 'shared/components/ihcl/input';
import {
  Select as BaseSelect,
  StyledControlContainer as BaseStyledContrlContainer,
  StyledIconsContainer as BaseStyledIconsContainer,
  StyledValueContainer as BaseStyledValueContainer,
  SIZE,
} from 'baseui/select';
import StatefulContainer from 'baseui/select/stateful-select-container';
import { Tag, KIND as TAG_KIND } from './tag';

export const StyledControlContainer = withStyle(
  BaseStyledContrlContainer,
  ({ $multi, $size, $theme, $error }) => {
    let height = 'auto';
    if (!$multi) {
      height =
        $size === 'compact'
          ? $theme.sizing.inputCompactHeight
          : $theme.sizing.inputHeight;
    }

    const additionalStyles = $error
      ? {
          backgroundColor: $theme.colors.inputFillError,
        }
      : {};

    return {
      height,
      ...additionalStyles,
    };
  }
);

export const StyledValueContainer = withStyle(
  BaseStyledValueContainer,
  ({ $theme }) => ({
    paddingLeft: $theme.sizing.inputPaddingLeft,
  })
);

export const StyledCompactValueContainer = withStyle(
  BaseStyledValueContainer,
  ({ $theme }) => ({
    fontSize: '14px',
    paddingLeft: $theme.sizing.inputCompactPadding,
    paddingRight: $theme.sizing.inputCompactPadding,
  })
);

// eslint-disable-next-line react/require-default-props
export const Select = (props) => {
  const IconsContainer = BaseStyledIconsContainer;
  let ValueContainer = StyledValueContainer;
  const [, theme] = useStyletron();

  if (props.size === 'compact') {
    ValueContainer = StyledCompactValueContainer;
  }

  return (
    <>
      <BaseSelect
        $size={props.size}
        {...props}
        overrides={{
          Root: {
            props: {
              $size: props.size,
              className: props.className,
              ...(props.instanceId && { 'data-instance-id': props.instanceId }),
            },
          },
          ClearIcon: {
            style: () =>
              props.size === 'compact'
                ? {
                    padding: '8px',
                    width: '32px',
                    height: '32px',
                  }
                : {
                    padding: '8px',
                    width: '40px',
                    height: '40px',
                  },
          },
          ControlContainer: {
            props: {
              $multi: props.multi,
              $size: props.size,
              $theme: theme,
              $error: props.error,
            },
            component: StyledControlContainer,
          },
          IconsContainer,
          SelectArrow: {
            style: () =>
              props.size === 'compact'
                ? {
                    padding: '8px 0',
                    width: '16px',
                    height: '32px',
                  }
                : {
                    padding: '8px 0',
                    width: '24px',
                    height: '40px',
                  },
          },
          ValueContainer,
          // eslint-disable-next-line react/no-unstable-nested-components
          Tag: (tagProps) => (
            <Tag {...tagProps} kind={TAG_KIND.accent} variant="solid" />
          ),
          SingleValue: {
            style: () => ({
              display: 'flex',
              alignItems: 'center',
            }),
          },
          ...(props.overrides || {}),
        }}
      />
      {props.caption && <Caption $error={props.error}>{props.caption}</Caption>}
    </>
  );
};
Select.propTypes = {
  caption: PropTypes.node,
  className: PropTypes.string,
  error: PropTypes.bool,
  instanceId: PropTypes.string,
  multi: PropTypes.bool,
  overrides: PropTypes.object,
  size: PropTypes.oneOf([SIZE.default, SIZE.compact]),
};
Select.defaultProps = {
  caption: null,
  className: '',
  error: false,
  instanceId: null,
  multi: false,
  overrides: null,
  size: SIZE.default,
};

export const StatefulSelect = (props) => (
  <StatefulContainer {...props}>
    {(childrenProps) => <Select {...childrenProps} />}
  </StatefulContainer>
);
