import { Map } from 'immutable';
import { handleActions } from 'redux-actions';

import {
  clearErrorDialog,
  displayError,
  displayErrorDialog,
} from 'shared/actions/errorActions';

import {
  updateTalentProfileComplete,
  updateTalentProfileError,
  updateTalentProfileStarted,
} from 'shared/actions/talentProfileActions';

import {
  scheduleScreeningStarted,
  scheduleScreeningComplete,
  scheduleScreeningError,
} from 'shared/actions/screeningActions';

const uiState = handleActions(
  {
    [updateTalentProfileComplete]: (state) => state.set('profileSaving', false),
    [updateTalentProfileError]: (state) => state.set('profileSaving', false),
    [updateTalentProfileStarted]: (state) => state.set('profileSaving', true),
    [displayError]: (state, action) =>
      state.set('errorDialogMessage', action.payload),
    [displayErrorDialog]: (state, action) =>
      state.set('errorDialogMessage', action.payload),
    [clearErrorDialog]: (state) => state.set('errorDialogMessage', null),
    [scheduleScreeningStarted]: (state) =>
      state.set('scheduleScreeningRequestInProgress', true),

    [scheduleScreeningComplete]: (state) =>
      state
        .set('scheduleScreeningRequestInProgress', false)
        .set('scheduleScreeningRequestCompleted', true),

    [scheduleScreeningError]: (state) =>
      state.set('scheduleScreeningRequestInProgress', false),
  },
  Map({ profileSaving: false, errorDialogMessage: null })
);

export default uiState;
