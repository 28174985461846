import { styled } from 'shared/components/ihcl/styled';

export const Center = styled('div', {
  textAlign: 'center',
});

export const Left = styled('div', {
  textAlign: 'left',
});

export const VerticalSpacedItems = styled('div', ({ $theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: $theme.sizing.scale600,
}));
