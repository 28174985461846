import React from 'react';
import PropTypes from 'prop-types';
import { Caption } from 'shared/components/ihcl/input';
import { Textarea as BaseTextarea } from 'baseui/textarea';

// eslint-disable-next-line react/require-default-props
export const Textarea = ({ instanceId, ...props }) => (
  <>
    <BaseTextarea
      {...props}
      overrides={{
        Input: {
          props: {
            ...(instanceId && { 'data-instance-id': instanceId }),
          },
        },
      }}
    />
    {props.caption && <Caption $error={props.error}>{props.caption}</Caption>}
  </>
);

Textarea.propTypes = {
  caption: PropTypes.string,
  error: PropTypes.bool,
  instanceId: PropTypes.string,
};

Textarea.defaultProps = {
  caption: null,
  error: false,
  instanceId: null,
};
