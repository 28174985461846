import PropTypes from 'prop-types';
import React from 'react';
import { styled } from 'shared/components/ihcl/styled';

const DividerContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

const DividerLine = styled('div', ({ $theme }) => ({
  content: '',
  flex: '1 1 0px',
  height: 1,
  borderBottomColor: $theme.colors.grayLine,
  borderBottomStyle: 'solid',
  borderBottomWidth: '1px',
}));

const DividerText = styled('div', ({ $theme }) => ({
  fontSize: '14px',
  color: $theme.colors.grayLabel,
  paddingLeft: '10px',
  paddingRight: '10px',
}));

// eslint-disable-next-line react/require-default-props
export const Divider = ({ children, ...props }) => (
  <DividerContainer {...props}>
    <DividerLine />
    <DividerText>{children}</DividerText>
    <DividerLine />
  </DividerContainer>
);

Divider.propTypes = {
  children: PropTypes.node,
};

Divider.defaultProps = {
  children: null,
};
