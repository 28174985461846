import { Set } from 'immutable';
import { Crc32 } from '@aws-crypto/crc32';
import { fromUtf8 } from '@smithy/util-utf8';

import sharedConstants from 'shared/constants/constants.json';

export const ENROLLMENT_FLAG_SUFFIX = '_enrollment';
export const DEFAULT_TREATMENT_VARIANT_NAME = 'treatment';
export const DEFAULT_CONTROL_VARIANT_NAME = 'control';

/**
 * Deterministic hash function that will always return the same integer.
 * It provides consistent assignment of variants to actors.
 * Implemented this way instead of simply using id because
 *   1. Idea stolen from Flipper
 *   2. Adds randomness to the assignment.
 *   3. Simple to implement on back & front-end. See experiment.rb
 *
 * @param experimentName Your experiment
 * @param actorId Talent or employer profile id
 * @returns Integer. Example: 'web5449_job_discovery', 1 == 4055248109
 */
export const getTreatmentVariantDigest = (
  experimentName: string,
  actorId: number
): number =>
  new Crc32().update(fromUtf8(`${experimentName}_variant_${actorId}`)).digest();

export const getExperimentAssignmentFromFeatureFlags = (
  featureFlags: Set<string>,
  experimentName: string,
  actorId?: number
): string => {
  const enrollmentFlag = `${experimentName}${ENROLLMENT_FLAG_SUFFIX}`;
  if (!featureFlags.includes(enrollmentFlag)) {
    return null;
  }

  if (featureFlags.includes(experimentName)) {
    const variants = sharedConstants.TREATMENT_VARIANTS[experimentName];
    if (variants) {
      return variants[
        getTreatmentVariantDigest(experimentName, actorId) % variants.length
      ];
    }

    return DEFAULT_TREATMENT_VARIANT_NAME;
  }

  return DEFAULT_CONTROL_VARIANT_NAME;
};

export const getFeatureFlagsForExperimentAssignment = (
  experimentName,
  variantName
) => {
  if (experimentName) {
    if (variantName === DEFAULT_CONTROL_VARIANT_NAME) {
      return [`${experimentName}${ENROLLMENT_FLAG_SUFFIX}`];
    }
    if (variantName === DEFAULT_TREATMENT_VARIANT_NAME) {
      return [`${experimentName}${ENROLLMENT_FLAG_SUFFIX}`, experimentName];
    }
  }
  return [];
};
