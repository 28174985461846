import { dispatch, receiveNativeMessage } from './messaging';
import { isNative } from './utils';

export const init = (store, initializers) => {
  if (!isNative()) {
    return;
  }

  initializers.forEach((fn) => {
    try {
      fn(store);
    } catch (err) {
      console.error(err);
    }
  });

  window.addEventListener('message', receiveNativeMessage, false);

  dispatch('appInitialized', {}, '1.2.1');
};
