import { withStyle } from 'shared/components/ihcl/styled';
import {
  StyledBody,
  StyledCloseIcon,
  StyledRoot,
  ToasterContainer,
} from 'baseui/toast';
import PropTypes from 'prop-types';
import React from 'react';

export * from 'baseui/toast';

export const employerOverrides = {
  Body: {
    style: {
      display: 'flex',
      alignItems: 'center',
      borderTopRightRadius: '8px',
      borderBottomRightRadius: '8px',
      borderBottomLeftRadius: '8px',
      borderTopLeftRadius: '8px',
      width: '480px',
    },
  },
  CloseIcon: {
    style: {
      marginTop: '-1px',
      marginBottom: '-1px',
      height: '20px',
      width: '20px',
    },
  },
};

export const employerInfoOverrides = {
  ...employerOverrides,
  Body: {
    style: ({ $theme }) => ({
      ...employerOverrides.Body.style,
      backgroundColor: $theme.colors.ebonyClay,
      color: $theme.colors.white,
    }),
  },
};

const TalentStyledRoot = withStyle(StyledRoot, ({ $theme }) => ({
  [$theme.mediaQuery.md]: {
    top: $theme.sizing.desktopNavbarHeight,
  },
}));
const TalentStyledBody = withStyle(StyledBody, ({ $theme }) => ({
  display: 'flex',
  backgroundColor: $theme.colors.bodyCopyGray,
  borderTopRightRadius: $theme.sizing.scale300,
  borderBottomRightRadius: $theme.sizing.scale300,
  borderBottomLeftRadius: $theme.sizing.scale300,
  borderTopLeftRadius: $theme.sizing.scale300,
  color: $theme.colors.white,
  width: `calc(100vw - ${$theme.sizing.scale900})`,
  maxWidth: '500px',
}));
const TalentStyledCloseIcon = withStyle(StyledCloseIcon, ({ $theme }) => ({
  marginTop: '1px',
  marginBottom: '-1px',
  height: $theme.sizing.scale700,
  width: $theme.sizing.scale700,
}));

// eslint-disable-next-line react/require-default-props
export const TalentToasterContainer = ({
  // eslint-disable-next-line react/require-default-props
  zIndex,
  // eslint-disable-next-line react/require-default-props
  autoHideDuration,
  ...props
}) => (
  <ToasterContainer
    {...props}
    autoHideDuration={autoHideDuration}
    overrides={{
      Root: { component: TalentStyledRoot, style: zIndex ? { zIndex } : {} },
      ToastBody: TalentStyledBody,
      ToastCloseIcon: TalentStyledCloseIcon,
    }}
  />
);
TalentToasterContainer.propTypes = {
  autoHideDuration: PropTypes.number,
  zIndex: PropTypes.number,
};
TalentToasterContainer.defaultProps = {
  autoHideDuration: 10000,
  zIndex: 10000, // Same as advice preferences z-index
};
