import { isFunction } from 'lodash';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';

import {
  registerImpressionId,
  trackTalentPageView,
  getPageName,
} from 'shared/helpers/tracking';

const withTracking = (WrappedComponent) => {
  const trackPage = (page) => {
    if (window.IH && window.IH.googleAnalyticsKey) {
      ReactGA.set({
        page,
      });
      ReactGA.pageview(page);
    }
  };

  const HOC = (props) => {
    const location = useLocation();

    useEffect(() => {
      trackPage(location.pathname);

      registerImpressionId();

      const pageName = getPageName(location.pathname);
      let properties = {};
      if (isFunction(WrappedComponent.getPageViewProperties)) {
        properties = WrappedComponent.getPageViewProperties(props);
      }
      trackTalentPageView(pageName, properties);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return <WrappedComponent {...props} />;
  };

  return HOC;
};

export default withTracking;
