export default (marketName) => {
  if (!marketName) {
    return null;
  }
  const [cities, states] = marketName.split(', ');
  if (!states) {
    return `${cities.split('-')} Area`;
  }
  if (states.split('-').length > 1) {
    const [city] = cities.split('-');
    const [state] = states?.split('-');
    return `${city}, ${state} Area`;
  }
  return marketName;
};
