import React from 'react';
import { LucideIcon } from 'shared/components/ihcl/lucideIcon';

import { Button } from 'shared/components/ihcl/button';
import { styled } from 'shared/components/ihcl/styled';
import { Text } from 'shared/components/ihcl/text';

import { getTimezone } from 'shared/helpers/timezones';
import { Stack } from 'shared/components/ihcl/patterns';
import { AppointmentDates } from './AppointmentDates';
import { AppointmentTimeOfDays } from './AppointmentTimeOfDays';
import { AppointmentTimes } from './AppointmentTimes';
import { BoldText } from './styledComponents';
import NoAvailableTimesNotice from './NoAvailableTimesNotice';

const DateTitleRowWrapper = styled('div', () => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
}));

const Timezone = styled(Text, ({ $theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: $theme.sizing.unit4,
}));

const AppointmentSelection = ({
  selectedDatetime,
  setSelectedDatetime,
  noTimesWorkAction,
  availableTimes = [],
}: {
  selectedDatetime: Date;
  setSelectedDatetime: Function;
  noTimesWorkAction: Function;
  availableTimes: Array<Date>;
}) => {
  const [date, setDate] = React.useState<Date>(null);
  const [timeOfDay, setTimeOfDay] = React.useState<string>(null);

  return (
    <Stack $flexDirection="column" $alignItems="center" $gapSizing="unit24">
      <DateTitleRowWrapper>
        <Text variant="LabelMedium">
          <BoldText>Select a time window</BoldText>
        </Text>
        <Timezone variant="LabelSmall" color="grayLabel">
          <LucideIcon name="Clock" size="unit14" /> {getTimezone()}
        </Timezone>
      </DateTitleRowWrapper>

      {availableTimes.length === 0 && (
        <NoAvailableTimesNotice showCallUs={noTimesWorkAction} />
      )}

      {availableTimes.length > 0 && (
        <>
          <AppointmentDates
            times={availableTimes}
            date={date}
            setDate={setDate}
          />

          <AppointmentTimeOfDays
            times={availableTimes}
            date={date}
            timeOfDay={timeOfDay}
            setTimeOfDay={setTimeOfDay}
          />

          <AppointmentTimes
            times={availableTimes}
            date={date}
            timeOfDay={timeOfDay}
            time={selectedDatetime}
            setTime={setSelectedDatetime}
          />

          <Button
            onClick={noTimesWorkAction}
            size="default"
            kind="minimal"
            color="alternate"
            fullWidth
          >
            <Text variant="LabelMedium" color="accent">
              None of these times work
            </Text>
          </Button>
        </>
      )}
    </Stack>
  );
};

export default AppointmentSelection;
