import React, { useEffect, useState } from 'react';
import { Map, Set } from 'immutable';
import { Helmet } from 'react-helmet';

import validateTalentProfile from 'registration/helpers/validateTalentProfile';
import {
  ActiveLicenses,
  NoLicense,
} from 'registration/components/new_pages/OnboardingLicenses';

import { Button } from 'shared/components/ihcl/button';
import { Checkbox, STYLE_TYPE } from 'shared/components/ihcl/checkbox';
import { trackClick } from 'shared/helpers/tracking';

import {
  BodyWrapper,
  ButtonWrapper,
  ErrorMessage,
  FooterWrapper,
  Header,
  Prompt,
} from './styledComponents';

import { useOnboardingStepContext } from '../providers/OnboardingStepProvider';
import { useOnboardingVariantContext } from '../providers/OnboardingVariantProvider';

const DEFAULT_HEADER = 'Where do you have an active nursing license?';

const validate = (localStepTalentProfile, talentProfileKeys) => {
  const keys = Set(talentProfileKeys);
  let requiredKeys = keys.delete('license_being_processed');
  const hasActiveStateLicenses =
    localStepTalentProfile.stateLicenses.filter(
      (stateLicense) => stateLicense.hasActiveLicense
    ).size > 0;
  if (!hasActiveStateLicenses) {
    requiredKeys = requiredKeys.add('hasActiveLicense');
  }
  if (localStepTalentProfile.hasActiveLicense === false) {
    requiredKeys = requiredKeys.delete('stateLicenses');
  }
  return validateTalentProfile(localStepTalentProfile, keys, requiredKeys);
};

type LicenseState = {
  abbrv: string;
  compact: boolean;
  hasActiveLicense?: boolean;
  hasCompactLicense?: boolean;
  name?: string;
  label: string;
  value: number;
};

type CompactActionProps = {
  children: React.ReactNode;
  state: LicenseState;
  licenseStates: LicenseState[];
  setLicenseStates: Function;
};

const CompactAction = ({
  children,
  state,
  licenseStates,
  setLicenseStates,
}: CompactActionProps) => (
  <Checkbox
    checked={state.hasCompactLicense}
    onChange={(e) => {
      setLicenseStates(
        licenseStates.map((licenseState) => {
          if (state.abbrv === licenseState.abbrv) {
            return {
              ...licenseState,
              hasCompactLicense: e.target.checked,
            };
          }
          return licenseState;
        })
      );
    }}
  >
    {children}
  </Checkbox>
);

type StateActionProps = {
  state: LicenseState;
  licenseStates: LicenseState[];
  setLicenseStates: Function;
};

const StateAction = ({
  state,
  licenseStates,
  setLicenseStates,
}: StateActionProps) => (
  <Checkbox
    ariaLabel={`${state.name}`}
    checked={state.hasActiveLicense}
    checkmarkType={STYLE_TYPE.toggle_round}
    onChange={(e) => {
      setLicenseStates(
        licenseStates.map((licenseState) => {
          if (state.abbrv === licenseState.abbrv) {
            return {
              ...licenseState,
              hasActiveLicense: e.target.checked,
            };
          }
          return licenseState;
        })
      );
    }}
  />
);

type OnboardingLicensesProps = {
  bodyText?: string;
  currentState: string;
  pageTalentProfileKeys: string[];
  stateOptions: Map<string, LicenseState>;
  title?: string;
};

const OnboardingLicenses = ({
  bodyText = null,
  currentState,
  pageTalentProfileKeys,
  stateOptions,
  title = DEFAULT_HEADER,
}: OnboardingLicensesProps) => {
  const {
    addTalentProfileKeys,
    maybeGoToNext,
    maybeGoToPrevious,
    isUpdatingProfile,
    localStepTalentProfile,
    setLocalStepTalentProfile,
  } = useOnboardingStepContext();
  const initialLicenseStates = Object.values(stateOptions.toJS())
    .filter((state: LicenseState) => state.abbrv === currentState)
    .map((state: LicenseState) => ({
      ...state,
      id: state.value,
      hasActiveLicense: false,
    }));
  const { currentStep, intent } = useOnboardingVariantContext();
  const [noActiveLicense, setNoActiveLicense] = useState(false);
  const [licenseStates, setLicenseStates] = useState(initialLicenseStates);
  const [errors, setErrors] = useState(Map());
  const setStateLicenses = (newLicenseStates) => {
    if (
      newLicenseStates.some((licenseState) => licenseState.hasActiveLicense) &&
      noActiveLicense
    ) {
      setNoActiveLicense(false);
    }
    setLicenseStates(newLicenseStates);

    const pendingActiveStateLicenses = newLicenseStates
      .filter((licenseState) => licenseState.hasActiveLicense)
      .map((licenseState) => ({
        state_id: licenseState.value,
        compact: !!licenseState.hasCompactLicense,
      }));
    setLocalStepTalentProfile((prevLocalStepTalentProfile) => ({
      ...prevLocalStepTalentProfile,
      stateLicenses: Set(pendingActiveStateLicenses),
      hasActiveLicense:
        pendingActiveStateLicenses.length > 0
          ? true
          : Boolean(prevLocalStepTalentProfile.hasActiveLicense),
    }));
    setErrors((prevErrors) =>
      prevErrors.delete('stateLicenses').delete('hasActiveLicense')
    );
  };

  useEffect(() => {
    const keys = pageTalentProfileKeys;
    addTalentProfileKeys(keys);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (Object.keys(localStepTalentProfile).length === 0) {
    return null;
  }

  const submit = () => {
    const newErrors = validate(localStepTalentProfile, pageTalentProfileKeys);
    if (newErrors.size > 0) {
      setErrors(newErrors);
      return;
    }

    trackClick('talent.onboarding.submit', {
      Intent: intent,
    });
    maybeGoToNext();
  };

  const displayBackButton = currentStep > 1;
  return (
    <>
      <Helmet>
        <body className="onboarding-page" />
      </Helmet>
      <BodyWrapper>
        <Prompt>
          <Header>{title}</Header>
          <p>{bodyText}</p>
          {errors.size > 0 && (
            <ErrorMessage>
              Please select at least one state where you have an active license,
              or indicate that you don&apos;t have an active license.
            </ErrorMessage>
          )}
        </Prompt>
        <ActiveLicenses
          CompactAction={CompactAction}
          StateAction={StateAction}
          licenseStates={licenseStates}
          setLicenseStates={setStateLicenses}
          stateOptions={Object.values(stateOptions.toJS()).map(
            (state: any) => ({
              ...state,
              id: state.id || state.value,
              disabled: licenseStates.some(
                (licenseState) => licenseState.value === state.value
              ),
            })
          )}
        />
        <NoLicense>
          <Checkbox
            checked={noActiveLicense}
            onChange={(e) => {
              if (e.target.checked) {
                setLocalStepTalentProfile((prevLocalStepTalentProfile) => ({
                  ...prevLocalStepTalentProfile,
                  hasActiveLicense: false,
                }));
              } else {
                setLocalStepTalentProfile((prevLocalStepTalentProfile) => ({
                  ...prevLocalStepTalentProfile,
                  hasActiveLicense: true,
                }));
              }
              setNoActiveLicense(e.target.checked);
              setErrors((prevErrors) =>
                prevErrors.delete('hasActiveLicense').delete('stateLicenses')
              );
            }}
          >
            I don&apos;t have an active license
          </Checkbox>
          <Checkbox
            checked={!!localStepTalentProfile.license_being_processed}
            onChange={(e) => {
              setLocalStepTalentProfile((prevLocalStepTalentProfile) => ({
                ...prevLocalStepTalentProfile,
                license_being_processed: e.target.checked,
              }));
            }}
          >
            My license is being processed
          </Checkbox>
        </NoLicense>
      </BodyWrapper>

      <FooterWrapper>
        {displayBackButton && (
          <ButtonWrapper>
            <Button
              type="button"
              kind="minimal"
              onClick={maybeGoToPrevious}
              style={{
                padding: '10px 24px',
              }}
            >
              Back
            </Button>
          </ButtonWrapper>
        )}

        {!displayBackButton && <span />}

        <ButtonWrapper>
          <Button
            className="submit"
            onClick={submit}
            disabled={isUpdatingProfile || (errors && errors.size > 0)}
            isLoading={isUpdatingProfile}
            style={{
              padding: '10px 24px',
            }}
          >
            Continue
          </Button>
        </ButtonWrapper>
      </FooterWrapper>
    </>
  );
};

export default OnboardingLicenses;
