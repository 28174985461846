import React from 'react';
import ReactGA from 'react-ga';

const withGATracker = (WrappedComponent) => {
  const trackPage = (page) => {
    if (window.IH && (window.IH as any).googleAnalyticsKey) {
      ReactGA.set({
        page,
      });
      ReactGA.pageview(page);
    }
  };

  const HOC = class extends React.Component<{ location: any }> {
    componentDidMount() {
      const page = this.props.location.pathname;
      trackPage(page);
    }

    componentDidUpdate(prevProps) {
      const prevPage = prevProps.location.pathname;
      const currentPage = this.props.location.pathname;

      if (currentPage !== prevPage) {
        trackPage(currentPage);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
};

export default withGATracker;
