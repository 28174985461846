import { useEffect, useState } from 'react';

export default function useScreeningOptions(): {
  isLoading: boolean;
  availableTimes: Array<Date>;
  hasFastTrackMatches: boolean;
} {
  const [isLoading, setIsLoading] = useState(false);
  const [availableTimes, setAvailableTimes] = useState<Array<Date>>([]);
  const [hasFastTrackMatches, setHasFastTrackMatches] =
    useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    fetch(`/appointment_window_screenings/scheduling_options`)
      .then((response) => response.json())
      .then((json) => {
        const slots = json.available_time_slots;
        setAvailableTimes(slots.map((unix) => new Date(unix * 1000)));
        setHasFastTrackMatches(json.has_fast_track_matches);
        setIsLoading(false);
      });
  }, []);

  return { isLoading, availableTimes, hasFastTrackMatches };
}
