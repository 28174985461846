import { Map } from 'immutable';

import { parsePhoneNumber } from 'shared/helpers/parsers';
import { validateEmail, validateRequired } from 'shared/helpers/validations';

import { validateWorkExperiences } from 'shared/components/WorkExperienceControl';

import parseFullName from './parseFullName';

const defaultAllRequiredKeys = [
  'currentLocation',
  'desiredLocations',
  'email',
  'educationLevel',
  'fullName',
  'jobSearchStatus',
  'password',
  'phone',
  'shifts',
  'startDateOption',
  'workExperienceMethod',
  'workPersonality',
  'whyChange',
];

function additionalErrorForKey(talentProfile, key) {
  let error = null;

  switch (key) {
    case 'desiredLocations': {
      const completeLocations = talentProfile.desiredLocations.filterNot((p) =>
        p.isEmpty()
      );

      if (completeLocations.count() === 0) {
        error = 'Required';
      }
      break;
    }

    case 'currentLocation': {
      const { currentLocation } = talentProfile;
      if (!currentLocation) {
        error = 'Required';
      } else if (currentLocation.isEmpty() || !currentLocation.isValid()) {
        error = 'Please enter a valid ZIP code';
      }
      break;
    }

    case 'email': {
      error = validateEmail(talentProfile.email);
      break;
    }

    case 'fullName': {
      if (parseFullName(talentProfile.fullName).length < 2) {
        error = 'Please enter both a first and last name';
      }
      break;
    }

    case 'nurseSpecialties': {
      const { nurseSpecialties } = talentProfile;
      let errors = Map();

      nurseSpecialties.forEach((ye, nsdId) => {
        const yearsExperience = Number.parseInt(ye, 10);

        if (Number.isNaN(yearsExperience)) {
          errors = errors.set(nsdId, 'Please enter a number of years.');
        } else if (yearsExperience >= 100) {
          errors = errors.set(nsdId, 'Number of years must be less than 100.');
        } else if (yearsExperience < 0) {
          errors = errors.set(nsdId, "Number of years can't be negative.");
        }
      });

      if (!errors.isEmpty()) {
        error = errors;
      }
      break;
    }

    case 'password': {
      if (talentProfile.password.length < 6) {
        error = 'Your password must be at least 6 characters.';
      }
      break;
    }

    case 'phone': {
      const parsedNumber = parsePhoneNumber(talentProfile.phone);
      if (!parsedNumber) {
        error =
          'Oops, this phone number appears to be invalid. If this ' +
          'is not a US number please make sure to start it with a + and ' +
          'your country code.';
      }
      break;
    }

    case 'resumeFormData': {
      const { workExperienceMethod, resumeFormData, resumeFilename } =
        talentProfile;

      if (
        workExperienceMethod === 'upload' &&
        !(resumeFormData || resumeFilename)
      ) {
        error = 'Please upload a resume file.';
      }
      break;
    }

    case 'workExperiences': {
      const { workExperienceMethod, workExperiences } = talentProfile;
      if (workExperienceMethod === 'manual_entry') {
        let { errors } = validateWorkExperiences(workExperiences);
        const workExperiencesEmpty =
          workExperiences.filterNot((we) => we.isEmpty()).size === 0;

        if (workExperiencesEmpty) {
          errors = errors.set(
            'base',
            'Please specify at least one work experience.'
          );
        }

        if (!errors.isEmpty()) {
          error = errors;
        }
      }
      break;
    }

    default:
      break;
  }

  return error;
}

/**
 * Validates the keys in the talent profile and returns an error object
 * with any error messages.
 */
function validateTalentProfile(
  talentProfile,
  keys,
  allRequiredKeys = defaultAllRequiredKeys
) {
  const requiredKeys = keys.filter((key) => allRequiredKeys.includes(key));

  let errors = validateRequired(talentProfile, ...requiredKeys);

  keys.forEach((key) => {
    if (!errors.get(key)) {
      const error = additionalErrorForKey(talentProfile, key);
      if (error) {
        errors = errors.set(key, error);
      }
    }
  });

  return errors;
}

export function validateTalentProfileField(key, value) {
  const validationMap = validateTalentProfile(
    {
      [key]: value,
    },
    [key]
  );

  return validationMap.get(key);
}

export default validateTalentProfile;
