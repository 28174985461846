import { displayError } from './errorActions';
import { doGet } from '../helpers/http';

export function getZipCodeInfo(zipCode) {
  return (dispatch) =>
    doGet(`/zip_codes/${zipCode}`).catch((error) => {
      const invalidZipCodeError =
        error.response && error.response.status === 404;
      if (!invalidZipCodeError) {
        const errMsg =
          'We encountered an error while looking up your ' +
          'code. Please check your internet connection, refresh ' +
          'your browser and try again.';
        dispatch(displayError(errMsg));
      }
      throw error;
    });
}
