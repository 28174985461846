import React from 'react';

import { Button } from 'shared/components/ihcl/button';
import { Text } from 'shared/components/ihcl/text';
import { ModalHeader } from 'shared/components/ihcl/modal';
import { trackClick } from 'shared/helpers/tracking';
import { WrappedSpinner } from 'shared/components/ihcl/spinner';
import { TalentProfile } from 'talent/types';
import AppointmentSelection from './AppointmentSelection';
import AppointmentConfirmation from './AppointmentConfirmation';
import {
  BoldText,
  ExpandingModalBody,
  FixedModalFooter,
  PrimaryCTAWrapper,
} from './styledComponents';
import Title from './Title';
import useScreeningOptions from './hooks/useScreeningOptions';

const DefaultCopy = (): JSX.Element => (
  <Text variant="ParagraphMedium">
    Please schedule a <BoldText>10-minute call</BoldText> with a nurse on our
    team to confirm your experience and preferences. This isn&apos;t an
    interview - so no prep needed. We will call in the{' '}
    <BoldText>30 min window.</BoldText>
  </Text>
);

const FastTrackCopy = (): JSX.Element => (
  <Text variant="ParagraphMedium">
    We see <BoldText>you already have job matches in our system</BoldText>,
    let&apos;s <BoldText>set up a 10-minute call</BoldText> to talk through
    these matches and help you find your next permanent nursing position! We
    will call within the 30 min window.
  </Text>
);

const InterviewRequestCopy = (): JSX.Element => (
  <Text variant="ParagraphMedium">
    Schedule a <BoldText>10 minute call</BoldText> to get more information on
    your interview request. We will call within the{' '}
    <BoldText>30 minute window</BoldText>.
  </Text>
);

function NewAppointment({
  closeModal,
  noTimesWorkAction,
  talentProfile,
  isForInterviewRequest,
  title,
}: {
  closeModal: Function;
  noTimesWorkAction: Function;
  talentProfile: TalentProfile;
  isForInterviewRequest: boolean;
  title: string;
}) {
  const [selectedDatetime, setSelectedDatetime] = React.useState(null);
  const [isConfirming, setIsConfirming] = React.useState(false);
  const { isLoading, availableTimes, hasFastTrackMatches } =
    useScreeningOptions();
  const hasAvailableTimes = !isLoading && availableTimes.length > 0;

  const handleConfirm = () => {
    setIsConfirming(true);
    trackClick('talent.screening.new.confirm');
  };

  const handleGoBack = () => {
    setIsConfirming(false);
    trackClick('talent.screening.new.cancel_confirm');
  };

  let instructionCopy: JSX.Element;
  if (isForInterviewRequest) {
    instructionCopy = <InterviewRequestCopy />;
  } else if (hasFastTrackMatches) {
    instructionCopy = <FastTrackCopy />;
  } else {
    instructionCopy = <DefaultCopy />;
  }

  return (
    <>
      <ModalHeader>
        <Title onClick={() => closeModal('talent.screening.new.close')}>
          {title}
        </Title>
      </ModalHeader>
      <ExpandingModalBody>
        {isLoading && <WrappedSpinner spinnerSize="large" />}
        {!isLoading && instructionCopy}
        {!isLoading && !isConfirming && (
          <AppointmentSelection
            selectedDatetime={selectedDatetime}
            setSelectedDatetime={setSelectedDatetime}
            noTimesWorkAction={noTimesWorkAction}
            availableTimes={availableTimes}
          />
        )}
        {isConfirming && (
          <AppointmentConfirmation
            date={selectedDatetime}
            talentProfile={talentProfile}
            closeModal={closeModal}
            goBack={handleGoBack}
          />
        )}
      </ExpandingModalBody>

      {!isLoading && !hasAvailableTimes && (
        <FixedModalFooter>
          <Button
            onClick={() => closeModal('talent.screening.new.no_times')}
            size="default"
            color="alternate"
            kind="tertiary"
            fullWidth
          >
            Ok, got it
          </Button>
        </FixedModalFooter>
      )}
      {!isConfirming && hasAvailableTimes && (
        <FixedModalFooter>
          <PrimaryCTAWrapper>
            <Button
              onClick={handleConfirm}
              size="default"
              color="alternate"
              kind="primary"
              fullWidth
              disabled={selectedDatetime === null}
            >
              Next: Confirmation
            </Button>
          </PrimaryCTAWrapper>
        </FixedModalFooter>
      )}
    </>
  );
}

export default NewAppointment;
