import parsePhoneNumberUtil from 'libphonenumber-js';

/**
 * Parses a phone number into E.164 format by default, or another format if
 * one is passed.
 *
 * @param {string} phoneNumber - The number to parse
 * @param {Object} format - The PhoneNumberFormat to parse the return value with
 * @return {string} The formatted phone number or null if parsing fails
 */
export const parsePhoneNumber = (
  phoneNumber: string,
  format: string = 'E.164'
): string => {
  let phoneObj = null;
  try {
    phoneObj = parsePhoneNumberUtil(phoneNumber, 'US');
  } catch (error) {
    // If the number fails parsing then we just return null below so
    // we can ignore this.
  }

  if (phoneObj && phoneObj.isValid()) {
    return phoneObj.format(format);
  }
  return null;
};

export const getLocationFromCityObj = (cityObj) => {
  if (!cityObj || !cityObj.city || !cityObj.state) {
    return null;
  }
  const label = `${cityObj.city}, ${cityObj.state}`;
  return {
    ...cityObj,
    id: `${label}, United States`,
    label,
  };
};
