import React, { useState } from 'react';
import {
  ROLE as ModalRole,
  SIZE as ModalSize,
  TalentModal,
} from 'shared/components/ihcl/modal';
import { TalentProfile } from 'talent/types';

import { useBreakpointContext } from 'shared/providers/BreakpointProvider';
import { trackClick } from 'shared/helpers/tracking';
import AppointmentSummary from './AppointmentSummary';
import NewAppointment from './NewAppointment';
import RescheduleAppointment from './RescheduleAppointment';
import CallUsModal from './CallUsModal';

export const MODAL_PATH = '#introCall';

const AppointmentModal = ({
  isOpen,
  talentProfile,
  isForInterviewRequest = false,
  title = 'Kickstart with a 10 min intro call',
  closeModal,
}: {
  isOpen: boolean;
  talentProfile: TalentProfile;
  isForInterviewRequest?: boolean;
  title?: string;
  closeModal: () => void;
}): JSX.Element => {
  const [isCallUsOpen, setIsCallUsOpen] = useState(false);
  const [isRescheduling, setIsRescheduling] = useState(false);

  const clearAndCloseModal = (trigger: string) => {
    trackClick(trigger);
    setIsRescheduling(false);
    setIsCallUsOpen(false);
    closeModal();
  };

  const handleNoTimesWork = () => {
    trackClick('talent.screening.modal.call_us');
    setIsCallUsOpen(true);
  };

  const handleReschedule = () => {
    trackClick('talent.screening.click.reschedule');
    setIsRescheduling(true);
  };

  const { screening } = talentProfile;

  const breakpoint = useBreakpointContext();
  const size = breakpoint === 'small' ? ModalSize.full : ModalSize.default;

  return (
    <>
      <TalentModal
        useTalentDialog={false}
        closeable
        isOpen={isOpen}
        animate
        autoFocus
        size={size}
        role={ModalRole.dialog}
        overrides={{
          Dialog: {
            style: {
              marginTop: 0,
              marginRight: 0,
              marginBottom: 0,
              marginLeft: 0,
              display: 'flex',
              flexDirection: 'column',
            },
          },
          Close: {
            style: {
              display: 'none',
            },
          },
        }}
      >
        {screening && !isRescheduling && (
          <AppointmentSummary
            screening={screening}
            handleReschedule={handleReschedule}
            closeModal={clearAndCloseModal}
          />
        )}
        {screening && isRescheduling && (
          <RescheduleAppointment
            closeModal={clearAndCloseModal}
            noTimesWorkAction={handleNoTimesWork}
            talentProfile={talentProfile}
          />
        )}
        {!screening && (
          <NewAppointment
            closeModal={clearAndCloseModal}
            noTimesWorkAction={handleNoTimesWork}
            talentProfile={talentProfile}
            isForInterviewRequest={isForInterviewRequest}
            title={title}
          />
        )}
      </TalentModal>
      <CallUsModal isOpen={isCallUsOpen} onClose={clearAndCloseModal} />
    </>
  );
};

export default AppointmentModal;
