import { List, Map, Set } from 'immutable';
import lodash from 'lodash';
import { snakeCase } from 'lodash/string';
import find from 'lodash/find';
import moment from 'moment';

import { Place } from 'shared/records/Place';
import {
  WorkExperience,
  parseServerWorkExperience,
} from 'shared/components/WorkExperienceControl';
import { shiftOptions } from 'shared/constants/selectOptions';
import {
  getLocationFromCityObj,
  parsePhoneNumber,
} from 'shared/helpers/parsers';

import parseFullName from './parseFullName';

const dateFormat = 'YYYY-MM-DD';

export const locationFromGooglePlace = (place) => {
  const location = {};
  const city = place.structured_formatting.main_text;
  const state = place.structured_formatting.secondary_text.replace(', USA', '');
  location.city = city;
  location.id = place.description.replace(', USA', ', United States');
  location.label = `${city}, ${state}`;
  location.state = state;
  return location;
};

const appendLabelAndValue = (option) => ({
  label: option.display_name,
  value: option.id.toString(),
  ...option,
});

const getStartDateFromOption = (startDateOption) => {
  switch (startDateOption) {
    case 'immediately':
      return moment().format(dateFormat);
    case 'few_weeks':
      return moment().add(3, 'weeks').format(dateFormat);
    case 'few_months':
      return moment().add(3, 'months').format(dateFormat);
    default:
      return null;
  }
};

const trueFalseToYesNo = (trueFalseVal) => {
  if (trueFalseVal === true) {
    return 'yes';
  }
  if (trueFalseVal === false) {
    return 'no';
  }

  return trueFalseVal;
};

const yesNoToTrueFalse = (yesNoVal) => {
  if (yesNoVal === 'yes') {
    return true;
  }
  if (yesNoVal === 'no') {
    return false;
  }

  return yesNoVal;
};

export const augmentOptions = (optionsFromServer, optionsKeys) => {
  const state = {};

  optionsKeys.forEach((key) => {
    switch (key) {
      case 'care_settings': {
        state.care_settings =
          optionsFromServer.care_settings.map(appendLabelAndValue);
        break;
      }

      default:
        state[key] = optionsFromServer[key];
        break;
    }
  });

  return state;
};

export const augmentState = (
  talentProfileFromServer,
  onboardingOptions,
  stateKeys
) => {
  const state = {};

  stateKeys.forEach((key) => {
    switch (key) {
      case 'acute_care_experience': {
        state.acute_care_experience = trueFalseToYesNo(
          talentProfileFromServer.acute_care_experience
        );
        break;
      }

      case 'care_settings': {
        state.care_settings =
          talentProfileFromServer.care_settings.map(appendLabelAndValue);
        break;
      }

      case 'clinical_care_experience': {
        state.clinical_care_experience = trueFalseToYesNo(
          talentProfileFromServer.clinical_care_experience
        );
        break;
      }

      case 'certifications': {
        if (!talentProfileFromServer.certifications) {
          state.certifications = [];
        } else {
          state.certifications =
            talentProfileFromServer.certifications.split(', ');
        }
        break;
      }

      case 'other_locations': {
        state.desiredLocations = talentProfileFromServer.other_locations.map(
          (location) => {
            const label = `${location.city}, ${location.state}`;
            const id = `${label}, United States`;
            const zipCode = location.zip_code;
            return {
              id,
              label,
              zipCode,
              ...location,
            };
          }
        );
        break;
      }

      case 'state_licenses': {
        state.state_licenses = talentProfileFromServer.state_licenses.map(
          (stateLicense) => {
            const stateObj = onboardingOptions.states.find(
              (stateOption) => stateLicense.state_id === stateOption.id
            );
            return {
              ...stateObj,
              hasActiveLicense: true,
              hasCompactLicense: stateObj.compact
                ? !!stateLicense.hasCompactLicense
                : undefined,
            };
          }
        );
        break;
      }

      default:
        state[key] = talentProfileFromServer[key];
        break;
    }
  });

  return state;
};

export const convertToState = (talentProfileFromServer, stateKeys) => {
  const state = {};

  stateKeys.forEach((key) => {
    switch (key) {
      case 'current_zip_code': {
        const {
          current_city: city,
          current_state: stateCode,
          current_zip_code: zipCode,
        } = talentProfileFromServer;

        if (!city || !state) {
          break;
        }
        state.currentLocation = getLocationFromCityObj({
          city,
          state: stateCode,
          zipCode,
        });
        break;
      }

      case 'currentLocation': {
        const {
          current_city: currentCity,
          current_state: currentState,
          current_zip_code: currentZipCode,
        } = talentProfileFromServer;

        state.currentLocation = new Place({
          city: currentCity,
          state: currentState,
          zipCode: currentZipCode,
        });
        break;
      }

      case 'desiredLocations': {
        const desiredLocations = List().withMutations((locList) => {
          talentProfileFromServer.other_locations.forEach((ol) => {
            const place = new Place({
              city: ol.city,
              state: ol.state,
              zipCode: ol.zip_code,
            });
            locList.push(place);
          });
        });

        state.desiredLocations = desiredLocations;
        break;
      }

      case 'nurseSpecialties': {
        const nurseSpecialties = Map().withMutations((specialtiesMap) => {
          talentProfileFromServer.nurse_specialties.forEach((ns) => {
            specialtiesMap.set(
              ns.nurse_specialty_description_id,
              ns.years_experience
            );
          });
        });

        state.nurseSpecialties = nurseSpecialties;
        break;
      }

      case 'shifts': {
        const possibleShifts = shiftOptions.map(
          (shiftOption) => shiftOption.value
        );

        const shifts = Set().withMutations((shiftsSet) => {
          possibleShifts.forEach((shift) => {
            if (talentProfileFromServer[shift]) {
              shiftsSet.add(shift);
            }
          });
        });

        state.shifts = shifts;
        break;
      }

      case 'careSettings': {
        state.careSettings = List(talentProfileFromServer.care_settings);
        break;
      }

      case 'startDateOption': {
        const { start_date: startDate, start_date_option: startDateOption } =
          talentProfileFromServer;

        let stateOption;
        if (startDateOption) {
          stateOption = startDateOption;
        } else if (startDate) {
          const mDate = moment(startDate);
          const immediately = moment();
          const fewWeeks = moment().add(3, 'weeks');

          if (moment.max(mDate, immediately) === immediately) {
            stateOption = 'immediately';
          } else if (moment.max(mDate, fewWeeks) === fewWeeks) {
            stateOption = 'few_weeks';
          } else {
            stateOption = 'few_months';
          }
        } else {
          stateOption = '';
        }
        state.startDateOption = stateOption;
        break;
      }

      case 'stateLicenses': {
        const stateLicenses = Set().withMutations((stateLicenseSet) => {
          const serverLicenses = talentProfileFromServer.state_licenses || [];
          serverLicenses.forEach((sl) => {
            stateLicenseSet.add(sl.state_id);
          });
        });

        state.stateLicenses = stateLicenses;
        break;
      }

      case 'workExperiences': {
        const workExperiences = List().withMutations((workExperienceList) => {
          talentProfileFromServer.work_experiences.forEach((we) => {
            workExperienceList.push(parseServerWorkExperience(we));
          });
        });

        if (workExperiences.isEmpty()) {
          state.workExperiences = List([new WorkExperience()]);
        } else {
          state.workExperiences = workExperiences;
        }

        break;
      }

      case 'phone': {
        const phone = talentProfileFromServer.phone || {};

        state.phone = phone.tel_uri || '';
        break;
      }

      default: {
        const value = talentProfileFromServer[snakeCase(key)];
        state[key] = value == null ? '' : value;
        break;
      }
    }
  });

  return state;
};

export const convertStateToAttributes = (
  stateTalentProfile,
  stateKeys,
  talentProfileFromServer = null
) => {
  const talentProfileAttributes = {};

  stateKeys.forEach((key) => {
    switch (key) {
      case 'acute_care_experience': {
        talentProfileAttributes.acute_care_experience = yesNoToTrueFalse(
          stateTalentProfile.acute_care_experience
        );
        break;
      }

      case 'certifications': {
        if (Array.isArray(stateTalentProfile.certifications)) {
          talentProfileAttributes.certifications =
            stateTalentProfile.certifications.join(', ');
        } else {
          talentProfileAttributes.certifications =
            stateTalentProfile.certifications;
        }
        break;
      }

      case 'clinical_care_experience': {
        talentProfileAttributes.clinical_care_experience = yesNoToTrueFalse(
          stateTalentProfile.clinical_care_experience
        );
        break;
      }

      case 'currentLocation': {
        /* eslint-disable @typescript-eslint/naming-convention */
        const { city, state, zipCode, zip_code } =
          stateTalentProfile.currentLocation;

        talentProfileAttributes.current_city = city;
        talentProfileAttributes.current_state = state;
        talentProfileAttributes.current_zip_code = zipCode || zip_code;
        break;
      }

      case 'desiredLocations': {
        if (!stateTalentProfile.desiredLocations) break;
        let otherLocations;
        if (
          typeof stateTalentProfile.desiredLocations.filterNot === 'function'
        ) {
          otherLocations = stateTalentProfile.desiredLocations
            .filterNot((place) => place.isEmpty())
            .map((place) => ({
              city: place.city,
              state: place.state,
              zip_code: place.zipCode,
            }))
            .toArray();
        } else {
          const expandedLocations = [];
          otherLocations = stateTalentProfile.desiredLocations
            .map((place) => {
              if (place.city) {
                return {
                  city: place.city,
                  state: place.state,
                  zip_code: place.zipCode,
                };
              }

              if (place.hospitalLocations) {
                return place.hospitalLocations
                  .split(';')
                  .map((hospitalLocation) => {
                    const [city, state, zipCode] = hospitalLocation.split(',');
                    return { city, state, zip_code: zipCode };
                  });
              }
              return null;
            })
            .filter((place) => place !== null)
            .flatMap((place) => place);

          if (expandedLocations.length) {
            otherLocations = otherLocations.concat(expandedLocations);
          }
        }

        talentProfileAttributes.other_locations_attributes = otherLocations;
        break;
      }

      case 'email': {
        const userAttributes = talentProfileAttributes.user_attributes || {};
        userAttributes.email = stateTalentProfile.email;
        talentProfileAttributes.user_attributes = userAttributes;
        break;
      }

      case 'fullName': {
        const [first, last] = parseFullName(stateTalentProfile.fullName);
        talentProfileAttributes.first_name = first;
        talentProfileAttributes.last_name = last;
        break;
      }

      case 'nurse_specialties': {
        const { nurse_specialties } = stateTalentProfile;
        talentProfileAttributes.nurse_specialties_attributes =
          nurse_specialties;
        break;
      }

      case 'nurseSpecialties': {
        if (!talentProfileFromServer) {
          const errorMsg =
            'convertStateToAttributes -- nurseSpecialties ' +
            'requires additional argument: talentProfileFromServer';
          throw new Error(errorMsg);
        }

        const { nurseSpecialties } = stateTalentProfile;
        const nurseSpecialtiesFromServer =
          talentProfileFromServer.nurse_specialties || [];

        const specialtiesAttributes = [];
        nurseSpecialtiesFromServer.forEach((specialtyFromServer) => {
          const serverSdId = specialtyFromServer.nurse_specialty_description_id;

          if (nurseSpecialties.has(serverSdId)) {
            const yearsExperience = nurseSpecialties.get(serverSdId);
            specialtiesAttributes.push({
              ...specialtyFromServer,
              years_experience: yearsExperience,
            });
          } else {
            specialtiesAttributes.push({
              ...specialtyFromServer,
              _destroy: true,
            });
          }
        });

        nurseSpecialties.forEach((yrsExp, nurseSpecialtyDescriptionId) => {
          const existingSpecialty = find(nurseSpecialtiesFromServer, [
            'nurse_specialty_description_id',
            nurseSpecialtyDescriptionId,
          ]);
          if (!existingSpecialty) {
            specialtiesAttributes.push({
              nurse_specialty_description_id: nurseSpecialtyDescriptionId,
              years_experience: yrsExp,
            });
          }
        });

        talentProfileAttributes.nurse_specialties_attributes =
          specialtiesAttributes;
        break;
      }

      case 'password': {
        const userAttributes = talentProfileAttributes.user_attributes || {};
        userAttributes.password = stateTalentProfile.password;
        userAttributes.password_confirmation = stateTalentProfile.password;
        talentProfileAttributes.user_attributes = userAttributes;
        break;
      }

      case 'shifts': {
        const possibleShifts = shiftOptions.map(
          (shiftOption) => shiftOption.value
        );
        possibleShifts.forEach((shift) => {
          talentProfileAttributes[shift] = stateTalentProfile.shifts.has(shift);
        });
        break;
      }

      case 'careSettings': {
        const { careSettings } = stateTalentProfile;

        talentProfileAttributes.care_settings = careSettings.toArray();
        talentProfileAttributes.care_setting_ids = careSettings
          .map((cs) => cs.id)
          .toArray();

        break;
      }

      case 'care_settings': {
        /* eslint-disable @typescript-eslint/naming-convention */
        const { care_settings } = stateTalentProfile;

        talentProfileAttributes.care_setting_ids = care_settings.map(
          (cs) => cs.id
        );

        break;
      }

      case 'startDateOption': {
        const { startDateOption } = stateTalentProfile;
        const startDate = getStartDateFromOption(startDateOption);

        talentProfileAttributes.start_date_option = startDateOption;
        talentProfileAttributes.start_date = startDate;
        break;
      }

      case 'start_date': {
        const { start_date: startDate } = stateTalentProfile;
        if (startDate instanceof Date) {
          talentProfileAttributes.start_date =
            moment(startDate).format(dateFormat);
        } else {
          talentProfileAttributes.start_date = startDate;
        }
        break;
      }

      case 'start_date_option': {
        const { start_date: startDate, start_date_option: startDateOption } =
          stateTalentProfile;
        talentProfileAttributes.start_date_option = startDateOption;
        if (startDate && startDateOption === 'specific_date') {
          talentProfileAttributes.start_date_option = null;
        } else {
          talentProfileAttributes.start_date =
            getStartDateFromOption(startDateOption);
        }
        break;
      }

      case 'workExperiences': {
        if (stateTalentProfile.workExperienceMethod === 'manual_entry') {
          const { workExperiences } = stateTalentProfile;

          const workExperienceAttributes = workExperiences
            .filterNot((we) => we.isEmpty())
            .map((we) => ({
              id: we.id,
              _destroy: we._destroy,
              company_name: we.companyName,
              location: we.location,
              job_title: we.jobTitle,
              start_date: we.getStartDate(),
              end_date: we.getEndDate(),
              duties: we.duties,
              work_experience_specialties_attributes:
                we.workExperienceSpecialties.toJS().map((wes) => ({
                  id: wes.id,
                  _destroy: wes._destroy,
                  nurse_specialty_description_id:
                    wes.nurse_specialty_description_id,
                  work_experience_specialty_skills_attributes:
                    wes.work_experience_specialty_skills.map((wess) => ({
                      id: wess.id,
                      _destroy: wess._destroy,
                      nurse_specialty_skill_description_id:
                        wess.nurse_specialty_skill_description_id,
                    })),
                })),
            }))
            .toArray();

          talentProfileAttributes.work_experiences_attributes =
            workExperienceAttributes;
        }
        break;
      }

      case 'resumeFilename':
      case 'resumeFormData':
        if (stateTalentProfile.workExperienceMethod === 'upload') {
          talentProfileAttributes[snakeCase(key)] = stateTalentProfile[key];
        }
        break;

      case 'stateLicenses': {
        talentProfileAttributes.state_licenses_attributes =
          stateTalentProfile.stateLicenses
            .map((state) => {
              if (['string', 'number'].includes(typeof state)) {
                return { state_id: state };
              }
              return state;
            })
            .toArray();
        break;
      }

      case 'phone': {
        const { phone, disallow_duplicate_phone } = stateTalentProfile;
        if (phone && phone.text) {
          talentProfileAttributes.phone = parsePhoneNumber(phone.text);
        } else if (typeof phone !== 'undefined') {
          talentProfileAttributes.phone = parsePhoneNumber(phone);
        }
        if (disallow_duplicate_phone) {
          talentProfileAttributes.disallow_duplicate_phone =
            disallow_duplicate_phone;
        }

        break;
      }

      case 'license_issue_date': {
        const { license_issue_date: licenseIssueDate } = stateTalentProfile;
        if (licenseIssueDate instanceof Date) {
          talentProfileAttributes.license_issue_date =
            moment(licenseIssueDate).format(dateFormat);
        } else {
          talentProfileAttributes.license_issue_date = licenseIssueDate;
        }
        break;
      }

      case 'graduation_date': {
        const { graduation_date: graduationDate } = stateTalentProfile;
        if (graduationDate instanceof Date) {
          talentProfileAttributes.graduation_date =
            moment(graduationDate).format(dateFormat);
        } else {
          talentProfileAttributes.graduation_date = graduationDate;
        }
        break;
      }

      default:
        talentProfileAttributes[snakeCase(key)] = stateTalentProfile[key];
        break;
    }
  });

  return talentProfileAttributes;
};

export const getAugmentedOnboardingData = (
  onboardingData,
  talentProfile,
  onboardingOptions
) => {
  const profileKeysToConvert = Object.keys(talentProfile).filter(
    (key) => !['phone'].includes(key)
  );
  profileKeysToConvert.push('currentLocation');
  profileKeysToConvert.push('nurseSpecialties');
  profileKeysToConvert.push('workExperiences');
  return {
    ...onboardingData,
    ...{
      ...augmentState(
        convertToState(talentProfile, profileKeysToConvert),
        onboardingOptions,
        profileKeysToConvert
      ),
      phone: talentProfile.phone,
    },
  };
};

export const getTalentAttribute = (
  attributeCamelCase,
  localTalentProfile,
  talentProfileFromServer,
  favorValuesFromServer = true
) => {
  const attributeSnakeCase = snakeCase(attributeCamelCase);
  const hasLocalProfile =
    localTalentProfile && Object.keys(localTalentProfile).length;
  const hasServerProfile =
    talentProfileFromServer && Object.keys(talentProfileFromServer).length;
  const localAttrValue =
    hasLocalProfile && localTalentProfile[attributeCamelCase];
  const serverAttrValue =
    hasServerProfile && talentProfileFromServer[attributeSnakeCase];

  if (favorValuesFromServer) {
    return serverAttrValue || localAttrValue;
  }

  return localAttrValue || serverAttrValue;
};

export const talentProfileAttributesWouldUpdateServer = (
  talentProfileAttributes,
  talentProfileFromServer
) =>
  Object.entries(talentProfileAttributes).some((entry) => {
    const [key, value] = entry;
    let localState = value;
    let serverState = talentProfileFromServer[key];
    switch (key) {
      case 'nurse_specialties_attributes': {
        localState = value.map((ns) => ({
          nurse_specialty_description_id: ns.nurse_specialty_description_id,
          years_experience: ns.years_experience,
        }));
        serverState = talentProfileFromServer.nurse_specialties.map((ns) => ({
          nurse_specialty_description_id: ns.nurse_specialty_description_id,
          years_experience: ns.years_experience,
        }));
        break;
      }
      case 'state_licenses_attributes': {
        localState = value.map((sl) => sl.state_id);
        serverState = talentProfileFromServer.state_licenses.map(
          (sl) => sl.state_id
        );
        break;
      }
      default:
    }

    return !lodash.isEqual(localState, serverState);
  });
