import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';

import { Button } from 'shared/components/ihcl/button';
import { StatefulInput } from 'shared/components/ihcl/input';

import validateTalentProfile, {
  validateTalentProfileField,
} from '../../../helpers/validateTalentProfile';
import { useOnboardingStepContext } from '../providers/OnboardingStepProvider';
import { useOnboardingVariantContext } from '../providers/OnboardingVariantProvider';

import {
  getTitles,
  SubmitFooterWrapper,
  Subtitle,
} from './OnboardingFormEmail';

const OnboardingFormPassword = () => {
  const {
    addTalentProfileKeys,
    isUpdatingProfile,
    maybeGoToNext,
    localStepTalentProfile,
    setLocalStepTalentProfile,
  } = useOnboardingStepContext();
  const { intent } = useOnboardingVariantContext();
  const formRef = useRef(null);
  const {
    clearErrors,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: 'onTouched',
    defaultValues: localStepTalentProfile,
  });
  const formErrors = {
    ...errors,
    ...errors.user,
  };

  useEffect(() => {
    addTalentProfileKeys(['password']);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signUp = async (formData) => {
    const validationErrors = validateTalentProfile(
      formData,
      Object.keys(formData)
    );
    if (validationErrors.size === 0) {
      maybeGoToNext();
    }
  };
  const changeField = (evt, fieldName, fieldProps) => {
    if (formErrors[fieldName]) {
      clearErrors(`user.${fieldName}`);
    }
    setLocalStepTalentProfile((prevLocalStepTalentProfile) => ({
      ...prevLocalStepTalentProfile,
      [fieldName]: evt.target.value,
    }));
    fieldProps.onChange(evt);
  };
  const passwordProps = register('password', {
    validate: (value) => validateTalentProfileField('password', value),
  });

  const [title, subtitle] = getTitles(intent);
  return (
    <>
      <div>
        <h1 className="title">{title}</h1>
        <Subtitle>{subtitle}</Subtitle>
        <form
          action="#"
          id="passwordForm"
          onSubmit={(evt) => {
            evt.preventDefault();
            handleSubmit(signUp)();
          }}
          ref={formRef}
        >
          <StatefulInput
            caption={formErrors.password && formErrors.password.message}
            collapseCaptionPadding
            initialState={{ value: localStepTalentProfile.password }}
            label="Password"
            type="password"
            inputMode="password"
            {...passwordProps}
            onChange={(evt) => changeField(evt, 'password', passwordProps)}
            error={!!formErrors.password}
          />
        </form>
      </div>
      <SubmitFooterWrapper>
        <span />
        <Button
          className="submit"
          form="passwordForm"
          disabled={isUpdatingProfile || Boolean(formErrors.password)}
          style={{
            marginTop: '15px',
            padding: '10px 24px',
          }}
        >
          Continue
        </Button>
      </SubmitFooterWrapper>
    </>
  );
};

OnboardingFormPassword.propTypes = {};

export default OnboardingFormPassword;
