import { styled } from 'shared/components/ihcl/styled';
import { Map, Set } from 'immutable';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import validateTalentProfile from 'registration/helpers/validateTalentProfile';
import { getStateOptions } from 'registration/selectors';
import { Button } from 'shared/components/ihcl/button';
import { Input } from 'shared/components/ihcl/input';
import { WrappedLucideIcon } from 'shared/components/ihcl/lucideIcon';
import { Select } from 'shared/components/ihcl/select';
import { InlineSpinner } from 'shared/components/ihcl/spinner';
import ZipCodeInput from 'shared/components/ZipCodeInput';
import { educationLevels } from 'shared/constants/selectOptions';
import { isNative } from 'shared/helpers/native';
import { trackClick } from 'shared/helpers/tracking';
import { getNurseSpecialtyOptions } from 'shared/selectors';

import { useOnboardingStepContext } from '../providers/OnboardingStepProvider';
import { useOnboardingVariantContext } from '../providers/OnboardingVariantProvider';

import { BodyWrapper, ButtonWrapper, Header } from './styledComponents';

const PHONE_ERROR_KEYS = ['phone', 'talent_profile.phone'];

const FooterWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
});

const InputWrapper = styled('div', {
  marginBottom: '20px',
  textAlign: 'left',
});

const Prompt = styled('div', ({ $intent }) => ({
  marginTop: '15px',
  marginBottom: $intent === 'resume' ? '42px' : '12px',
}));

const SignInPromptWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

const SignInPrompt = () => {
  const signInUrl = '/users/sign_in';
  return (
    <SignInPromptWrapper>
      Already have an account?
      <a href={signInUrl}>Sign In</a>
    </SignInPromptWrapper>
  );
};

const getTitles = (intent) => {
  let header;
  let prompt;
  switch (intent) {
    case 'education':
      header = 'Continuing education';
      prompt = 'Get access to 100% free continuing education & much more.';
      break;

    case 'advice':
    case 'network':
      header = 'Real advice for nurses';
      prompt =
        'Get advice and share your own expertise with over 500k nurses! ' +
        'Join the community.';
      break;

    case 'resume':
      header = 'Resume contact information';
      prompt = 'This information will be part of your generated resume.';
      break;

    case 'salary':
      header = 'Nurse salary estimator';
      prompt = 'Get custom salary estimates and much more.';
      break;

    case 'mental_health':
      header = 'Nurse mental health';
      prompt =
        'Create an account or sign in to get access to all of our 100% ' +
        'free tools & services exclusively for nurses.';
      break;

    default:
      console.error(`Unknown intent: ${intent}`);
  }
  return [header, prompt];
};

const validate = (
  educationLevel,
  localStepTalentProfile,
  talentProfileKeys
) => {
  let keys = Set(talentProfileKeys);

  if (educationLevel === 'student') {
    keys = keys.add('school');
  } else if (educationLevel) {
    keys = keys.union(['nurseSpecialties', 'stateLicenses']);
  }

  // Special case zip code and phone errors
  const requiredKeys = keys.subtract(['currentLocation', 'phone']);

  return validateTalentProfile(localStepTalentProfile, keys, requiredKeys);
};

// eslint-disable-next-line react/require-default-props
const OnboardingTalentDetailsForm = ({
  // eslint-disable-next-line react/require-default-props
  bodyText,
  // eslint-disable-next-line react/require-default-props
  includeSignInLink,
  nurseSpecialtyOptions,
  serverErrors,
  stateOptions,
  pageTalentProfileKeys,
  // eslint-disable-next-line react/require-default-props
  title,
}) => {
  const {
    addTalentProfileKeys,
    maybeGoToNext,
    maybeGoToPrevious,
    isUpdatingProfile,
    localStepTalentProfile,
    setLocalStepTalentProfile,
  } = useOnboardingStepContext();
  const { currentStep, intent } = useOnboardingVariantContext();
  const [errors, setErrors] = useState(new Map());
  const [locationHasChanged, setLocationHasChanged] = useState(false);
  const [locationIsLoading, setLocationIsLoading] = useState(false);

  useEffect(() => {
    let keys = pageTalentProfileKeys;
    if (pageTalentProfileKeys.includes('educationLevel')) {
      keys = keys.concat(['nurseSpecialties', 'stateLicenses', 'school']);
    }
    addTalentProfileKeys(keys);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setErrors((prevErrors) => {
      let newErrorMap = prevErrors;

      PHONE_ERROR_KEYS.forEach((phoneErrorKey) => {
        if (Object.keys(serverErrors).includes(phoneErrorKey)) {
          newErrorMap = new Map(
            prevErrors.set('phone', serverErrors[phoneErrorKey][0])
          );
        }
      });

      return newErrorMap;
    });
  }, [serverErrors]);

  if (Object.keys(localStepTalentProfile).length === 0) {
    return null;
  }

  const {
    currentLocation,
    educationLevel,
    fullName,
    nurseSpecialties,
    phone,
    school,
    stateLicenses,
  } = localStepTalentProfile;

  const submit = () => {
    const newErrors = validate(
      educationLevel,
      localStepTalentProfile,
      pageTalentProfileKeys
    );
    if (newErrors.size > 0) {
      setErrors(newErrors);
      return;
    }

    trackClick('talent.onboarding.submit', {
      Intent: intent,
    });
    maybeGoToNext();
  };

  const genHandleValueChange = (name) => (change) => {
    let value;
    const newTalentProfile = {};

    switch (name) {
      case 'fullName':
      case 'phone':
      case 'school':
        ({ value } = change.currentTarget);
        break;

      case 'educationLevel':
        if (change?.value?.length > 0 && change.value[0].value) {
          ({ value } = change.value[0]);
          if (value === 'student') {
            // Clear the values for non-student fields
            newTalentProfile.nurseSpecialties = Map();
            newTalentProfile.stateLicenses = Set();
          } else {
            // Clear the values for student fields
            newTalentProfile.school = '';
          }
        } else {
          value = null;
        }
        break;

      case 'nurseSpecialties':
        // default to 1 year of experience
        value = Map(change.value.map((choice) => [choice.value, 1]));
        break;

      case 'stateLicenses':
        value = Set(change.value.map((choice) => choice.value));
        break;

      default:
      // pass
    }
    newTalentProfile[name] = value;

    setLocalStepTalentProfile((prevLocalStepTalentProfile) => ({
      ...prevLocalStepTalentProfile,
      ...newTalentProfile,
    }));
    setErrors((prevErrors) => prevErrors.delete(name));
  };

  let zipCodeDetails = null;
  if (locationIsLoading || locationHasChanged) {
    zipCodeDetails = (
      <>
        {locationIsLoading && (
          <div>
            <InlineSpinner /> Loading
          </div>
        )}

        {locationHasChanged &&
          currentLocation &&
          !currentLocation.isEmpty() && (
            <div>
              {currentLocation.city}, {currentLocation.state}
            </div>
          )}
      </>
    );
  }

  let educationDependentFields;
  if (!pageTalentProfileKeys.includes('educationLevel')) {
    educationDependentFields = null;
  } else if (educationLevel === 'student') {
    educationDependentFields = (
      <InputWrapper>
        <Input
          id="school"
          onChange={genHandleValueChange('school')}
          value={school}
          label="Enrolled Nursing School"
          error={Boolean(errors.get('school'))}
          caption={errors.get('school')}
        />
      </InputWrapper>
    );
  } else if (educationLevel) {
    const nurseSpecialtiesArr = nurseSpecialties.keySeq().toArray();
    const selectedSpecialtyOptions = nurseSpecialtyOptions
      .toArray()
      .filter((specialtyOption) =>
        nurseSpecialtiesArr.includes(specialtyOption.value)
      );
    const stateLicensesArr = stateLicenses.toArray();
    const selectedStateLicenses = stateOptions
      .toArray()
      .filter((stateOption) => stateLicensesArr.includes(stateOption.value));
    educationDependentFields = (
      <>
        <InputWrapper>
          <Select
            clearable={false}
            multi
            instanceId="nurse-specialties"
            onChange={genHandleValueChange('nurseSpecialties')}
            options={nurseSpecialtyOptions.toArray()}
            value={selectedSpecialtyOptions}
            valueKey="value"
            placeholder="Select specialties"
            error={Boolean(errors.get('nurseSpecialties'))}
            caption={errors.get('nurseSpecialties')}
          />
        </InputWrapper>

        <InputWrapper>
          <Select
            clearable={false}
            multi
            instanceId="state-licenses"
            onChange={genHandleValueChange('stateLicenses')}
            options={stateOptions.toArray()}
            value={selectedStateLicenses}
            valueKey="value"
            placeholder="Select state licenses"
            error={Boolean(errors.get('stateLicenses'))}
            caption={errors.get('stateLicenses')}
          />
        </InputWrapper>
      </>
    );
  }

  const educationLevelArr = [];
  const selectedEducationLevel = educationLevels.find(
    (level) => level.value === educationLevel
  );
  if (educationLevel) {
    educationLevelArr.push(selectedEducationLevel);
  }

  const fullNameField = (
    <InputWrapper>
      <Input
        id="full-name"
        onChange={genHandleValueChange('fullName')}
        label="Full name"
        value={fullName}
        error={Boolean(errors.get('fullName'))}
        caption={errors.get('fullName')}
      />
    </InputWrapper>
  );

  const handleCurrentLocationChange = (newLocation, isLoading) => {
    const additionalFields = {};
    if (intent === 'resume') {
      additionalFields.other_locations_attributes = [
        {
          city: newLocation.city,
          state: newLocation.state,
          zip_code: newLocation.zipCode,
        },
      ];
    }
    setLocalStepTalentProfile((prevLocalStepTalentProfile) => ({
      ...prevLocalStepTalentProfile,
      currentLocation: newLocation,
      ...additionalFields,
    }));
    setErrors((prevErrors) => prevErrors.delete('currentLocation'));
    setLocationHasChanged(true);
    setLocationIsLoading(isLoading);
  };
  const zipCodeField = (
    <InputWrapper>
      <ZipCodeInput
        id="zip-code"
        onChange={handleCurrentLocationChange}
        place={currentLocation}
        label="Zipcode"
        useIhcl
        error={Boolean(errors.get('currentLocation'))}
        caption={errors.get('currentLocation')}
      />
      {zipCodeDetails}
    </InputWrapper>
  );

  const phoneField = (
    <InputWrapper>
      <Input
        id="phone"
        onChange={genHandleValueChange('phone')}
        label="Phone number"
        type="tel"
        value={phone}
        error={Boolean(errors.get('phone'))}
        caption={errors.get('phone')}
      />
    </InputWrapper>
  );

  const educationLevelField = (
    <>
      <InputWrapper>
        <Select
          clearable={false}
          instanceId="education-level"
          onChange={genHandleValueChange('educationLevel')}
          options={educationLevels}
          placeholder="Nursing Education Level"
          value={educationLevelArr}
          valueKey="value"
          error={Boolean(errors.get('educationLevel'))}
          caption={errors.get('educationLevel')}
        />
      </InputWrapper>
      {educationDependentFields}
    </>
  );

  const displayNativeBackButton = isNative() && currentStep === 1;
  const displayBackButton = !displayNativeBackButton && currentStep > 1;
  const [header, prompt] = getTitles(intent);
  return (
    <>
      <Helmet>
        <body className="onboarding-page" />
      </Helmet>
      <BodyWrapper>
        <Prompt $intent={intent}>
          <Header>{title || header}</Header>
          <p>{bodyText || prompt}</p>
        </Prompt>

        {pageTalentProfileKeys.includes('fullName') && fullNameField}
        {pageTalentProfileKeys.includes('phone') && phoneField}
        {pageTalentProfileKeys.includes('currentLocation') && zipCodeField}
        {pageTalentProfileKeys.includes('educationLevel') &&
          educationLevelField}
        {includeSignInLink && !educationDependentFields && <SignInPrompt />}
      </BodyWrapper>

      <FooterWrapper>
        {displayNativeBackButton && (
          <a href="/" className="back-btn">
            <WrappedLucideIcon name="ArrowLeft" aria-hidden="true" /> Back
          </a>
        )}
        {displayBackButton && (
          <ButtonWrapper>
            <Button
              type="button"
              kind="minimal"
              onClick={maybeGoToPrevious}
              style={{
                padding: '10px 24px',
              }}
            >
              Back
            </Button>
          </ButtonWrapper>
        )}

        {!displayNativeBackButton && !displayBackButton && <span />}

        <ButtonWrapper>
          <Button
            className="submit"
            onClick={submit}
            disabled={isUpdatingProfile || (errors && errors.size > 0)}
            isLoading={isUpdatingProfile}
            style={{
              padding: '10px 24px',
            }}
          >
            Continue
          </Button>
        </ButtonWrapper>
      </FooterWrapper>
    </>
  );
};

OnboardingTalentDetailsForm.propTypes = {
  bodyText: PropTypes.string,
  includeSignInLink: PropTypes.bool,
  nurseSpecialtyOptions: PropTypes.object.isRequired,
  pageTalentProfileKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  serverErrors: PropTypes.object.isRequired,
  stateOptions: PropTypes.object.isRequired,
  title: PropTypes.string,
};

OnboardingTalentDetailsForm.defaultProps = {
  bodyText: null,
  includeSignInLink: true,
  title: null,
};

const mapStateToProps = (state) => ({
  nurseSpecialtyOptions: getNurseSpecialtyOptions(state),
  serverErrors: state.serverErrors,
  stateOptions: getStateOptions(state),
});

export default connect(mapStateToProps)(OnboardingTalentDetailsForm);
