import lodash from 'lodash';
import PropTypes from 'prop-types';
import Select from 'react-select';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

const fullYearOptions = Array.from({ length: 50 }, (v, i) => i).map((i) => {
  const curYear = new Date().getFullYear();
  const yearIter = curYear - i;
  return { value: yearIter, label: String(yearIter) };
});

const monthOptions = Array.from({ length: 12 }, (v, i) => i + 1).map((i) => ({
  value: i,
  label: lodash.padStart(i, 2, '0'),
}));

// eslint-disable-next-line react/require-default-props
const MonthSelector = function (props) {
  let monthId = null;
  let yearId = null;
  if (props.id) {
    monthId = `${props.id}-month`;
    yearId = `${props.id}-year`;
  }

  return (
    <Row>
      <Col xs={6}>
        <Select
          clearable={false}
          disabled={props.disabled}
          instanceId={yearId}
          onChange={props.onYearChange}
          options={fullYearOptions}
          placeholder="Year"
          value={props.yearValue}
        />
      </Col>
      <Col xs={6}>
        <Select
          clearable={false}
          disabled={props.disabled}
          instanceId={monthId}
          onChange={props.onMonthChange}
          options={monthOptions}
          placeholder="Month"
          value={props.monthValue}
        />
      </Col>
    </Row>
  );
};

MonthSelector.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  monthValue: PropTypes.oneOfType([PropTypes.oneOf(['']), PropTypes.number])
    .isRequired,
  onMonthChange: PropTypes.func.isRequired,
  onYearChange: PropTypes.func.isRequired,
  yearValue: PropTypes.oneOfType([PropTypes.oneOf(['']), PropTypes.number])
    .isRequired,
};

MonthSelector.defaultProps = {
  disabled: false,
};

export default MonthSelector;
