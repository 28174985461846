import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { styled, useStyletron, withStyle } from 'shared/components/ihcl/styled';

import { Checkbox, STYLE_TYPE } from 'shared/components/ihcl/checkbox';
import { AlertIcon } from 'shared/components/ihcl/icon';
import { Center } from 'shared/components/ihcl/positioning';

import {
  Onboarding,
  OnboardingContext,
  OnboardingTitle as OnboardingTitleBase,
} from 'registration/containers/Onboarding';

import { pathToVariant } from 'shared/helpers/activateABTest';

const OnboardingTitle = withStyle(
  OnboardingTitleBase,
  ({ $additionalTopSpacing = false }) => ({
    marginTop: $additionalTopSpacing ? '40px' : '64px',
  })
);

const SpecialtyRow = styled('li', ({ $theme }) => ({
  borderBottomColor: $theme.colors.grayLine,
  borderBottomStyle: 'solid',
  borderBottomWidth: '1px',
  paddingTop: '20px',
  paddingBottom: '20px',
}));

const SpecialtiesRows = styled('ul', () => ({
  listStyleType: 'none',
  paddingLeft: 0,
}));

const ToggleRow = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
});

const SpecialtiesWantsToDo = ({ stepNumber }) => {
  const {
    onboardingBasePath,
    onboardingData,
    onboardingOptions,
    setOnboardingData,
  } = useContext(OnboardingContext);
  const [, theme] = useStyletron();
  if (!onboardingData || Object.keys(onboardingData).length < 1) {
    return null;
  }
  const { nurse_specialty_descriptions: nurseSpecialtyDescriptions } =
    onboardingOptions;
  const { nurse_specialties: nurseSpecialties } = onboardingData;

  const hasWantsToDoSpecialty = nurseSpecialties.some(
    (specialty) => specialty.wants_to_do
  );

  const variant = pathToVariant(onboardingBasePath);
  const isYukiVariant = variant === 'yuki';

  return (
    <Onboarding
      currentStep={stepNumber}
      autoSpacedStepContents={false}
      isNextDisabled={!hasWantsToDoSpecialty}
      stepKeys={['nurse_specialties']}
    >
      <Center>
        <OnboardingTitle
          $reducedFontSize={isYukiVariant}
          $additionalTopSpacing={isYukiVariant}
        >
          Which specialties do you want to work in?
        </OnboardingTitle>
        <SpecialtiesRows>
          {nurseSpecialties.map((ns) => {
            const nsd = nurseSpecialtyDescriptions.find(
              (description) =>
                description.id === ns.nurse_specialty_description_id
            );
            const specialtiesRowDescription = `${nsd.description}: \
              ${ns.years_experience} \
              ${ns.years_experience === 1 ? 'year' : 'years'}`;

            return (
              <SpecialtyRow key={nsd.id}>
                <ToggleRow>
                  {specialtiesRowDescription}
                  <Checkbox
                    ariaLabel={`${nsd.description}`}
                    checked={ns.wants_to_do}
                    checkmarkType={STYLE_TYPE.toggle_round}
                    onChange={(e) => {
                      const newNurseSpecialties = [];
                      nurseSpecialties.forEach((specialty) => {
                        if (
                          specialty.nurse_specialty_description_id === nsd.id
                        ) {
                          newNurseSpecialties.push({
                            ...specialty,
                            wants_to_do: e.target.checked,
                          });
                        } else {
                          newNurseSpecialties.push({ ...specialty });
                        }
                      });
                      setOnboardingData({
                        ...onboardingData,
                        nurse_specialties: newNurseSpecialties,
                      });
                    }}
                  />
                </ToggleRow>
              </SpecialtyRow>
            );
          })}
        </SpecialtiesRows>
        {!hasWantsToDoSpecialty && (
          <div
            style={{
              marginTop: '24px',
              color: theme.colors.negative,
              fontSize: '16px',
            }}
          >
            <AlertIcon height="14px" width="14px" />
            &nbsp;Please select at least one
          </div>
        )}
      </Center>
    </Onboarding>
  );
};
SpecialtiesWantsToDo.propTypes = {
  stepNumber: PropTypes.number.isRequired,
};

export default SpecialtiesWantsToDo;
