import {
  Checkbox as BaseCheckbox,
  StyledCheckmark as BaseCheckmark,
} from 'baseui/checkbox';
import PropTypes from 'prop-types';
import React from 'react';
import { withStyle } from 'shared/components/ihcl/styled';

export { LABEL_PLACEMENT, STYLE_TYPE } from 'baseui/checkbox';

export const StyledCheckmark = withStyle(
  BaseCheckmark,
  ({ $theme: { borders, colors }, $checked }) => {
    const styles = {
      backgroundColor: $checked
        ? colors.checkboxDisabledFill
        : colors.checkboxPrimaryFill,
      backgroundSize: '10px',
      borderTopWidth: '1px',
      borderRightWidth: '1px',
      borderBottomWidth: '1px',
      borderLeftWidth: '1px',
      borderTopLeftRadius: borders.checkboxBorderRadius,
      borderTopRightRadius: borders.checkboxBorderRadius,
      borderBottomRightRadius: borders.checkboxBorderRadius,
      borderBottomLeftRadius: borders.checkboxBorderRadius,
    };
    return {
      ...styles,
    };
  }
);

// eslint-disable-next-line react/require-default-props
export const Checkbox = ({ instanceId, ...props }) => {
  const labelFontSize = props.labelSize === 'small' ? '14px' : '16px';
  const labelFontWeight = props.labelWeight === 'normal' ? 500 : 800;

  return (
    <BaseCheckbox
      {...props}
      overrides={{
        Root: {
          props: {
            ...(instanceId && { 'data-instance-id': instanceId }),
          },
        },
        Checkmark: {
          component: StyledCheckmark,
        },
        Label: {
          style: {
            alignItems: 'center',
            fontSize: labelFontSize,
            fontWeight: labelFontWeight,
            textTransform: 'none',
          },
        },
        ...props.overrides,
      }}
    />
  );
};

Checkbox.propTypes = {
  instanceId: PropTypes.string,
  labelSize: PropTypes.oneOf(['small', 'medium']),
  labelWeight: PropTypes.oneOf(['normal', 'bold']),
  overrides: PropTypes.object,
};

Checkbox.defaultProps = {
  instanceId: null,
  labelSize: 'medium',
  labelWeight: 'bold',
  overrides: {},
};
