import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { trackNonHiringSignUpCompletion } from 'shared/helpers/tracking';

import { getTalentAttribute } from '../../helpers/talentProfileConversions';
import { getTalentProfile } from '../../selectors';

import { OnboardingVariantProvider } from './providers/OnboardingVariantProvider';

export const SIGNUP_STEP = 'email';
export const PASSWORD_STEP = 'password';
export const GET_HIRED_STEP = 'getHired';
export const COMPLETE_ONBOARDING_STEP = 'completeOnboarding';

const shouldUseSavedStep = (savedTpStep, currentStep) => {
  if (!savedTpStep) {
    return false;
  }
  if (savedTpStep && !currentStep) {
    return true;
  }
  return savedTpStep > currentStep;
};

const showGetHired = (intent, serverTalentProfile, talentProfileUpdates) => {
  const educationLevel = getTalentAttribute(
    'educationLevel',
    talentProfileUpdates,
    serverTalentProfile
  );
  return educationLevel !== 'student' && intent !== 'mental_health';
};

// eslint-disable-next-line react/require-default-props
const OnboardingStepController = ({
  defaultStepList,
  getStepComponent,
  intent,
  // eslint-disable-next-line react/require-default-props
  serverTalentProfile,
  variant,
}) => {
  const history = useHistory();
  const location = useLocation();
  const { step } = useParams();
  const [talentProfileUpdates, setTalentProfileUpdates] = useState({});
  const [stepList, setStepList] = useState(defaultStepList);
  const [currentStep, setCurrentStep] = useState(null);
  const [mostRecentCompletedStep, setMostRecentCompletedStep] = useState(null);

  useEffect(() => {
    const stepFromUrl = Number(step);
    let updatedStep = stepFromUrl;

    if (!serverTalentProfile.id) {
      const isInvalidStep =
        Number.isNaN(stepFromUrl) || Number(stepFromUrl) < 1;
      const isFirstNavigationToPage =
        Object.keys(talentProfileUpdates).length === 0;
      if (isInvalidStep || isFirstNavigationToPage) {
        updatedStep = 1;
      }
    } else {
      const savedTpStep = serverTalentProfile.current_onboard_step;
      // Add 1 for the step after sign up, and add another 1 for the difference
      // between array index and the step number.
      const postSignUpStep = stepList.indexOf(SIGNUP_STEP) + 2;
      if (shouldUseSavedStep(savedTpStep, stepFromUrl)) {
        updatedStep = savedTpStep;
      } else if (
        postSignUpStep < stepList.length &&
        (!stepFromUrl || stepFromUrl < postSignUpStep)
      ) {
        // The user signed in to an existing account, so default to the first
        // step after sign up.
        updatedStep = postSignUpStep;
      }
    }

    if (updatedStep > stepList.length) {
      updatedStep = stepList.length;
    }

    if (updatedStep !== stepFromUrl) {
      // If step is not present, use the fully qualified pathname to avoid
      // accidentally removing the variant, i.e. a redirect from
      // sign_up/<variant> to sign_up/<step> instead of sign_up/<variant>/<step>
      const path = step ? updatedStep : `${location.pathname}/${updatedStep}`;
      if (updatedStep === 1) {
        history.replace({
          pathname: path,
          search: location.search,
        });
      } else {
        history.push({
          pathname: path,
          search: location.search,
        });
      }
    } else {
      if (currentStep > updatedStep) {
        // This is a navigate back action, so clear the most recent completed
        // step.
        setMostRecentCompletedStep(null);
      }
      setCurrentStep(updatedStep);
    }
  }, [
    step,
    serverTalentProfile.id,
    serverTalentProfile.current_onboard_step,
    stepList,
    talentProfileUpdates,
    location.pathname,
    location.search,
    history,
    currentStep,
  ]);

  useEffect(() => {
    const stepsToRemove = [];
    if (serverTalentProfile.uses_3p_oauth) {
      stepsToRemove.push(PASSWORD_STEP);
    }
    if (!showGetHired(intent, serverTalentProfile, talentProfileUpdates)) {
      stepsToRemove.push(GET_HIRED_STEP);
    }
    const newStepList = defaultStepList.filter(
      (stepListStep) => stepsToRemove.indexOf(stepListStep) === -1
    );
    setStepList(newStepList);
  }, [talentProfileUpdates, serverTalentProfile, defaultStepList, intent]);

  useEffect(() => {
    if (!currentStep || !mostRecentCompletedStep) {
      return;
    }
    const nextStep = currentStep + 1;
    if (mostRecentCompletedStep === currentStep) {
      history.push(`${nextStep}${location.search}`);
    }
  }, [currentStep, history, location.search, mostRecentCompletedStep]);

  const goToPreviousPage = () => {
    history.goBack();
  };

  if (!currentStep) {
    return null;
  }

  if (currentStep === stepList.length) {
    mixpanel.track('Onboard Lite Complete');
    trackNonHiringSignUpCompletion(serverTalentProfile);
  }

  return (
    <OnboardingVariantProvider
      config={{
        currentStep,
        goToPreviousPage,
        intent,
        onboardVariant: variant,
        setMostRecentCompletedStep,
        setTalentProfileUpdates,
        talentProfileUpdates,
        // Don't count 'completeOnboarding' as a step
        totalSteps: stepList.length - 1,
      }}
    >
      {getStepComponent(stepList[currentStep - 1])}
    </OnboardingVariantProvider>
  );
};

OnboardingStepController.propTypes = {
  defaultStepList: PropTypes.array.isRequired,
  getStepComponent: PropTypes.func.isRequired,
  intent: PropTypes.string.isRequired,
  serverTalentProfile: PropTypes.object,
  variant: PropTypes.string.isRequired,
};

OnboardingStepController.defaultProps = {
  serverTalentProfile: null,
};

const mapStateToProps = (state) => ({
  serverTalentProfile: getTalentProfile(state),
});

export default connect(mapStateToProps)(OnboardingStepController);
